import React from 'react';
import MoveResizeBase from "./dialog/MoveResizeBase";
// import {Dialog, DialogTitle} from "@mui/material";

class Test extends MoveResizeBase {
    constructor(props) {
        super(props);

        this.state = {exportedContent: [], document: false, isLoading: false};
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        return (<div></div>
/*
            <Dialog open={this.open} close={this.handleClose}>
                <DialogTitle>Test</DialogTitle>
                Test!
            </Dialog>

 */
        )
    }

    handleClose() {
        this.close();
    }
}

export default Test;
