import React, { Component } from 'react';
import TopBar from "../TopBar";
import LabeledInput from "../forms/LabeledInput";
import TransactionRows from "./TransactionRows";
import Utils from "../../utils/Utils";
import Uploader from "./Uploader";

class TransactionForm extends React.Component {
    constructor(props) {
        super(props);

        this.state={verificationId: props.verificationId, verification: {}, transactions: props.transactions == null ? {} : props.transactions, isLoading: true, ordinal: 0}

        this.headerFontSize = '12px';
    }

    componentDidMount() {
        if (this.state.verificationId == null) {

            const targetUrl = Utils.createUrl('/brfa/api/accounting/next/2022');
            const headers = Utils.getAuthorizationHeaders();

            let registrationDate = new Date();
            let registrationDateString = registrationDate.toLocaleDateString();

            let models = [];
            if (this.state.transactions != null) {
                models.push({ account: 1920, amount: this.props.transactions.amount, comment: this.props.transactions.description, name: '', ordinal: 1, debet: 0, credit: 0 });
            } else {
                models.push({ account: '', amount: '', comment: '', name: '', ordinal: 1, debet: 0, credit: 0 });
                models.push({ account: '', amount: '', comment: '', name: '', ordinal: 2, debet: 0, credit: 0 });
            }

            fetch(targetUrl, headers)
                .then(response => {
                    return response == null ? null : response.json();
                }).then(data => {
                let verification = { series: 'A', number: data, registrationDate: registrationDateString, transactionDate: null, transactions: models }
                this.setState({verification: verification, isLoading: false});
            });

            return;
        }
        const targetUrl = Utils.createUrl('/brfa/api/accounting/verification/'+this.state.verificationId);
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response == null ? null : response.json();
            }).then(data => {
            this.setState({verification: data, isLoading: false});
        });
    }

    render() {

        if (this.state.isLoading == true) {
            return <div>Loading...</div>;
        }

        let verification = this.state.verification;

        return (
            <div>
                <table style={{width: 'calc(100% - 10px)'}}>
                    <tr style={{height: '48pt'}}>
                        <td><LabeledInput
                            fontSize={this.headerFontSize}
                            name='description'
                            label={'Beskrivning'}
                            value={verification.description}
                            onChange={(e) => this.onChange(e)}>
                        </LabeledInput></td>
                        <td><LabeledInput
                            name='series'
                            fontSize={this.headerFontSize}
                            label={'Verifikationsserie'}
                            value={verification.series}
                            onChange={(e) => this.onChange(e)}>
                        </LabeledInput></td>
                        <td><LabeledInput
                            name='number'
                            fontSize={this.headerFontSize} label={'Nummer'}
                            value={verification.number}
                            onChange={(e) => this.onChange(e)}>
                        </LabeledInput></td>
                        <td><LabeledInput
                            name='registrationDate'
                            fontSize={this.headerFontSize}
                            label={'Bokföringsdatum'}
                            value={verification.registrationDate}
                            onChange={(e) => this.onChange(e)}>
                        </LabeledInput></td>
                        <td><LabeledInput
                            name='transactionDate'
                            fontSize={this.headerFontSize}
                            label={'Transaktionsdatum'}
                            value={verification.transactionDate}
                            onChange={(e) => this.onChange(e)}>
                        </LabeledInput></td>
                    </tr>
                    <tr style={{height: '48pt'}}>
                        <td><LabeledInput
                            name='reference'
                            fontSize={this.headerFontSize}
                            label={'Fakturareferens / OCR'}
                            value={verification.reference}
                            onChange={(e) => this.onChange(e)}>
                        </LabeledInput></td>
                        <td></td>
                        <td></td>
                        <td><button onClick={() => this.onSave() } style={{height: '64px', width: '100px'}}>SPARA</button></td>
                    </tr>
                </table>
                <TransactionRows onUpdate={(transactions) => this.onTransactionUpdate(transactions)} transactions={this.state.verification.transactions}></TransactionRows>
                <div style={{padding: '10px'}}><Uploader series = {this.state.verification.series} number = {this.state.verification.number}/></div>
            </div>
        );
    }

    onChange(e) {
        let name = e.target.name;
        let verification = this.state.verification;
        verification[name] = e.target.value;
        console.log(name+" "+e.target.value)
        // this.setState({verification: verification});
    }

    onTransactionUpdate(transactions) {
        let verification = this.state.verification;
        verification.transactions = transactions;
        this.setState( { verification: verification } );
    }

    onSave() {
        if (this.state.verification != null) {

            const targetUrl = Utils.createUrl('/brfa/api/accounting/verification');
            const headers = Utils.getAuthorizationHeaders();

            const requestOptions = {
                method: 'POST',
                headers: Utils.getAuthorizationHeaders().headers,
                body: JSON.stringify(this.state.verification)
            }

            fetch(targetUrl, requestOptions)
                .then(response => {
                    return response;
                });

            return;
        }
    }
}

export default TransactionForm;
