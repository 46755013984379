import React from "react";
import ImageViewer from "./ImageViewer";

class Thumbnail extends React.Component {

    constructor(props) {
        super(props);
        this.state={previewTopOffset: props.previewTopOffset, id: props.file.id, file: props.file, src: props.src, fileName: props.fileName, page: this.props.page, preview: null};
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps.src !== this.props.src) {
            this.setState({src: this.props.src, fileName: this.props.fileName, page: this.props.page});
        }
    }

    render() {
        let now = new Date();
        let preview = [];

        if (this.state.preview != null) {
            preview.push(
            <div style={{
                position: 'fixed',
                top: this.state.previewTopOffset,
                left: '100px',
                display: 'block',
                overflow: 'visible',
                width: '768px',
                transform: 'translate(0%, 0%)',
                boxShadow: 'rgba(0, 0, 0, 0.9) 0px 1px 12px'
            }}>
                <div  style={{
                    float: 'left',
                    width: '99%',
                    padding: '2pt',
                    color: '#FFFFFF',
                    background: '#003c68'}}>
                    <div style={{float: 'right'}}><button onClick={(e) => this.onClose(e)} style={{align: 'right'}}>X</button></div>
                    <div style={{fontSize: '10pt', float: 'left', width: 'auto'}}>{this.state.fileName} ({this.state.id})<br/>Sid {this.state.page}</div>
                </div>
                <img onClick={(event) => this.onThumbClick(event, this.state.src)}
                     src={this.state.src}
                     height='auto'
                     width='100%'
                     style={{
                    width: '100%',
                    margin: '0px',
                    padding: '0px'
                }}/>
            </div>)
        }

        return(
            <div draggable='true' onDragStart={(event) => this.dragStart(event, this.state.file)} style={{width: 'auto', display: 'grid', grid: 'contents', background: '#fff4dd', boxShadow: 'rgba(0, 0, 0, 0.9) 0px 1px 12px'}}>
                {preview}
                <div  style={{
                    fontSize: '10pt',
                    width: 'calc(100%-2pt',
                    height: '32px',
                    padding: '2pt',
                    color: '#FFFFFF',
                    background: '#003c68'}}>
                    {this.state.fileName} ({this.state.id})<br/>Sid {this.state.page}
                </div>
                <img onClick={(event) => this.onThumbClick(event, this.state.src)} src={this.state.src} height='auto' width='100%' style={{
                    width: '100%',
                    margin: '0px',
                    padding: '0px'
                }}/>
        </div>)
    }

    onThumbClick(event, src) {
        this.setState({preview: src})
    }

    onClose(event) {
        this.setState({preview: null})
    }

    dragStart(event, file) {
        let fileJson = JSON.stringify(file);
        console.log(file)
        event.dataTransfer.setData("text", fileJson);
    }

    zoomChanged(e) {
        this.setState({size: e.target.value + 'px'})
    }
}

export default Thumbnail;
