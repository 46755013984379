import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import './App.css';
import AccountView from './views/AccountView';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import VerificationView from "./views/VerificationView";
import PersonView from "./views/PersonView";
import LoginView from "./views/LoginView";
import ApartmentView from "./views/ApartmentView";
import Dashboard from "./views/Dashboard";
import ErrorBoundary from "./components/ErrorBoundary";
import ApartmentFee from "./components/apartment/ApartmentFee";
import TaskBoardView from "./views/TaskBoardView";
import DevelopmentView from "./views/DevelopmentView";
import Test from "./components/Test";
import CalendarView from "./views/CalendarView";
import TimelineView from "./views/TimelineView";
import TopBar from "./components/TopBar";
import ChartsView from "./views/ChartsView";
import BrokerImage from "./components/apartment/brokerimage/BrokerImage";
import AccountingView from "./components/accounting/AccountingView";
import AdminView from "./views/AdminView";
import CSVUpload from "./components/economy/CSVUpload";
import BudgetView from "./views/BudgetView";
import MortgageView from "./views/MortgageView";
import SelfServiceView from "./views/SelfServiceView";
import KeyValuesView from "./views/KeyValuesView";
import FileView from "./views/FileView";
import MessageView from "./views/MessageView";
import QRLookupView from "./views/QRLookupView";
import MultiYearView from "./views/MultiYearView";
import BookingView from "./views/BookingView";

// const client = new W3CWebSocket('ws://' + Utils.createUrlWithProtocol(''));

class App extends Component {

    constructor() {
        super();
        const meta = {
            viewport: 'width=device-width, initial-scale=1.0',
        }

        this.state = { loginInfo: null};

        // Clear Bearer and info

        let loginInfo = localStorage.getItem('loginInfo')
        if (loginInfo && loginInfo != 'undefined') {
            this.state = {loginInfo: JSON.parse(loginInfo)};
        }
      }
/*
    componentDidMount() {
        document.title = "Brf Assistant"
        client.onopen = () => {
            alert('WebSocket Client Connected');
        };
        client.onmessage = (message) => {
            alert(message);
        };
    }
  */
    render() {

    let login = document.getElementById("login");

    return (
        <div style={{position: 'relative', display: 'block', width: '100%', minHeight: 'calc(100vh-70px)', float: 'left'}}>
            <Router>
                <MetaTags>
                    <meta id="slask" name="viewport" content="width=device-width, initial-scale=1.0" user-scalable={0} />
                </MetaTags>
                <ErrorBoundary>
                <TopBar loginInfo={this.state.loginInfo} onLogin={(data) => this.onLogin(data)}>
                <div style={{marginTop: '71px', minHeight: 'calc(100% - 71px)'}}>
                    <Switch>
                        <Route path="/dashboard/:apartmentId" component={Dashboard}>
                        </Route>
                        <Route path="/dashboard/" component={Dashboard}>
                        </Route>

                        <Route path="/booking/:token" component={BookingView}>
                        </Route>
                        <Route path="/booking/" component={BookingView}>
                        </Route>


                        <Route path="/test">
                            <Test/>
                        </Route>
                        <Route path="/files">
                            <FileView/>
                        </Route>
                        <Route path="/multiYears">
                            <MultiYearView/>
                        </Route>
                        <Route path="/keyvalues">
                            <KeyValuesView/>
                        </Route>
                        <Route path="/calendar">
                            <CalendarView size='190'/>
                        </Route>
                        <Route path="/accounting/:verification" component={AccountingView}>
                        </Route>
                        <Route path="/accounting/">
                            <AccountingView/>
                        </Route>
                        <Route path="/upload/csv">
                            <CSVUpload/>
                        </Route>
                        <Route path="/dev">
                            <DevelopmentView/>
                        </Route>
                        <Route path="/tasks">
                            <TaskBoardView/>
                        </Route>
                        <Route path="/apartment">
                            <ApartmentView/>
                        </Route>
                        <Route path="/contacts">
                            <PersonView/>
                        </Route>
                        <Route path="/messages">
                            <MessageView/>
                        </Route>
                        <Route path="/accounts">
                            <AccountView/>
                        </Route>
                        <Route path="/verifications">
                            <VerificationView/>
                        </Route>
                        <Route path="/timeline">
                            <TimelineView/>
                        </Route>
                        <Route path="/fee">
                            <ApartmentFee onLogin={(e) => this.onLogin(e)}/>
                        </Route>
                        <Route path="/charts">
                            <ChartsView/>
                        </Route>
                        <Route path="/admin">
                            <AdminView/>
                        </Route>
                        <Route path="/budget">
                            <BudgetView/>
                        </Route>
                        <Route path="/mortgage">
                            <MortgageView/>
                        </Route>
                        <Route path="/selfservice">
                            <SelfServiceView/>
                        </Route>
                        <Route path="/lookup/token/:uuid" component={QRLookupView}>
                        </Route>
                        <Route path="/brokerimage/:apartmentId" component={BrokerImage}>
                        </Route>
                        <Route path="/">
                            <ErrorBoundary>
                                <LoginView id="login" onLogin={(e) => this.onLogin(e)}/>
                            </ErrorBoundary>
                        </Route>
                        <Route path="/login">
                            <LoginView/>
                        </Route>
                    </Switch>
                    </div>
                </TopBar>
                </ErrorBoundary>
            </Router>
        </div>
    );
    }
    onLogin(loginInfo) {
        localStorage.setItem('loginInfo', JSON.stringify(loginInfo));
        this.setState({loginInfo: loginInfo})
    }
}

export default App;
