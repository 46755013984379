import React from "react";


class ErrorBoundary extends React.Component {
    state = {
        hasError: false,
        error: { message: "", stack: "" },
        info: { componentStack: "" }
    };

    static getDerivedStateFromError = error => {
        return { hasError: true };
    };

    componentDidCatch = (error, info) => {
        this.setState({ error, info });
    };

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div style={{marginTop: '30%'}}><h1>Something went wrong.{this.state.error.message}</h1></div>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
