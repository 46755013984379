import Utils from "../../utils/Utils";
import axios from "axios";
import React from "react";
import Files from "./Files";
import WindowFrame from "../dialog/WindowFrame";

class Uploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {files: props.files == null ? [] :props.files , selectedFile: '', uploadedFiles: [], images: {}, imageUrls: null, pdfDocument: null};
    }

    componentDidMount() {

    }
    componentDidUpdate(prevProps) {
        if (prevProps.files !== this.props.files) {
            this.setState({files: this.props.files});
        }
    }

    render() {

        let files = this.state.uploadedFiles;

        files.map((item) =>
            <dt>{item}</dt>
        )

        let images = [];
        let combined = [];
        if (this.state.fileDialog) {
            let content = [];
            let style = {display: 'grid', gridTemplateColumns: 'repeat(auto-fit, 500px)'};
            combined.push(<WindowFrame onClose={() => this.onFileWindowClose()} title="Files" content={content}></WindowFrame>);
        }

        return (
            <div style={{margin: '12pt'}}>
                {combined}
                <div>
                    <ul>
                        {files}
                    </ul>
                </div>
                <div style={{marginLeft: '12px', padding: '12px'}}>
                    <input type="file" onChange={(e) => this.handleInputChange(e)} />
                    <button onClick={() => this.onUpload()}>
                        Ladda upp
                    </button>
                </div>
                <div style={{}}>{images}</div>
            </div>
        )
    }

    onFileWindowClose(window) {
    }

    showFiles() {
        this.setState({fileDialog: true})
    }

    handleInputChange(event) {
        this.setState({
            selectedFile: event.target.files[0],
        })
    }

    onUpload() {
        let formData = new FormData();


        const fileMetadata = {
            // fileType: 'Verification',
            // identification: this.props.series+this.props.number,
        }

        const data = new FormData()
        data.append('fileType', 'pdf')
        data.append('objectType', 'Verification')
        data.append('title', 'title')
        data.append('objectId', this.props.objectId)
        data.append('objectKey', this.props.objectKey)
        data.append('objectDate', this.props.objectDate)
        data.append('information', 'Test information')
        data.append('createdBy', Utils.getUserInfo().id)
        data.append('file', this.state.selectedFile)

        // this.buildFormData(data, fileMetadata, fileMetadata)

        const targetUrl = Utils.createUrl('/brfa/api/file/upload');
        let headers = Utils.getAuthorizationHeaders('text/json');

        const requestOptions = {
            method: 'POST',
            headers: headers.headers,
        }

        axios.post(targetUrl, data, requestOptions, {
        }).then(data => { // then print response status
            let imageUrls = [];
            let imageData = data.data.imageData;
            for (let i=0;i<imageData.jpgImages.length;i++) {
                let url = "data:image/jpeg;base64,"+imageData.jpgImages[i];
                imageUrls[i] = url;
            }
            let pdfUrl = "data:image/pdf;base64,"+imageData.pdfDocument;

            let uploadedFiles = this.state.uploadedFiles;
            uploadedFiles.push(data.originalFileName);
            const file = {
                uploadedFiles: this.state.uploadedFiles,
                imageUrls: imageUrls,
                pdfDocument: pdfUrl
            }
            let files = this.state.files;
            files.push(file);
            // this.setState({files: files});
            this.props.onUpload(file);
        })
    }

    buildFormData(formData, data, parentKey) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;

            formData.append(parentKey, value);
        }
    }

    onFileWindowClose() {
        this.state.fileDialog = false;
    }
}

export default Uploader;