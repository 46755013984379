import React, { Component } from 'react';
import TopBar from "../TopBar";

class MoveResizeBase extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (<div></div>
            /*
            <div style={{textAlign: "left", color: "#FFFFFF", background: "#000000",  height: "48px", width: "100%"}}>
                Brf Assistant
                <Dialog open={this.open}>
                    <DialogTitle>Test</DialogTitle>
                    Test!
                </Dialog>
            </div>

             */
        );
    }

    open() {

    }
}

export default MoveResizeBase;
