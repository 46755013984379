

let background = '#FFFFEE';
export let panelStyle = {margin: '5px', maxWidth: '1170px', padding: '5px',  height: 'fit-content', width: 'fit-content', background: background, borderRadius: '10px', boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.5)'};

// export let calendarContainerStyle = { height: 'fit-content', width: 'fit-content', padding: '10px', margin: '10px', display: 'grid', gridTemplateColumns: 'auto auto auto auto auto auto auto', background: '#AAAAAA', borderRadius: '10px', boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.5)'};

export let calendarContainerStyle = { height: 'fit-content', width: 'fit-content', padding: '4px', margin: '10px', display: 'grid', gridTemplateColumns: 'auto auto auto auto auto auto auto', boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.5)'};

export let calendarHeadingStyle = { paddingTop: '5px', textAlign: 'center', margin: '0px', background: '#FFFFFF', color: '#FFFFFF', border: '1px dotted gray',  width: '50px', height: '24px'};

export let calendarWeekDayStyle = { cursor: 'pointer', paddingTop: '5px', textAlign: 'center', margin: '0px', background: '#FFFFFF', color: '#000000', border: '1px dotted gray',  width: '50px', height: '50px'};

export let calendarWeekendStyle = { cursor: 'pointer', paddingTop: '5px', textAlign: 'center', margin: '0px', background: '#FFEEEE', color: '#000000', border: '1px dotted gray',  width: '50px', height: '50px'};

export let calendarTodayStyle = { cursor: 'pointer', paddingTop: '5px', textAlign: 'center', margin: '0px', background: '#DDDDFF', color: '#000000', border: '1px dotted gray',  width: '50px', height: '50px'};

export let widePanelStyle = {padding: '5px',  margin: '10px', width: '40%', background: background, borderRadius: '10px', boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.5)'};

export let dashboardContainerStyle = { height: 'fit-content', width: 'fit-content', padding: '0px', margin: '10px', display: 'grid', gridTemplateColumns: 'auto auto auto auto', background: '#FF0000', boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.5)'};

export let inlinePopup = {width: 'calc(90vw-30px)', padding: '5px',  margin: '0px', background: background, borderRadius: '10px', boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.5)'};

export let tooltipStyle = {allowTransparency: 'false', width: '1024', padding: '5px',  margin: '10px', color: '#000000', background: background, borderRadius: '10px', boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.5)'};

export let timelineContainerStyle = { height: 'fit-content', width: '100%', padding: '0px', margin: '0px', display: 'grid', gridTemplateColumns: 'auto auto auto auto auto auto auto auto auto auto auto auto', boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.5)'};

export let shadowBox = {padding: '10px', float: "left", width: 'calc(98% - 6px)', cursor: 'pointer', boxShadow: '0px 1px 12px rgba(0.5, 0.5, 0.5, 0.5)'}

export let gridShadowBox = {margin: '12px', padding: '12px', float: "left", minWidth: 'calc(50% - 48px)', cursor: 'pointer', boxShadow: '0px 1px 12px rgba(0.5, 0.5, 0.5, 0.5)'}

export let gridShadowBoxFull = {margin: '12px', padding: '12px', float: "left", width: 'calc(98% - 6px)', cursor: 'pointer', boxShadow: '0px 1px 12px rgba(0.5, 0.5, 0.5, 0.5)'}


export let headingStyle = {
    padding: '10px',
    height: '24pt',
    fontWeight: 'bold',
    fontSize: '18pt',
    color: '#949494' };

export let subHeadingStyle = {
    paddingTop: '4px',
    height: '24pt',
    fontWeight: 'bold',
    fontSize: '14pt',
    color: '#949494' };

export let dialogStyle = {
    zIndex: '1',
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginTop: '-50px',
    marginLeft: '-150px',
    width: '300px',
    height: '100px',
    maxWidth: '1170px',
    padding: '10px',
    background: '#FFFFEE',
    borderRadius: '10px',
    boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.5)'
};

