import React from "react";
import {panelStyle} from "../styles/Main";

class EditPerson extends React.Component {
    constructor(props) {
        super(props);

        this.state = {apartmentId: props.apartmentId, person: props.person, isLoading: false};
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        return (
            <div style={{margin: '2px 2px 12px 12px'}}>
                <table style={{width: '400px'}}>
                    <tr><td>ID</td><td>{this.state.person.id}</td></tr>
                    <tr><td>Andel</td><td><input name="share" onChange={(e) => this.syncModel(e)} value={this.state.person.share}/></td></tr>
                    <tr><td>Förnamn</td><td><input name="firstName" onChange={(e) => this.syncModel(e)} value={this.state.person.firstName}/></td></tr>
                    <tr><td>Efternamn</td><td><input name="lastName" onChange={(e) => this.syncModel(e)} value={this.state.person.lastName}/></td></tr>
                    <tr><td>Personnummer</td><td><input name="personalNumber" onChange={(e) => this.syncModel(e)} value={this.state.person.personalNumber}/></td></tr>
                    <tr><td>Avtalsdatum</td><td><input name="fromDate" onChange={(e) => this.syncModel(e)} value={this.state.person.fromDate}/></td></tr>
                    <tr><td>Mobil</td><td><input name="mobilePhone" onChange={(e) => this.syncModel(e)} value={this.state.person.mobilePhone}/></td></tr>
                    <tr><td>Hemtelefon</td><td><input name="homePhone" onChange={(e) => this.syncModel(e)} value={this.state.person.homePhone}/></td></tr>
                    <tr><td>Arbete</td><td><input name="workPhone" onChange={(e) => this.syncModel(e)} value={this.state.person.workPhone}/></td></tr>
                    <tr><td>e-post</td><td><input name="email" onChange={(e) => this.syncModel(e)} value={this.state.person.email}/></td></tr>
                </table>
            </div>
        )
    }

    syncModel(event) {

        let person = this.state.person;
        person[event.target.name] = event.target.value ;
        this.setState({person: person})
    }
}

export default EditPerson;