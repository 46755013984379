import React from "react";
import Utils from "../../../utils/Utils";

class Owners extends React.Component {
    constructor(props) {
        super(props);

        this.state = {apartmentId: props.apartmentId, isLoading: true};
    }

    componentDidMount() {
        const targetUrl = Utils.createUrl('/brfa/api/person/apartment/' + this.state.apartmentId + '/CURRENT_OWNER');
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({persons: data, isLoading: false});
        });
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        let persons = []

        for (let i=0;i<this.state.persons.length;i++) {
            if (this.state.persons[i].share != 0) {
                persons.push(
                    <tbody>
                    <tr>
                        <td>Ägare {i+1}</td>
                        <td>{this.state.persons[i].share}%</td>
                        <td>{this.state.persons[i].firstName} {this.state.persons[i].lastName}</td>
                        <td>{this.state.persons[i].personalNumber}</td>
                        <td>{this.state.persons[i].fromDate}</td>
                        <td>{this.state.persons[i].mobilePhone}</td>
                        <td>{this.state.persons[i].email}</td>
                    </tr>
                    </tbody>)
            }
        }

        return (
            <div style={{width: '100%', padding: '5px 5px 5px 5px', margin: '5px 5px 5px 5px'}}>
                <table style={{width: 'auto', borderCollapse: 'collapse', border: 'none'}}>
                    <thead style={{fontWeight: 'bold'}}>
                    <tr>
                        <td>Ägare</td>
                        <td>Andel</td>
                        <td>Namn</td>
                        <td>Personnummer</td>
                        <td>Köpeavtal</td>
                        <td>Mobiltelefon</td>
                        <td>E-Post</td>
                    </tr>
                    </thead>
                    {persons}
                </table>
            </div>
        )
    }
}

export default Owners;