import React from "react";
import Utils from "../../../utils/Utils";

class Loans extends React.Component {
    constructor(props) {
        super(props);

        this.state = {apartmentId: props.apartmentId, isLoading: true};
    }

    componentDidMount() {
        const targetUrl = Utils.createUrl('/brfa/api/accounting/loans');
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({loans: data, isLoading: false});
        });
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        return (
            <div style={{width: '100%', padding: '5px 5px 5px 5px', margin: '5px 5px 5px 5px'}}>
                <table style={{width: 'auto', borderCollapse: 'collapse', border: 'none'}}>
                    <tr><td>Lån totalt:</td><td>{this.state.loans.totalLoan} Kr</td></tr>
                    <tr><td>Totalyta bostäder</td><td>{this.state.loans.totalArea} m<sup>2</sup></td></tr>
                    <tr><td>Lån per m<sup>2</sup></td><td>{this.state.loans.loanPerSquaremeter} Kr</td></tr>
                </table>
            </div>
        )
    }
}

export default Loans;