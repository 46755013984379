import React from 'react';
import '../App.css';
import * as Main from "../styles/Main";
import Utils from "../utils/Utils";

class BookingView extends React.Component {
    constructor(props) {
        super(props);
        let token = this.props.match.params.token != null ? this.props.match.params.token : null;

        let personId = null;
        let uuid = null;
        if (token != null) {
            uuid = token.substring(0, 36);
            personId = token.substring(36);
        } else {
            let userInfo = Utils.getUserInfo();
            personId = userInfo.personId;
        }

        this.state = { mounted: false, width: window.innerWidth, height: window.innerHeight, isLoading: true, booking: {}, bookingId: 2, uuid: uuid, personId: personId}
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {

        this.setState({mounted: true})

        window.addEventListener("resize", this.updateDimensions);

        const header = this.state.uuid == null ? Utils.getAuthorizationHeaders() : null;

        let targetUrl = Utils.createUrl('/brfa/api/booking/' + (this.state.uuid != null ? 'token/' + this.state.uuid : this.state.bookingId));

        Utils.fetch(targetUrl, header, (data) => {
            this.setState({booking: data, isLoading: false});
        }, (error) => {
            if (error == 401) {
            }
        });
    }

    componentWillUnmount() {
        this.setState({mounted: false})
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        console.log(window.innerHeight + " " + window.innerWidth);
        if (this.state.mounted == true) {
            this.setState({height: window.innerHeight, width: window.innerWidth
            });
        }
    }

    renderSmall() {
        let booking = this.state.booking;

        let result = [];
        let personIndex;

        for (let i=0;i<booking.persons.length;i++) {
            if (booking.persons[i].idperson == this.state.personId) {
                result.push(<div>{booking.persons[i].firstName} {booking.persons[i].lastName}</div>);
                personIndex = i;
            }
        }

        let checkStyle={width: '30px', height: '30px'}

        let style = {fontSize: '12pt'}

        let rows = [];
        let curWeekNumber = 0;
        for (let i=0;i<booking.dateEntries.length;i++) {
            let checked = booking.dateEntries[i].data[personIndex];
            let personId = booking.persons[personIndex].idperson;
            let dates = [];
            if (booking.dateEntries[i].weekNumber != curWeekNumber) {
                rows.push(<tr style={{background: '#79c0f8'}}><td colspan={4}>Vecka {booking.dateEntries[i].weekNumber}</td></tr>)
            }
            curWeekNumber = booking.dateEntries[i].weekNumber;

            dates.push(<td style={style}>{booking.dateEntries[i].weekday}</td>)
            dates.push(<td style={style}>{booking.dateEntries[i].date}</td>)
            dates.push(<td style={style}>{booking.dateEntries[i].time}</td>)
            rows.push(<tr>{dates}<input style={checkStyle} type='checkbox' checked={checked} onChange={(e) => this.onChange(e, booking.dateEntries[i].id, personId)}></input></tr>)
        }

        return (
            <div style={{marginTop: '-48pt'}}>
                <div style={Main.headingStyle}>{booking.name}</div>
                <div style={{marginLeft: '10pt'}}>{booking.description}</div>
                <div style={{marginLeft: '10pt', marginTop: '14px'}}>Markera nedan vilka tillfällen du kan delta</div>
                <div style={{padding: '12px', marginRight: '6px', background: '#FFFFFF'}}>
                    <div style={Main.shadowBox}>

                        {result}
                        <div>&nbsp;</div>
                        <table>
                            <td style={{fontSize: '12pt', width: '40px'}}>Dag</td>
                            <td style={{fontSize: '12pt', width: '100px'}}>Datum</td>
                            <td style={{fontSize: '12pt', width: '40px'}}>Tid</td>
                            {rows}
                        </table>
                    </div>
                </div>
            </div>);
    }

    render() {
        if (this.state.isLoading == true) {
            return "";
        }

        if (this.state.height < 500 || this.state.width < 500) {
            return this.renderSmall();
        }

        let checkStyle={width: '20px', height: '20px'}

        let tableHeader = {background: "rgba(42,146,252,0.22)"}

        let persons = [];
        let booking = this.state.booking;
        for (let i=0;i<booking.persons.length;i++) {
            persons.push(<td style={tableHeader}>{booking.persons[i].firstName} {booking.persons[i].lastName} {booking.persons[i].role}</td>)
        }

        let bookings = [];

        let personName;

        let best = [];
        let high = 0;

        for (let i=0;i<booking.dateEntries.length;i++) {
            let count = 0;
            for (let k=0;k<booking.dateEntries[i].data.length;k++) {
                if (booking.dateEntries[i].data[k] == true) {
                    count++;
                }
                if (count > high) {
                    high = count;
                }
            }
        }

        for (let i=0;i<booking.dateEntries.length;i++) {
            let dates = [];
            let slots = [];
            let count = 0;
            for (let k=0;k<booking.dateEntries[i].data.length;k++) {
                if (booking.dateEntries[i].data[k] == true) {
                    count++;
                }
                best[i] = count;
            }

            if (best[i] > high) {
                high = best[i];
            }

            let style = {fontSize: '12pt'}
            if (count == persons.length) {
                style.background = "#d9fcb7";
            } else if (count >= persons.length * 0.75) {
                style.background = "#fffac3";
            } else {
                style.background = "#ffe1e1";
            }


            dates.push(<td style={style}>{booking.dateEntries[i].weekday}</td>)
            dates.push(<td style={style}>{booking.dateEntries[i].date}</td>)
            dates.push(<td style={style}>{booking.dateEntries[i].time}</td>)
            for (let j=0;j<booking.persons.length;j++) {
                let entry = booking.dateEntries[i].data[j];
                let personId = booking.persons[j].idperson
                if (booking.persons[j].idperson != this.state.personId) {
                    slots.push(<td style={{textAlign: 'center'}}><input style={checkStyle} type='checkbox' disabled defaultChecked={entry} onChange={(e) => this.onChange(e, booking.dateEntries[i].id, personId)}></input></td>)
                } else {
                    personName = booking.persons[j].firstName + " " + booking.persons[j].lastName;
                    slots.push(<td style={{textAlign: 'center'}}><input style={checkStyle} type='checkbox' defaultChecked={entry} onChange={(e) => this.onChange(e, booking.dateEntries[i].id, personId)}></input></td>)
                }
            }
            if (best[i] >= high) {
                bookings.push(<tr style={{border: 'solid blue'}}>{dates}{slots}<td>{best[i]}</td></tr>)
            } else {
                bookings.push(<tr>{dates}{slots}<td>{best[i]}</td></tr>)
            }
        }

        return (
            <>
            <div style={Main.headingStyle}>Mötesbokning {booking.name}</div>
                <div style={{margin: '12px'}}>
                    <div>{booking.description}</div>
                    <div>{personName}</div>
                </div>
                <div style={{background: 'ltgray', padding: '12px'}}>
                    <div style={Main.shadowBox}>
                    <table>
                        <tr>
                            <td style={{width: '40px'}}>Dag</td>
                            <td style={{width: '100px'}}>Datum</td>
                            <td style={{width: '40px'}}>Tid</td>
                            {persons}
                        </tr>
                        {bookings}
                    </table>
                    </div>
                </div>
                <div style={{display: 'flex', flexFlow: 'row Wrap', alignItems: 'center', justifyContent: 'center'}}>
                </div>
            </>
        );
    }
    onChange(event, bookingOptionsId, pid) {
        let bookingId = this.state.bookingId;
        // const targetUrl = Utils.createUrl('/brfa/api/booking/'+bookingId+'/option/' + bookingOptionsId +'/' + pid + '/' + event.target.checked);
        const targetUrl = Utils.createUrl('/brfa/api/booking/'+(this.state.uuid != null ? 'token/' + this.state.uuid : this.state.bookingId) + '/option/' + bookingOptionsId +'/' + pid + '/' + event.target.checked);
        const headers = this.state.uuid == null ? Utils.getAuthorizationHeaders() : null;

        const requestOptions = {
            method: 'PUT',
            headers: Utils.getAuthorizationHeaders().headers,
            body: JSON.stringify(this.state.verification)
        }

        fetch(targetUrl, requestOptions)
            .then(response => {
                return response.json();
            }).then(data => {
                this.setState({booking: data, isLoading: false});
        }).catch(error => {
            this.setState({isLoading: false});
        });

        return;

    }
}

export default BookingView;
