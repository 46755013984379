import React from "react";
import Utils from "../../utils/Utils";

class SMSBatchRecipients extends React.Component {
    constructor(props) {
        super(props);

        this.state = {batchId: props.batchId, isLoading: true};

        this.itemStyle = {
            cursor: 'pointer',
            fontSize: '11pt'
        }
    }

    componentDidMount() {
        if (this.state.bacthId == null) {return}
        this.getBatchRecipients(this.state.bacthId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.batchId !== this.props.batchId) {
            this.getBatchRecipients(this.props.batchId);
        }
    }

    getBatchRecipients(batchId) {
        this.setState({details: null, recipients: []})
        let targetUrl = Utils.createUrl('/brfa/api/service/sms/delivery/' + batchId);
        let headers = Utils.getAuthorizationHeaders();
        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({recipients: data, selected: batchId, isLoading:  false});
        });

    }

    render() {
        if (this.state.isLoading) {
            return <div></div>;
        }

        let recipients = [];
        if (this.state.recipients.length>0) {
            for (let i=0;i<this.state.recipients.length;i++) {
                let recipient = this.state.recipients[i];
                recipients.push(
                    <tr>
                        <td>{recipient.timestamp}</td>
                        <td>{recipient.firstName} {recipient.lastName}</td>
                        <td>{recipient.recipient}</td>
                        <td>{recipient.messageId}</td>
                        <td>{recipient.code}</td>
                        <td>{recipient.shortMessage}</td>
                        <td>{recipient.message}</td>
                        <td>{recipient.price}</td>
                        <td>{recipient.currency}</td>
                    </tr>
                )
            }
        }


        return (
            <div style={{margin: '12px', fontFamily: 'Arial', fontSize: '14px'}}>
                <table>
                    <thead>
                    <tr>
                        <td>Mottaget</td>
                        <td>Namn</td>
                        <td>Mobilnummer</td>
                        <td>Meddelande id</td>
                        <td>Status</td>
                        <td>Resultat</td>
                        <td>Meddelande</td>
                        <td>Pris</td>
                        <td>Valuta</td>
                    </tr>
                    </thead>
                    {recipients}
                </table>
            </div>
        )
    }
}

export default SMSBatchRecipients;