import React from "react";
import Timeline from "../components/calendar/Maintenance/Timeline";
import Utils from "../utils/Utils";
import * as Main from "../styles/Main";

class TimelineView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {person: {}, isLoading: false};
    }

    componentDidMount() {
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        let cols = [];

        for (let i = 0;i<366;i++) {
            cols.push(<td style={{border: '0px important!'}}></td>)
        }

        return (
            <div>
                <div style={Main.headingStyle}>Timeline</div>
                <Timeline/>
            </div>
        )
    }
}

export default TimelineView;