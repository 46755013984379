import React from 'react';
import TransactionForm from "./TransactionForm";

class AccountingView extends React.Component {
    constructor(props) {
        super(props);

        // const queryParams = new URLSearchParams(window.location.search);
        let verificationId = this.props.match != null ? this.props.match.params.verification != null ? this.props.match.params.verification : null : null;
        this.state={verificationId: verificationId}
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <TransactionForm verificationId = {this.state.verificationId}/>
            </div>
        );
    }
}

export default AccountingView;
