import React from "react";
import YearlyComparationChart from "../components/economy/YearlyComparationChart";
import * as Main from "../styles/Main";

class MultiYearView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {person: {}};
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <div style={Main.headingStyle}>Flerårsöversikt{this.state.year}</div>
                <div>
                    <YearlyComparationChart title='Intäkter' accountClass='INCOME'/>
                    <YearlyComparationChart title='Driftskostnader' accountClass='OPERATING_COSTS'/>
                    <YearlyComparationChart title='Tillgångar' accountClass='ASSETS'/>
                    <YearlyComparationChart title='Skulder' accountClass='LOANS'/>
                </div>
            </div>
        )
    }
}

export default MultiYearView;