import React from "react";
import * as Main from "../../styles/Main";

class SurvayForm extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {

        return (
            <>
                <div style={Main.headingStyle}>Enkät</div>
                <div style={{margin: '6px 6px 6px 6px'}}>

                </div>
            </>
        )
    }
}

export default SurvayForm;