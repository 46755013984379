import React from "react";
import {
    calendarContainerStyle,
    calendarHeadingStyle,
    calendarTodayStyle,
    calendarWeekDayStyle,
    calendarWeekendStyle
} from "../../styles/Main";
import Utils from "../../utils/Utils";
import ReactTooltip from "react-tooltip";
import TaskDetails from "./TaskDetail";

const weekDays = ['Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör', 'Sön'];
const months = ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"]

class Calendar extends React.Component {

    constructor(props) {
        super(props);

        const today = new Date();

        let year = today.getFullYear();
        let month = today.getMonth() + 1;

        let localHeadingStyle = Object.assign({}, calendarHeadingStyle);
        let localTodayStyle = Object.assign({}, calendarTodayStyle);
        let localWeekDayStyle = Object.assign({}, calendarWeekDayStyle);
        let localWeekendStyle = Object.assign({}, calendarWeekendStyle);

        localWeekDayStyle.height = this.props.size + "px";
        localWeekDayStyle.width = this.props.size + "px";;
        localWeekendStyle.height = this.props.size + "px";;
        localWeekendStyle.width = this.props.size + "px";;
        localTodayStyle.height = this.props.size + "px";;
        localTodayStyle.width = this.props.size + "px";;
        localHeadingStyle.width = this.props.size + "px";;

        this.state = ({isLoading: true, currentTask: null, calendarData: [], year: year, month: month, localHeadingStyle: localHeadingStyle, localTodayStyle: localTodayStyle, localWeekDayStyle: localWeekDayStyle, localWeekendStyle: localWeekendStyle})
    }

    componentDidMount() {
        this.getData(this.state.year, this.state.month);
    }

    render() {
        if (this.state.isLoading) {
            return "Loading...";
        }

        console.log(this.state.month);

        let today = new Date();

        let days = [];

        let date = new Date(this.state.year, this.state.month - 1, 1);
        // console.log(date);
        let firstDayOfWeek = date.getDay() -1;
        let nextMonth = new Date(this.state.year, this.state.month, 0)
        // console.log(firstDayOfWeek)
        // console.log(nextMonth);

        let lastDayOfMonth = nextMonth.getDate()

        let day = 1;

        let fontSize = this.props.fontSize;
        fontSize = 24;

        days.push(
            <>
                <div style={{cursor: 'pointer', fontSize: fontSize, textAlign: 'left', background: '#003f7e', color: '#FFFFFF'}}></div>
                <div style={{padding: '10px', fontSize: fontSize, gridColumn: '2/6', textAlign: 'center', background: '#003f7e', color: '#FFFFFF'}}>{months[date.getMonth()]} {date.getFullYear()}</div>
                <div onClick={(e) => this.increaseMonth(e)} style={{cursor: 'pointer', fontSize: fontSize, textAlign: 'center', background: '#003f7e', color: '#FFFFFF'}}>&#x2B06;</div>
                <div onClick={(e) => this.decreaseMonth(e)} style={{cursor: 'pointer', fontSize: fontSize, textAlign: 'center', background: '#003f7e', color: '#FFFFFF'}}>&#x2B07;</div>
            </>)

        for (let i=0;i<7;i++) {
            days.push(<div style={this.state.localHeadingStyle}>{weekDays[i]}</div>);
        }

        let toolTips = [];


        for (let i=0;i<firstDayOfWeek + lastDayOfMonth;i++) {
            let item = this.state.calendarData[day-1];
            let descriptions = [];
            let calendarItems = [];

            if (item != null) {
                    item.tasks.map((task, index) => {
                    var taskStyle = {};
                    if (Date.parse(item.date) <= Date.now()) {
                        taskStyle = {fontSize: '10', margin: '4pt', textAlign: 'left', width: '100%', color: 'red'};
                    } else {
                        taskStyle = {fontSize: '10pt', margin: '4pt', textAlign: 'left', width: '100%'};
                    }

                    calendarItems.push(<div style={taskStyle}>{task.taskId + ' ' +
                        (task.description == null ? '' : task.description )+ ' ' +
                        (task.comment == null ? '' : task.comment) + ' ' +
                        ('Ansvarig: ' + task.responsible.firstName + ' ' + task.responsible.lastName + ' ' + task.responsible.mobilePhone + ' ' + task.responsible.email)
                        }</div>)

                });
            }

            if (i >= firstDayOfWeek && i < firstDayOfWeek + lastDayOfMonth) {

                if (calendarItems.length > 0) {
                    let desc = this.getCompleteDay(day, calendarItems)
                    if (this.props.size >= 2000) {
                        descriptions.push(desc);
                    } else {
                        descriptions.push(<>*<ReactTooltip id={'day'+day} place="top" effect="solid">{<div>{desc}</div>}</ReactTooltip></>);
                    }
                }

                if ((i + 2) % 7 == 0 || (i + 1) % 7 == 0) {
                    if (day == today.getDate() && this.state.month == today.getMonth() + 1) {
                        days.push(<div onClick={(e) => this.onClick(e, this.state.calendarData[i-1])} data-tip data-for={'day'+day} style={this.state.localTodayStyle}><div>{day}</div><div>{descriptions}</div></div>);
                    } else {
                        days.push(<div onClick={(e) => this.onClick(e, this.state.calendarData[i-1])} data-tip data-for={'day'+day} style={this.state.localWeekendStyle}><div>{day}</div><div>{descriptions}</div></div>);
                    }
                } else {
                    if (day == today.getDate() && this.state.month == today.getMonth() + 1) {
                        days.push(<div onClick={(e) => this.onClick(e, this.state.calendarData[i-1])} data-tip data-for={'day'+day} style={this.state.localTodayStyle}><div>{day}</div><div>{descriptions}</div></div>);
                    } else {
                        days.push(<div onClick={(e) => this.onClick(e, this.state.calendarData[i-1])} data-tip data-for={'day'+day} style={this.state.localWeekDayStyle}><div>{day}</div><div>{descriptions}</div></div>);
                    }
                }
                day++;
            } else {
                days.push(<div style={this.localWeekDayStyle}></div>);
            }
        }

        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        let taskDetails = [];
        if (this.state.currentTask != null) {
            taskDetails.push(<TaskDetails task={this.state.currentTask} dismiss={() => this.onDismiss()}/>)
        }

        return (
            <div style={calendarContainerStyle}>
                {days}
                {taskDetails}
            </div>
        )
    }

    getCompleteDay(day, calendarItems) {
        let descriptions = [];
        descriptions.push(<div>{calendarItems}</div>);
        return descriptions;
/*
        if (this.props.size > 100) {
            descriptions.push(<div style={taskStyle}>{task.taskId + " " +
                (task.description == null ? '' : task.description )+ " " +
                (task.comment == null ? '' : task.comment)}</div>);
        } else {
            descriptions.push(<div>*</div>)
            toolTips.push(<ReactTooltip id={'day'+i} place="top" effect="solid">{task.taskId+' '+(task.description == null ? '' : task.description) +' '+task.comment}</ReactTooltip>);
        }

 */
    }

    decreaseMonth(e) {
        let year = this.state.year;
        let month = this.state.month - 1;
        if (month == 0) {
            year -= 1;
            month = 12;
        }

        this.getData(year, month);
    }

    increaseMonth(e) {
        let year = this.state.year;
        let month = this.state.month + 1;
        if (month == 13) {
            year += 1;
            month = 1;
        }

        this.getData(year, month);
    }

    getData(year, month) {
        const targetUrl = Utils.createUrl('/brfa/api/maintenance/calendar/' + year + "/" + month);
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {

            this.setState({year: year, month: month, calendarData: data, isLoading: false});
        });
    }

    onClick(e, task) {
        this.setState({currentTask: task});
    }

    onDismiss(e) {
        this.setState({currentTask: null});
    }
}

export default Calendar;