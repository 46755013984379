import React from "react";
import LabeledInput from "../forms/LabeledInput";
import TransactionRows from "./TransactionRows";
import Utils from "../../utils/Utils";
import LabeledCheckbox from "../forms/LabeledCheckbox";
import ImageViewer from "../filemanagement/ImageViewer";
import WindowFrame from "../dialog/WindowFrame";
import FileView from "../../views/FileView";

class Verification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {fileWindow: false, verification: props.verification, apartmentId: props.apartmentId, person: props.person, isLoading: true};
    }

    componentDidUpdate(prevProps) {
        if (prevProps.verificationId !== this.props.verificationId) {
            this.loadVerification();
            this.state.files=null;
        }
    }

    componentDidMount() {
        this.loadVerification();
    }

    loadDocuments() {
        if (this.state.verification.id == null) {
            alert("!");
            return; }

        let targetUrl = Utils.createUrl('/brfa/api/file/') + this.state.verification.id;
        const headers = Utils.getAuthorizationHeaders();
        fetch(targetUrl, headers)
            .then(response => {
                if (response.status == 200) {
                    return response.json();
                } else {
                    this.setState({error: true});
                    // throw Error("Error!");
                }
            }).then(data => {

            if (data==null) {return};

            let files = [];
            for (let j=0;j<data.length;j++) {
                let imageData = data[j].imageData;
                let imageUrls = [];
                for (let i=0;i<imageData.length;i++) {
                    for (let k=0;k<imageData[i].jpgImages.length;k++) {
                        let url = "data:image/jpeg;base64," + imageData[i].jpgImages[k];
                        imageUrls.push(url);
                    }
                    let pdfUrl = "data:image/pdf;base64,"+imageData[i].pdfDocument;

                    const file = {
                        id: data[j].id,
                        uuid: data[j].uuid,
                        objectType: "Verification",
                        objectDate: data[j].objectDate,
                        originalFileName: data[j].originalFileName,
                        uploadedFiles: this.state.uploadedFiles,
                        imageUrls: imageUrls,
                        pdfDocument: pdfUrl
                    }

                    files.push(file);
                }
            }
            this.setState({files: files, isLoading: false});
        })
    }

    loadVerification() {
        const targetUrl = Utils.createUrl('/brfa/api/accounting/verification/' + this.props.verificationId);
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                if (response.status == 200) {
                    return response.json();
                } else {
                    this.setState({error: true});
                    // throw Error("Error!");
                }
            }).then(data => {
            this.setState({verification: data, isLoading: false});
            this.state.verification = data;
            this.loadDocuments();
        })
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        let verification=this.state.verification;

        let fileWindow = [];
        if (this.state.fileWindow) {
            let content = [];
            content.push(<FileView></FileView>)
            fileWindow.push(<WindowFrame onClose={(event) => this.onClose(event)} title="Files" content = {content}></WindowFrame>);
        } else {
            fileWindow = [];
        }

        return (
            <div moveable style={{resize: 'both', display: 'grid', gridTemplateColumns: '80% 18%'}}>
                <div>
                <table class='noBorder' style={{marginLeft: '8px', width: 'calc(100% - 20px)', border: 'solid 0px'}}>
                    <tr style={{height: '48pt', border: 'none 0px'}}>
                        <td><LabeledInput
                            fontSize={this.headerFontSize}
                            name='description'
                            label={'Beskrivning'}
                            value={verification.description}
                            onChange={(e) => this.onChange(e)}>
                        </LabeledInput></td>
                        <teble>
                            <tbody>
                            <tr>
                                <td style={{paddingTop: '6px'}}><LabeledInput
                                    name='series'
                                    width='24px'
                                    fontSize={this.headerFontSize}
                                    label={'Serie'}
                                    value={verification.series}
                                    onChange={(e) => this.onChange(e)}>
                                </LabeledInput></td>
                                <td style={{paddingTop: '6px'}}><LabeledInput
                                    name='number'
                                    width='32px'
                                    fontSize={this.headerFontSize} label={'Nummer'}
                                    value={verification.number}
                                    onChange={(e) => this.onChange(e)}>
                                </LabeledInput></td>
                            </tr>
                            </tbody>
                        </teble>
                        <td><LabeledInput
                            name='registrationDate'
                            fontSize={this.headerFontSize}
                            label={'Bokföringsdatum'}
                            value={verification.registrationDate}
                            onChange={(e) => this.onChange(e)}>
                        </LabeledInput></td>
                        <td><LabeledInput
                            name='transactionDate'
                            fontSize={this.headerFontSize}
                            label={'Transaktionsdatum'}
                            value={verification.transactionDate}
                            onChange={(e) => this.onChange(e)}>
                        </LabeledInput></td>
                        <td><LabeledInput
                            name='period'
                            fontSize={this.headerFontSize}
                            label={'Period'}
                            value={verification.period}
                            onChange={(e) => this.onChange(e)}>
                        </LabeledInput></td>
                        <td>
                            <button onClick={() => this.onSave(0)} style={{
                                float: 'right',
                                padding: '0px',
                                marginLeft: '32px',
                                marginTop: '18px',
                                height: '30px',
                                width: '100px'
                            }}>SPARA
                            </button>
                        </td>
                        <td>
                            <button onClick={() => this.onSave(1)} style={{
                                float: 'right',
                                padding: '0px',
                                marginLeft: '32px',
                                marginTop: '18px',
                                height: '30px',
                                width: '140px'
                            }}>SKULDBOKNING
                            </button>
                        </td>
                    </tr>
                    <tr style={{height: '48pt'}}>
                        <td colSpan='2'><LabeledInput
                            name='reference'
                            fontSize={this.headerFontSize}
                            label={'Fakturareferens / OCR'}
                            value={verification.reference}
                            onChange={(e) => this.onChange(e)}>
                        </LabeledInput></td>
                        <td colSpan='2'><LabeledInput
                            name='recipient'
                            fontSize={this.headerFontSize}
                            label={'Namn'}
                            value={verification.transactionName == null ? '' : verification.transactionName}
                            onChange={(e) => this.onChange(e)}>
                        </LabeledInput></td>
                        <td colSpan='2'><LabeledInput
                            name='recipient'
                            fontSize={this.headerFontSize}
                            label={'Avsändare / Mottagare'}
                            value={verification.senderOrRecipient == null ? '' : verification.senderOrRecipient}
                            onChange={(e) => this.onChange(e)}>
                        </LabeledInput></td>
                        <td colSpan='2'><LabeledInput
                            name='details'
                            fontSize={this.headerFontSize}
                            label={'Detailjer'}
                            value={verification.details == null ? '' : verification.details}
                            onChange={(e) => this.onChange(e)}>
                        </LabeledInput></td>
                        <td><LabeledCheckbox
                            label="Autogiro"
                            checked={verification.autogiro > 0 ? 'checked' : ''}/></td>
                        <td></td>
                    </tr>
                </table>
                    <TransactionRows onUpdate={(transactions) => this.onTransactionUpdate(transactions)}
                                     transactions={this.state.verification.transactions}></TransactionRows>
                </div>
                <div width='100%'
                    // style={{margin: '12px'}}
                     onDragEnter={(event) => this.onDragEnter(event)}
                     onDragOver={(event) => this.onDragOver(event)}
                     onDragoLeave={(event) => this.onDragLeave(event)}
                     onDrop={(event) => this.onDrop(event)}>
                    <button onClick={() => this.openFileWindow()}>Filfönster</button>
                    <ImageViewer
                        previewTopOffset={-100}
                        parent={this}
                        files={this.state.files}
                        size='200px'
                        autoFit={false}
                        objectId={verification.id}
                        objectKey={verification.series + verification.number}
                        objectDate={verification.transactionDate}>
                    </ImageViewer>
                    {fileWindow}
                </div>
            </div>
        );
    }

    onDragEnter(event) {
        event.preventDefault();
    }
    onDragOver(event) {
        event.preventDefault();
    }

    onDragLeave(event) {
        event.preventDefault();
    }

    onDrop(event) {
        if (event.dataTransfer.getData("text")==null) {return;}
        let file = JSON.parse(event.dataTransfer.getData("text"));

        file.objectId = this.state.verification.id;
        file.objectKey = this.state.verification.series + this.state.verification.number;

        // alert(JSON.stringify(file));

        if (this.state.verification != null) {

            const targetUrl = Utils.createUrl('/brfa/api/file/assign');
            const headers = Utils.getAuthorizationHeaders();

            const requestOptions = {
                method: 'POST',
                headers: Utils.getAuthorizationHeaders().headers,
                body: JSON.stringify(file)
            }

            fetch(targetUrl, requestOptions)
                .then(response => {
                    this.loadDocuments();
            });
        }
    }

    openFileWindow() {
        this.setState({fileWindow: true})
    }

    onClose() {
        this.setState({fileWindow: false})
    }

    onChange(e) {
        let name = e.target.name;
        let verification = this.state.verification;
        verification[name] = e.target.value;
        this.setState({verification: verification});
    }

    onTransactionUpdate(transactions) {
        let verification = this.state.verification;
        verification.transactions = transactions;
        this.setState( { verification: verification } );
    }

    onSave(debt) {
        if (this.state.verification != null) {

            const targetUrl = Utils.createUrl('/brfa/api/accounting/verification?debt=' + debt);
            const headers = Utils.getAuthorizationHeaders();

            const requestOptions = {
                method: 'POST',
                headers: Utils.getAuthorizationHeaders().headers,
                body: JSON.stringify(this.state.verification)
            }

            fetch(targetUrl, requestOptions)
                .then(response => {
                    return response;
                });
            return;
        }
    }

    syncModel(event) {

        let person = this.state.person;
        person[event.target.name] = event.target.value ;

        this.setState({person: person})
    }

}

export default Verification;