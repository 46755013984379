import React from 'react';
import LabeledInput from "../../forms/LabeledInput";
import Utils from "../../../utils/Utils";
import LabeledButton from "../../forms/LabeledButton";
import LabeledTextArea from "../../forms/LabeledTextArea";
import LabeledDropDown from "../../forms/LabeledDropDown";

class TaskDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = { task: props.task, index: props.index, board: [] }
        // alert(JSON.stringify(props.task.taskItem.plannedCost));
    }

    componentDidMount() {
        const targetUrl = Utils.createUrl('/brfa/api/maintenance/board');
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({board: data, isLoading: false});
        });
    }



    render() {
        let personId = (this.state.task == null || this.state.task.taskItem == null || this.state.task.taskItem.person == null) ? -1 : this.state.task.taskItem.person.id;

        let qrCode;

        if (this.state.task.taskItem.qrCodeImageData != null) {
            var image = 'data:image/png;base64,' + this.state.task.taskItem.qrCodeImageData;
            qrCode = (<div style={{float: 'left', width: '200px', height: '200px', background: '#FFFFFF'}}><div><img style={{width: '200px', height: '200px'}} src={image}/></div><div style={{fontSize: '12pt', marginLeft: '12pt'}}>Läs för kalenderbokning</div></div>);
        }

        return (
            <div style={{top: '50%', left: '50%', position: 'absolute'}}>
                <div style={{
                    position: "fixed",
                    top: '50px',
                    textAlign: "left",
                    background: "#FFFFFF",
                    border: "1px solid black",
                    width: "80%",
                    // height: '50%',
                    marginLeft: '-40%',
                    marginTop: '25%',
                    zIndex: '100'
                    }}>
                    <div style={{zIndex: '10', padding: '12px', height: '24px', background: '#44cdff'}}><div style={{float: 'left'}}>Brf Assistant</div><div style={{margin: '0px', float: 'right'}}><button style={{height: '24pt', width: '24pt'}} onClick = {() => this.onCancel(this.state.task.taskItem, this.state.index)}>X</button></div></div>
                        <LabeledInput onChange={(e) => this.syncModel(e)} style={{float: 'left'}} name="heading" label='Rubrik' width='400px' value={this.state.task.taskItem == null ? '' : this.state.task.taskItem.heading}/>
                    <LabeledInput onChange={(e) => this.syncModel(e)} style={{float: 'left'}} name="startDate" label='Start' value={this.state.task.taskItem == null ? '' : this.state.task.taskItem.startDate}/>
                    <LabeledInput onChange={(e) => this.syncModel(e)} style={{float: 'left'}} name="due" label='Färdigdatum' value={this.state.task.taskItem == null ? '' : this.state.task.taskItem.due}/>
                        <LabeledInput onChange={(e) => this.syncModel(e)} style={{float: 'left'}} name="plannedCost" label='Totalpris' value={this.state.task.taskItem == null ? '' : Utils.numberAsCurrency(this.state.task.taskItem.plannedCost == null ? 0 : this.state.task.taskItem.plannedCost)}/>
                        <LabeledInput onChange={(e) => this.syncModel(e)} style={{float: 'left'}} name="actualCost" label='Utfall' value={this.state.task.taskItem == null ? '' : Utils.numberAsCurrency(this.state.task.taskItem.actualCost == null ? 0 : this.state.task.taskItem.actualCode)}/>
                        <LabeledInput onChange={(e) => this.syncModel(e)} style={{float: 'left'}} name="account" label='Konto' value={this.state.task.taskItem == null ? '' : this.state.task.taskItem.account == null ? 0 : this.state.task.taskItem.account}/>
                        <LabeledInput onChange={(e) => this.syncModel(e)} style={{float: 'left'}} name="priority" label='Prioritet' value={this.state.task.taskItem == null ? '' : this.state.task.taskItem.priority}/>
                        <LabeledInput onChange={(e) => this.syncModel(e)} style={{float: 'left'}} name="taskStage" label='Status' value={this.state.task.taskItem == null ? '' : this.state.task.taskItem.taskStage.description}/>
                        <LabeledDropDown onChange={(e) => this.assign(e)} style={{float: 'left'}} label={'Ansvarig'} selection={personId} list={this.state.board}/>
                        <LabeledTextArea onChange={(e) => this.syncModel(e)} name="description" label="Beskrivning" value={this.state.task.taskItem == null ? '' : this.state.task.taskItem.description} rows='10' cols='80'></LabeledTextArea>
                    <div>
                        {qrCode}
                    </div>
                    <div style = {{float: 'left', width: '100%', padding: '5px'}}>
                        <LabeledButton label='Spara' onClick = {() => this.onSave(this.state.task.taskItem, this.state.index)}/>
                        <LabeledButton label='Avbryt' onClick = {() => this.onCancel(this.state.task.taskItem, this.state.index)}/>
                    </div>
                </div>
            </div>
        );
    }

    syncModel(event) {
        let task = this.state.task;
        task.taskItem[event.target.name] = event.target.value ;
        this.setState({task: task})
    }

    assign(event) {
        let index = event.target.selectedIndex;
        let value = event.target.options[index].value;
        let task = this.state.task;

        if (task.taskItem.person == null) {
            task.taskItem.person = {};
        }

        task.taskItem.person.id = value;
    }

    onSave(taskItem, index) {
        this.props.onSave(taskItem, index)
    }

    onCancel(taskItem) {
        this.props.onCancel(taskItem);
    }
}

export default TaskDialog;
