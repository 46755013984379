import React from "react";
import WindowFrame from "../dialog/WindowFrame";
import Uploader from "./Uploader";
import ReactDOM from "react-dom";
import Thumbnail from "./Thumbnail";

class ImageViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { selectedThumb: null, files: props.files, objectId: props.objectId, objectKey: props.objectKey, objectDate: props.objectDate, size: this.props.size == null ? '400px' : this.props.size};
    }
    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps.files !== this.props.files) {
            this.setState({files: this.props.files})
        }
        if (prevProps.size !== this.props.size) {
            this.setState({size: this.props.size})
        }
    }

    render() {
        if (this.state.files == null) {
            return "";
        }

        let content = [];

        if (this.state.selectedThumb != null) {
            const style =  {
                // display: 'block',
                position: 'absolute',
                top: '30%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                minWidth: '512px',
                minHight: '512px',
                width: 'auto',
                height: '300px',
                background: '#00FF00',
                // border: '2px solid red',
                borderRadius: '5px',
                beckground: 'blue',
                // boxShadow: '0px 1px 12px rgba(0.5, 0.5, 0.5, 0.5)'
                // zIndex: '100'
            }

            const container = document.getElementById('root');
            content.push(<img src={this.state.selectedThumb}></img>);
            ReactDOM.render(<WindowFrame content={content}></WindowFrame>, container);
        }

        let images = [];

        let style = {fontFamily: 'arial', fontSize: '14px', display: 'grid', rowGap: '1rem', columnGap: '1rem'};

        if (this.props.autoFit) {
            style.gridTemplateColumns = 'repeat(auto-fit, ' + this.state.size + ')';
        } else {
            style.gridTemplateColumns = 'repeat(' + this.state.size + ')';
        }

        content.push(<div><Uploader onUpload={(files) => this.onUpload(files)} files={this.state.files} objectId={this.state.objectId} objectKey={this.state.objectKey} objectDate={this.state.objectDate}></Uploader></div>);

        for (let i=0;i<this.state.files.length;i++) {
            let fileName = null;
            let prevFileName = null;
            let file = this.state.files[i];

            for (let j = 0; j < file.imageUrls.length; j++) {
                if (file.originalFileName != prevFileName) {
                    fileName = file.originalFileName;
                } else {
                    fileName = '';
                }

                images.push(
                        <Thumbnail previewTopOffset={this.props.previewTopOffset}
                                   file ={file}
                                   src = {file.imageUrls[j]}
                                   fileName = {fileName}
                                   page = {j+1}>
                        </Thumbnail>)

                prevFileName = file.originalFileName;
            }
        }
        content.push(<div style={{padding: '20px', background: '#00FF00'}} onDragover={(event) => this.props.onDragover(event) } onDrop={(event) => this.props.onDrop(event)} style={style}>{images}</div>);
        if (this.props.newWindow) {
            return (<WindowFrame onClose={() => this.onClose()} title="Files" content = {content}></WindowFrame>);
        } else if (this.props.selectedThumb != null) {
            return (<>{this.state.selectedThumb}</>);
        } else {
            return (<>{content}</>);
        }
    }

    onThumbClick(event, imageData) {
        this.setState({selectedThumb: imageData});
    }

    dragStart(event, file) {
        let fileJson = JSON.stringify(file);
        event.dataTransfer.setData("text", fileJson);
    }
    onUpload(file) {
        this.setState({dymmu: ''});
    }
    onClose() {
        alert("!");
    }
}

export default ImageViewer;
