import React from "react";
import '../../App.css';
class LabeledDropDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {fields: props.fields, isLoading: false};
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps.formTypes !== this.props.formTypes) {
            this.setState({formTypes: this.props.formTypes});
        }
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        let multiple = this.props.multiple == true ? 'multiple' : '';

        return (
            <div style={{fontSize: '11pt', float: 'left', height: '100%'}}>
                <div style={{padding: '5px', float: 'left'}}>{this.props.label}</div><br/>
                <select multiple={multiple} class="styled-select" name={this.props.name} onChange = {(e) => this.props.onChange(e)} style={{padding: '2px', margin: '5px', fontSize: '12pt', height: '100%'}}>
                    {this.props.list.map((item, index) => {
                        let selected = (item.id == this.props.selection ? 'selected' : '');
                        // let role = item.id == null ? '' : '(' + item.role+ ')';
                        return (<option
                            key = {item.id}
                            value = {item.id}
                            name = {this.props.name}
                            selected={selected}>
                                {item.value}
                        </option>)
                    })}
                </select>
            </div>
        )
    }
}

export default LabeledDropDown;