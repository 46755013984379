import React from "react";
import * as Main from "../styles/Main";
import LabeledInput from "../components/forms/LabeledInput";

class MortgageView extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {

        return (
            <>
                <div style={Main.headingStyle}>Pantsättningar</div>
                <div style={{display: 'grid', padding: '0px', gridTemplateColumns: 'auto auto auto auto auto auto auto auto auto auto auto auto'}}>
                <div><LabeledInput label='Ärendenummer'></LabeledInput></div>
                <div><LabeledInput label='Kreditnummer'></LabeledInput></div>
                <div><LabeledInput label='Datum'></LabeledInput></div>
                <div><LabeledInput label='Ägare1'></LabeledInput></div>
                <div><LabeledInput label='Ägare2'></LabeledInput></div>
                <div><LabeledInput label='Datum'></LabeledInput>
                <LabeledInput label='Signerad'></LabeledInput></div>
                <div><LabeledInput label='Datum'></LabeledInput>
                <LabeledInput label='Signerad'></LabeledInput></div>
                </div>
            </>
        )
    }
}

export default MortgageView;