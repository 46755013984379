import React from "react";
import Utils from "../../../utils/Utils";
import Mortgage from "./Mortgage";
import Owners from "./Owners";
import ApartmentData from "./ApartmentData";
import Loans from "./Loans";

class Information extends React.Component {
    constructor(props) {
        super(props);

        this.state = {apartmentId: props.apartmentId, info: [], isLoading: true};
    }

    componentDidMount() {
        const targetUrl = Utils.createUrl('/brfa/api/apartment/' + this.state.apartmentId + '/brokerinfo');
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({info: data, isLoading: false});
        });
    }

    render() {
        if (this.state.isLoading == null) {
            return <div>Loading...</div>;
        }

        let info = [];

        for (let i=0;i<this.state.info.length;i++) {
            info.push(<div><b>{this.state.info[i].paragraph} {this.state.info[i].heading}</b></div>)
            if (this.state.info[i].text === '[summary]') {
                info.push(<ApartmentData apartmentId = {this.state.apartmentId}></ApartmentData>)
            } else if (this.state.info[i].text === '[pledges]') {
                info.push(<Mortgage apartmentId = {this.state.apartmentId}></Mortgage>)
            } else if (this.state.info[i].text === '[ownerdata]') {
                info.push(<Owners apartmentId = {this.state.apartmentId}></Owners>)
            } else if (this.state.info[i].text === '[economy]') {
                info.push(<Loans></Loans>)
            } else {
                info.push(<div style={{padding: '5px 5px 5px 5px'}}
                               dangerouslySetInnerHTML={{
                    __html: this.state.info[i].text
                }}></div>)
            }
            info.push(<div><br/></div>)
        }

            return (
            <div style={{width: '100%', padding: '5px 5px 5px 5px', margin: '5px 5px 5px 5px'}}>
                <div style={{fontSize: '18px', margin: '10px 0px 10px 0px'}}><b>Mäklarbild BrfNygård lägenhet {this.state.apartmentId}</b></div>
                {info}
            </div>
        )
    }
}

export default Information;