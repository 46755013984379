import React from "react";
import Utils from "../../utils/Utils";
import {Link} from "react-router-dom";

class PopupMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {isLoading: false, windowWidth: 0, windowHeight: 0, menus: props.menus, menuItem: null};

        this.userGroupId = Utils.getLoginInfo().user.userGroupId;

        this.itemStyle = {
            cursor: 'pointer',
            fontSize: '11pt'
        }
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
    handleResize = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight, menuItem: null });
    }

    render () {
        return (<div onMouseOut={(e) => this.onMouseOut(e)}
                     style={{color: '#000000',
                         position: 'absolute',
                         padding: '8px',
                         fontSize: '10pt',
                         lineHeight: '1.6',
                         background: '#FFFFFF',
                         width: 'auto',
                         height: 'auto',
                         cursor: 'pointer',
                         boxShadow: '0px 1px 12px rgba(0.5, 0.5, 0.5, 0.5)',
                         borderRadius: '5px'}}>
            {this.state.menus.map((item, index) => {
                let background = this.state.menuItem == index ? '#444444' : '#FFFFFF';
                let style = {color: '#000000', background: background, margin: '0px 0px 0px 0px', padding: '0px 0px 0px 0px', fontSize: '10pt'};
                return <div style={style}
                            onClick={(event) => this.onSelectMenuItem(event, index)}
                            onMouseOver={(e) => this.onMouseOverMenuItem(e, index)}>
                    <Link style={{color: '#000000'}} to={item.link}><span style={{padding: '6px'}}><img width='16px' height='16px' src={'/icons/' + item.icon}/></span>&nbsp;{item.label}</Link>
                </div>
            })}
        </div>);
    }

    onMouseOut(event) {
    }

    onMouseOverMenuItem(event, index) {
        this.setState({menuItem: index});
    }

    onSelectMenuItem(event, index) {
        this.props.onClick(event);
    }
}

export default PopupMenu;