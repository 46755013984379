import React from "react";
import Calendar from "../components/calendar/Calendar";
import * as Main from "../styles/Main";

class CalendarView extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {

        return (
            <>
                <div style={Main.headingStyle}>Kalender</div>
                <div style={{margin: '6px 6px 6px 6px'}}>
                <Calendar size={this.props.size} fontSize='18pt'/>
            </div>
            </>
        )
    }
}

export default CalendarView;