import React from "react";
import Utils from "../../../utils/Utils";
import {gridShadowBox, gridShadowBoxFull, panelStyle, subHeadingStyle} from "../../../styles/Main";

class Mortgage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {apartmentId: props.apartmentId, isLoading: true};
    }

    componentDidMount() {
                const targetUrl = Utils.createUrl('/brfa/api/mortgage/' + this.state.apartmentId);
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({mortgages: data, isLoading: false});
        });
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        let mortgages = [];

        let tbody = [];

            let active = 0;
            for (let i=0;i<this.state.mortgages.length;i++) {
                if (this.state.mortgages[i].denotedDate == null) {
                    active++;
                }
            }

        mortgages.push(<div style={subHeadingStyle}>Panter</div>)

            if (active == 0) {
            mortgages.push(<div style={{width: '100%', fontSize: '12pt', lineHeight: '1.5'}}>För närvarande finns inga panter registrerade på lägenheten.</div>);
        } else {
            mortgages.push(<div>För närvarande finns följande {active} panter registrerade på lägenheten:</div>)
            mortgages.push(<br></br>)
            for (let i = 0; i < this.state.mortgages.length; i++) {
                if (this.state.mortgages[i].denotedDate == null) {
                    tbody.push(
                        <tbody>
                        <tr>
                            <td>{this.state.mortgages[i].ordinal}</td>
                            <td>{this.state.mortgages[i].date}</td>
                            <td>{this.state.mortgages[i].institute}</td>
                            <td>{this.state.mortgages[i].caseNumber}</td>
                            <td>{this.state.mortgages[i].creditNumber}</td>
                        </tr>
                        </tbody>)

                }
            }

            mortgages.push(
                    <table style={panelStyle}>
                        <thead style={{fontWeight: 'bold'}}>
                        <tr>
                            <td>Nr</td>
                            <td>Datum</td>
                            <td>Institut</td>
                            <td>Lånenummer</td>
                            <td>Kreditnummer</td>
                        </tr>
                        </thead>
                        {tbody}
                    </table>)

        }


        return (
            <div style={gridShadowBoxFull}>
                <div style={{width: '100%'}}>
                {mortgages}
                </div>
            </div>
        )
    }
}

export default Mortgage;