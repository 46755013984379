import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import Utils from "../utils/Utils";
import BuildInfo from "../core/buildInfo";
import InformationPopup from "../components/InformationPopup";
import buildInfo from "../core/buildInfo";

class LoginView extends Component {
    constructor() {
        super();

        this.state = {
            usernName: "",
            password: "",
            otp: "",
            isLoading: true,
            loggedIn: false,
            data: {},
            otpRequest: false,
            buildInfo: []
        }

        this.defaultInput = React.createRef();

        // localStorage.removeItem('loginInfo');
    }

    componentDidMount() {
        let targetUrl = Utils.createUrl('/brfa/api/service/version');
        const requestOptions = {
            method: 'GET',
            headers: {},
        }

        fetch(targetUrl, requestOptions)
            .then(response => {
                return response.json();
            }).then(data => {
                Utils.setVersionInfo(data);
                this.setState({buildInfo: data, isLoading: false});
        });
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // alert(errorInfo);
    }

    render() {

        if (this.state.isLoading == true) {
            return "";
        }

        const otp = [];

        if (this.state.otpRequest == true) {
            otp.push(
                <>

                    <div>SMS kod</div>
                    <div><input name="otp" onChange={(e) => this.onChange(e)} style={{width: '70%'}}
                                type='password'/></div>
                    <InformationPopup/>
                </>
            )
        }

        if (!this.state.loggedIn) {

            let tableStyle = {
                borderCollapse: 'collapse',
                border: 'none',
            };

            let commitTime = new Date(Number(this.state.buildInfo.commitTime)).toLocaleDateString();

            return (
                <div style={{
                    height: '30vh',
                    // marginTop: '200px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}>
                    <div style={{display: 'grid', marginTop: '30%', gridGap: '10pt', gridTemplateColumns: 'auto auto'}}>
                        <div></div>
                        <div style={{fontSize: '8pt', padding: '0px', margin: '0px'}}>Front: {BuildInfo.buildDate} {buildInfo.gitCommit.substring(0,7)} ({BuildInfo.buildNumber})</div>
                        <div></div>
                        <div style={{fontSize: '8pt', padding: '0px', margin: '0px'}}>Back: {commitTime} {this.state.buildInfo.commitIdAbbrev} ({this.state.buildInfo.totalCommitCount})</div>
                        <div>Användarnamn</div>
                        <div><input name="userName" ref={this.defaultInput} onChange={(e) => this.onChange(e)}
                                    style={{width: '70%'}}
                                    type='text' autofocus='true'/></div>
                        <div>Lösenord</div>
                        <div><input name="password" onChange={(e) => this.onChange(e)} style={{width: '70%'}}
                                    type='password'/></div>
                        {otp}
                        <div></div>
                        <div><input style={{width: '76%'}} value='Logga In' type='submit'
                                    onClick={() => this.doLogin()}/></div>
                    </div>
                </div>
            )
        } else {

            if (Utils.getLoginInfo() != null && Utils.getLoginInfo().user.mustChangePassword) {

                return (
                    <div style={{
                        height: '30vh',
                        // marginTop: '200px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}>
                        <div style={{
                            display: 'grid',
                            marginTop: '30%',
                            gridGap: '10pt',
                            gridTemplateColumns: 'auto auto'
                        }}>
                            <div style={{
                                marginBottom: '24px',
                                fontSize: '24px',
                                textAlign: 'center',
                                gridColumn: '1 / span 2'
                            }}>Ditt lösenord måste bytas
                            </div>
                            <div>Nuvarande lösenord</div>
                            <div><input name="password" onChange={(e) => this.onChange(e)} style={{width: '70%'}}
                                        type='password'/></div>
                            <div>Nytt lösenord</div>
                            <div><input name="newPassword" onChange={(e) => this.onChange(e)} style={{width: '70%'}}
                                        type='password'/></div>
                            <div>Bekräfta nytt lösenord</div>
                            <div><input name="newPasswordConfirm" onChange={(e) => this.onChange(e)}
                                        style={{width: '70%'}}
                                        type='password'/></div>
                            <div></div>
                            <div><input style={{width: '76%'}} value='Byt lösenord' type='submit'
                                        onClick={() => this.doChangePassword()}/></div>
                        </div>
                    </div>
                )
            } else {

                return (
                    <div style={{
                        height: '200px',
                        marginTop: '200px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                    }}>
                        <Redirect to='/dashboard'/>
                    </div>
                )
            }
        }
    }

    onChange(event) {
        let name = event.target.name;
        this.setState({[name]: event.target.value})
    }

    doChangePassword() {
        let targetUrl = Utils.createUrl('/brfa/api/authorization/password/change');
        const headers = Utils.getAuthorizationHeaders();
        const login = {
            password: this.state.password,
            newPassword: this.state.newPassword,
            newPasswordConfirm: this.state.newPasswordConfirm,
        }
        const requestOptions = {
            method: 'POST',
            headers: headers.headers,
            body: JSON.stringify(login)
        }

        Utils.fetch(targetUrl, requestOptions, (data) => {
            // Make sure to update the status
            let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
            loginInfo.user.mustChangePassword = false;
            localStorage.setItem("loginInfo", JSON.stringify(loginInfo));

            this.setState({data: data, isLoading: false, loggedIn: true});
        }, (error) => {
            alert(error);
        });
    }

    doLogin() {

        if (this.state.otpRequest) {
            let targetUrl = Utils.createUrl('/brfa/api/authorization/otp/verify');
            const login = {
                userId: this.state.userName,
                password: this.state.password,
                key: this.state.otpKey,
                otp: this.state.otp
            }
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(login)
            }
            this.state.isLoading = true;
            fetch(targetUrl, requestOptions)
                .then(response => {
                    if (response.status === 403) {
                        throw Error("Server error");
                    }

                    return response.json();
                }).then(data => {
                if (data.user.otpRequest != null) {
                    this.setState({otpRequest: true})
                    return;
                }
                this.props.onLogin(data);
                this.setState({data: data, isLoading: false, loggedIn: true});
            })

        } else {
            let targetUrl = Utils.createUrl('/brfa/api/authorization/login');
            const login = {
                userId: this.state.userName,
                password: this.state.password,
            }
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(login)
            }

            this.state.isLoading = true;
            Utils.fetch(targetUrl, requestOptions, (data) => {
                if (data.user.otpRequest != null) {
                    // beep();
                    this.setState({otpRequest: true, otpKey: data.user.otpRequest, isLoading: false, loggedIn: false})
                    return;
                }
                this.props.onLogin(data)
                this.setState({otpRequest: false, data: data, isLoading: false, loggedIn: true});
            }, (error) => {
                alert(error);
            });
        }
    }
}
export default LoginView;