import React from "react";
import Utils from "../utils/Utils";
import Thumbnail from "../components/filemanagement/Thumbnail";
import Uploader from "../components/filemanagement/Uploader";

class FileView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {imageCount: 0, currentIndex: 0, isLoading: true, isOpen: true};
    }

    componentDidMount() {
        this.loadDocuments();
    }

    loadDocuments() {
        let targetUrl = Utils.createUrl('/brfa/api/file/') + 0;
        const headers = Utils.getAuthorizationHeaders();
        fetch(targetUrl, headers)
            .then(response => {
                if (response.status == 200) {
                    return response.json();
                } else {
                    this.setState({error: true});
                    throw Error("Error!");
                }
            }).then(data => {

            if (data==null) {return};

            let now = new Date();

            let count = 0;

            let files = [];
            for (let j=0;j<data.length;j++) {
                let imageData = data[j].imageData;
                let imageUrls = [];
                for (let i=0;i<imageData.length;i++) {
                    for (let k=0;k<imageData[i].jpgImages.length;k++) {
                        let url = "data:image/jpeg;base64," + imageData[i].jpgImages[k];
                        imageUrls.push(url);
                        count++;
                    }
                    let pdfUrl = "data:image/pdf;base64,"+imageData[i].pdfDocument;

                    const file = {
                        id: data[j].id,
                        fileType: data[j].fileType,
                        fileName: data[j].originalFileName,
                        uuid: data[j].uuid,
                        originalFileName: data[j].originalFileName,
                        uploadedFiles: this.state.uploadedFiles,
                        imageUrls: imageUrls,
                        pdfDocument: pdfUrl
                    }

                    files.push(file);
                }
            }
            this.setState({files: files, imageCount: count, currentIndex: 0, isLoading: false});
        })
    }

    render() {

        if (this.state.isLoading == true) {
            return "Loading...";
        }

        let objectDate = new Date().toLocaleDateString();

        let content = [];
        let combined = [];
        let style = {display: 'grid', gridTemplateColumns: 'repeat(auto-fit, 300px)', margin: '5px'};
        content.push();

        let srcSet = [];

        for (let i=0;i<this.state.files.length;i++) {
            for (let j=0;j<this.state.files[i].imageUrls.length;j++) {
                srcSet.push(
                    <div style={{margin: '5px'}}>
                        <Thumbnail
                            style={{grid: 'contents', margin: '50px'}}
                            file={this.state.files[i]}
                            src={this.state.files[i].imageUrls[j]}
                            fileName={this.state.files[i].fileName}
                            page={j + 1}>
                        </Thumbnail>
                    </div>)
            }
        }

        return (<div style={style}>
            <Uploader
            objectId='0'
            objectKey='Unassigned'
            objectDate={objectDate}
            onUpload={() => this.loadDocuments()}
            >
            </Uploader>{srcSet}</div>);
    }
}

export default FileView;