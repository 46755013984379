import React from "react";
import ReactDOM from "react-dom";

class WindowFrame extends React.PureComponent {
    constructor(props) {
        super(props);
        // STEP 1: create a container <div>
        this.state=({title: this.props.title, content: this.props.content});
        this.containerEl = document.createElement('div');
        this.externalWindow = null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.content !== this.props.content) {
            this.createExternalWindow();
            this.setState({title: this.props.title, content: this.props.content});
        }
    }

    render() {
        // STEP 2: append props.children to the container <div> that isn't mounted anywhere yet
        if (this.props.content == null) {
            return "No Content!";
        }
        // return ReactDOM.createPortal(this.props.content, this.containerEl);
        return ReactDOM.createPortal(this.state.content, this.containerEl);
    }

    createExternalWindow() {
        this.externalWindow = window.open('', 'files', 'width=1040,height=680,left=200,top=200,menubar=no,toolbar=no');
        this.externalWindow.document.title = this.state.title == null ? '' : this.state.title;

        this.externalWindow.onbeforeunload = () => {
            // this.props.onClose();
            this.externalWindow.close();
        }

        // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
        this.externalWindow.document.body.appendChild(this.containerEl);
    }

    componentDidMount() {
        // STEP 3: open a new browser window and store a reference to it
        // this.externalWindow = window.open('', 'files', 'width=1000,height=400,left=200,top=200,dialog=yes,menubar=no,toolbar=no');
        this.createExternalWindow();
    }

    componentWillUnmount() {
        // STEP 5: This will fire when this.state.showWindowPortal in the parent component becomes false
        // So we tidy up by closing the window
    }
}


export default WindowFrame;