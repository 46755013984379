import React from "react";
import {Link} from "react-router-dom";

class InformationPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {person: {}, isLoading: false};

        this.itemStyle = {
            cursor: 'pointer',
            fontSize: '11pt'
        }
    }

    componentDidMount() {
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        return (
            <>
                <div class='fade-in' style={{top: '50%', left: '50%', position: 'absolute'}}>
                    <div style={{
                        position: "fixed",
                        top: '-200px',
                        textAlign: "left",
                        background: "#8eff8f",
                        border: "1px solid black",
                        width: "30%",
                        // height: '50%',
                        marginLeft: '-15%',
                        marginTop: '25%',
                        padding: '12pt',
                        zIndex: '100',
                        borderRadius: '10px',
                        boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.5)'
                    }}>
                        <div style={{float: 'left'}}>Du har en behörighet som kräver tvåfaktorinloggning</div><br/>
                        <div style={{float: 'left'}}>Vänligen mata in SMS koden som har skickats till din mobil</div>
                    </div>
                </div>
             </>
        )
    }
}

export default InformationPopup;



