import React from "react";
import Utils from "../utils/Utils";
import {panelStyle} from "../styles/Main";

class News extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false }
    }

    componentDidMount() {
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        return (
            <div style={panelStyle}>
                Nyheter
            </div>
        )
    }
}

export default News;