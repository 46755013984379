import React from 'react';
import '../App.css';
import CashFlow from "../components/economy/CashFlow";
import AccountChart from "../components/economy/AccountChart";
import * as Main from "../styles/Main";
import YearlyComparationChart from "../components/economy/YearlyComparationChart";

class ChartsView extends React.Component {
    constructor(props) {
        super(props);

        const now = new Date();
        const currentYear = now.getFullYear();

        this.years = [];
        for (let i=currentYear;i>currentYear-5;i--) {
            this.years.push(<option>{i}</option>);
        }

        this.state = {year: currentYear}
    }

    componentDidMount() {

    }

    yearSelected(e) {
        this.setState({year: e.target.value})
    }


    render() {
        return (
            <>
            <div style={Main.headingStyle}>Ekonomi {this.state.year}</div>
                <div style={{margin: '12px'}}>
                    <select onChange = { (e)  => this.yearSelected(e) }>
                        {this.years}
                    </select>
                </div>

                <div style={{display: 'flex', flexFlow: 'row Wrap', alignItems: 'center', justifyContent: 'center'}}>
                <AccountChart year={this.state.year} accountClass='INCOME'/>
                <AccountChart year={this.state.year} accountClass='OPERATING_COSTS'/>
                <AccountChart year={this.state.year} accountClass='BANK_FUNDS'/>
                <AccountChart year={this.state.year} accountClass='LOANS'/>
                <AccountChart year={this.state.year} accountClass='EXPENSES'/>
                <CashFlow year={this.state.year}/>
            </div>
            </>
        );
    }
}

export default ChartsView;
