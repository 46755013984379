import * as Main from "../styles/Main";
import LabeledInput from "./forms/LabeledInput";
import LabeledButton from "./forms/LabeledButton";
import React from "react";
import {panelStyle} from "../styles/Main";
import Utils from "../utils/Utils";

const STATE_INITIAL = 0;
const STATE_GENERATING = 1;
const STATE_FETCHING_PAGES = 2;
const STATE_VIEW = 3;

class ImageViewer extends React.Component {

    constructor(props) {
        super(props);

        let m = [
            {id: 1, name: "Jan", selected: false},
            {id: 2, name: "Feb", selected: false},
            {id: 3, name: "Mar", selected: false},
            {id: 4, name: "Apr", selected: false},
            {id: 5, name: "Maj", selected: false},
            {id: 6, name: "Jun", selected: false},
            {id: 7, name: "Jul", selected: false},
            {id: 8, name: "Aug", selected: false},
            {id: 9, name: "Sep", selected: false},
            {id: 10, name: "Okt", selected: false},
            {id: 11, name: "Nov", selected: false},
            {id: 12, name: "Dec", selected: false}
        ]

        let state = props.imageUrls == null ? STATE_INITIAL : STATE_VIEW;

        this.state = { state: state, months: m, imageUrls: [], pdfFileName: this.props.pdfFileName, pdfDocument: this.props.pdfDocument }
    }

    componentDidMount() {
    }

    createControlSection() {
        let selections = [];
        for (let i=0;i<12;i++) {
            let test = this.state.months[i].selected;
            console.log("TEST: " + test)
            selections.push(<table><tr><td><input checked={test} onClick={(e) => this.onSelect(e)} type={"checkbox"} id={this.state.months[i].id}/></td><td><label htmlFor={this.state.months[i].id}>{this.state.months[i].name}</label></td></tr></table>);
        }

        return(
            <div style={{margin: '12pt'}}>
                <div style={{marginBottom: '12px', cursor: 'wait'}}>Kryssa i de månader du vill skapa betalningsavi för</div>
                {selections}
                <br/>
                <button onClick={() => this.generateInvoices()}>Skapa inbetalningskort</button>
            </div>
        );
    }

    render() {
        let controls = this.createControlSection();

        if(this.state.state == STATE_INITIAL) {
            return controls;
        } else if(this.state.state == STATE_GENERATING) {
            return (<div>Genererar avi...</div>);
        } else if (this.state.state == STATE_VIEW) {

            let images = [];
            for (let i=0;i<this.state.imageUrls.length;i++) {
                images.push(<img src={this.state.imageUrls[i]} width='22%' style={{margin: '20px', boxShadow:  'rgba(0, 0, 0, 0.9) 0px 1px 12px', padding: '5px'}}/>);
            }

            let style = {float: 'left', width: '100%', justifyContent: 'center', cursor: this.state.cursor};

            return (
                <div style={style}>
                    {controls}
                    {images}<br/>
                    <button><a download={this.state.pdfFileName} href={this.state.pdfDocument}>Ladda ner PDF</a></button>
                </div>
            )
        }
    }

    onSelect(e) {
        let month = this.state.months[e.target.id-1];
        month.selected = e.target.checked;
        this.setState({months: this.state.months})
        }

    generateInvoices(e) {
            this.setState({ state: STATE_GENERATING });

            let apartmentId = Utils.getLoginInfo().user.apartmentId;
            let today = '2022-12-20'

            let months = "";
            let first = true;
            for (let i = 0;i<this.state.months.length;i++) {
                if (this.state.months[i].selected) {
                    if (first == false) {
                        months += ","
                    }
                    months = months + this.state.months[i].id;
                    first = false;
                }
            }

            let targetUrl = Utils.createUrl('/brfa/api/admin/generate/invoice/'+apartmentId+'/'+today+'/'+ months +'?overlay=false');

            const headers = Utils.getAuthorizationHeaders();
            // headers.responseType =  'blob';
            fetch(targetUrl, headers)
                .then(response => {
                    return response.json();
                }).then(data => {
                let imageUrls = this.state.imageUrls;
                let fileName = data.fileName;
                for (let i=0;i<data.imageData.jpgImages.length;i++) {
                    let url = "data:image/jpeg;base64,"+data.imageData.jpgImages[i];
                    imageUrls[i] = url;
                }
                let url = "data:image/pdf;base64,"+data.imageData.pdfDocument;

                this.setState({ imageUrls: imageUrls, pdfDocument: url, pdfFileName: fileName, state: STATE_VIEW })
            });
        }

    downloadPdf() {
        const url = window.URL.createObjectURL(
            new Blob([this.state.pdfDocument]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `FileName.pdf`)

    }
}

export default ImageViewer;
