import React from "react";
import Calendar from "../components/calendar/Calendar";
import TopBar from "../components/TopBar";
import * as Main from "../styles/Main";
import Utils from "../utils/Utils";

class BudgetView extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true, expenses: {}, percent: [], amount: []}
    }

    componentDidMount() {
        const header = Utils.getAuthorizationHeaders();

        let targetUrl = Utils.createUrl('/brfa/api/accounting/balance/'+'2020, 2021, 2022' +'/ALL');
        Utils.fetch(targetUrl, header, (data) => {
            this.setState({expenses: data, isLoading: false});
        }, (error) => {
            if (error == 401) {
            }
        });
    }

    render() {

        if (this.state.isLoading) {
            return "Loading..."
        }

        let assets = [];
        this.state.expenses.balances.map((balance, row) => {

            var now = new Date();
            var start = new Date(now.getFullYear(), 0, 0);
            var diff = now - start;
            var oneDay = 1000 * 60 * 60 * 24;
            var day = Math.floor(diff / oneDay);

            assets.push(<tr>
                <td>{balance.account}</td>
                <td>{balance.name}</td>
                <td>{Utils.numberAsCurrency(balance.yearlyBalances[balance.yearlyBalances.length-5])}</td>
                <td>{Utils.numberAsCurrency(balance.yearlyBalances[balance.yearlyBalances.length-4])}</td>
                <td>{Utils.numberAsCurrency(balance.yearlyBalances[balance.yearlyBalances.length-3])}</td>
                <td>{Utils.numberAsCurrency(balance.yearlyBalances[balance.yearlyBalances.length-2])}</td>
                <td><input autocomplete="off" type = "number" style={{border: 'none'}} name='percent' value={this.state.percent[row]} onChange={(e) => this.onChange(row, e)}/></td>
                <td><input autocomplete="off" type = "number" style={{border: 'none'}}name='amount' value={this.state.amount[row]} onChange={(e) => this.onChange(row, e)}/></td>
                <td><input autocomplete="off" type = "number" style={{border: 'none'}}name='budget' onChange={(e) => this.onChange(row, e)} value={balance.yearlyBalances[balance.yearlyBalances.length-1]}/></td>
            </tr>)
        })

        return (
            <>
                <div style={Main.headingStyle}>Budget</div>
                <div style={{margin: '6px 6px 6px 6px'}}>
                <table>
                    <thead>
                        <th>Konto</th>
                        <th>Benämning</th>
                        <th>2020</th>
                        <th>2021</th>
                        <th>Hittills</th>
                        <th>Prognos</th>
                        <th>Procent</th>
                        <th>Belopp</th>
                        <th>Budget</th>
                    </thead>
                    {assets}
                </table>
                    <button onClick={e => this.onSave(e)}>SPARA BUDGET</button>
                </div>
            </>
        )
    }

    onSave(e) {
        const targetUrl = Utils.createUrl('/brfa/api/accounting/budget');
        const headers = Utils.getAuthorizationHeaders();

        let records = []
        this.state.expenses.balances.map((balance, row) => {
            if (balance.yearlyBalances[4] != null) {
                records.push({account: balance.account,  amount: balance.yearlyBalances[4]});
            }
        });

        const requestOptions = {
            method: 'POST',
            headers: Utils.getAuthorizationHeaders().headers,
            body: JSON.stringify(records)
        }

        fetch(targetUrl, requestOptions)
            .then(response => {
                return response;
            });
    }

    onChange(row, e) {

        // let prognosis = Utils.numberAsCurrency(Math.round((balance.yearlyBalances[balance.yearlyBalances.length-2]) / day * 365));
        let value = e.target.value;

        let currentRow = this.state.expenses;

        if (e.target.name == 'percent') {
            this.state.percent[row] = value;
            currentRow.balances[row].yearlyBalances[4] = Math.round(currentRow.balances[row].yearlyBalances[3] + currentRow.balances[row].yearlyBalances[3] * value / 100, 0);
            this.setState({expenses: currentRow});
        } else if (e.target.name == 'amount') {
            this.state.percent[row] = value;
            currentRow.balances[row].yearlyBalances[4] = Math.round(Number(currentRow.balances[row].yearlyBalances[3]) + Number(value));
            this.setState({expenses: currentRow});
        } else {
            currentRow.balances[row].yearlyBalances[4] = value;
        }
    }
}

export default BudgetView;