import React from "react";
import Utils from "../utils/Utils";
import Suggestions from "./Suggestions";

class Accounting extends React.Component {
    constructor(props) {
        super(props);

        let account = [];
        let uirows = [];

        // let rows = props.verification.transactions;
        let rows = [];

        let transactionRows = [];

        for (let i=0;i<rows.length;i++) {

            let transaction = rows[i];

            let debet, credit;
            let displayDebet, displayCredit;

            if (rows[i].amount >= 0) {
                displayDebet = rows[i].amount;
                debet = rows[i].amount;
                credit = '';
            } else {
                displayCredit = rows[i].amount;
                credit = rows[i].amount;
                debet = '';
            }

            let transactionRow = {
                id: transaction.id,
                row: transaction.row,
                account: transaction.account,
                name: transaction.name,
                comment: transaction.comment,
                date: transaction.date,
                debet: debet,
                credit: credit,
                displayDebet: displayDebet,
                displayCredit: displayCredit,
                amount: transaction.amount,
                lookupPending: false,
                suggestions: null
            }
            transactionRows.push(transactionRow);
        }

        let verificationState = {
            id: props.verification.id,
            description: props.verification.description,
            series: props.verification.series,
            number: props.verification.number,
            transactionDate: props.verification.transactionDate,
            reference: props.verification.reference,
            transactions: transactionRows
        }

        this.state = {isLoading: false, verification: verificationState, account: account, summary: []};
        this.suggestionsComponent = [];
    }

    componentDidMount() {
        this.recalc();
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
        }
    }

    render() {
        console.log("Rendering...");

        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }
        var rows = [];

        const inputTextStyle = {
            textAlign: 'left',
            border: '1px',
            padding: '0px',
            width: '100%',
            height: '24px',
            margin: '0px'}

        const inputNumberStyle = {
            textAlign: 'right',
            border: '1px',
            padding: '0px',
            width: '100%',
            height: '24px',
            margin: '0px'}

        const autoCompleteStyle = {
            width: '100%',
            height: '100%',
            padding: '0px 0px 0px 0px',
            margin: '0px 0px 0px 0px',
            position: 'relative',
            display: 'inline-block'
        }

        const cellDivStyle = {
            padding: '0px',
            margin: '0px'
        }

        const cellStyle = {
            fontFamily: 'Arial, Helvetica, sans-serif',
            fontSize: '12px',
            textAlign: 'left',
            borderCollapse: 'collapse',
            border: '1px solid #C7C7C7',
            borderSpacing: '0px',
            height: '20px',
            padding: '2px'
        }

        console.log("Accounting suggestions");

        return (
            <div ondrop={(e) => this.drop(e)} ondragover={(e) => e.preventDefault()} draggable style={{margin: '5px', minWidth: '1024', padding: '5px',  height: 'fit-content', background: '#DDDDDD', borderRadius: '10px', boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.5)'}}>
                <table>
                    <tbody>
                    <tr>
                        <td>Serie</td>
                        <td style={{width: '50x'}}>Nummer</td>
                        <td style={{width: '100px'}}>Bokföringsdatum</td>
                        <td style={{width: '300px'}}>Beskrivning</td>
                        <td style={{width: '200px'}}>Fakturareferens</td>
                        <td><button onClick={this.props.onAccountingClose}>X</button></td>
                    </tr>
                    <tr>
                        <td style={cellStyle}><div style={cellDivStyle}><select><option>A</option></select></div></td>
                        <td style={cellStyle}><div style={cellDivStyle}>
                            <input
                            style={inputTextStyle} id="serial" type="text" value={this.state.verification.number}/></div></td>
                        <td style={cellStyle}><div style={cellDivStyle}>
                            <input
                            style={inputTextStyle} id="date" type="text"value={this.state.verification.transactionDate}
                            name='transactionDate'
                            onChange = {(event) => this.updateVerification(event)}
                            /></div></td>
                        <td style={cellStyle}><div style={cellDivStyle}>
                            <input
                            style={inputTextStyle} id="verdescription" type="text"value={this.state.verification.description}
                            name='description'
                            onChange = {(event) => this.updateVerification(event)}
                            /></div></td>
                        <td style={cellStyle}><div style={cellDivStyle}>
                            <input
                            style={inputTextStyle} id="reference" type="text"value={this.state.verification.reference}
                            name='reference'
                            onChange = {(event) => this.updateVerification(event)}
                            /></div></td>
                    </tr>
                    <tr></tr>
                    </tbody></table>
                <table style={{width: '100%', height: '24px'}}>
                    <thead>
                    <tr>
                        <th style={{height: '20px', width: '100px'}}>KONTO</th>
                        <th>BENÄMNING</th>
                        <th>TRANSAKTIONSINFO</th>
                        <th style={{width: '100px'}}>DEBET</th>
                        <th style={{width: '100px'}}>KREDIT</th>
                        <th style={{width: '100px'}}>SALDO</th>
                    </tr>
                    <tr><button onClick={(e) => this.addRow(e)}>+</button></tr>
                    </thead>
                    <tbody ondragover="allowDrop(event)" ondrop={(e) => alert("!")}>
                    {this.state.verification.transactions.map((row, i) => {
                        return (
                        <tr id="accountRow" draggable ondragstart="drag(event)">
                            <td style={cellStyle}>
                                <div style={{cellDivStyle}}>
                                    <input
                                        autocomplete="ihdsgfkjhgiuhfjheuir"
                                        onKeyDown={this._handleKeyDown}
                                        value={this.state.verification.transactions[i].account}
                                        onChange={(e) => this.accountSearch(i, "account", e)}
                                        style={inputTextStyle}
                                        id="account"
                                        type="text"/>
                                <Suggestions
                                    ref={(el) => this.suggestionsComponent[i] = el}
                                    row={i}
                                    component="account"
                                    onUpdate={(account) => this.onUpdate(i, account)}
                                    data={this.state.verification.transactions[i].suggestions}/></div></td>
                            <td style={cellStyle}>
                                <div style={{cellDivStyle}}>
                                    <input
                                        autocomplete="ihdsgfkjhgiuhfjheuir"
                                        value={this.state.verification.transactions[i].name}
                                        onChange={(e) => this.accountSearch(i, "accountName", e)}
                                        style={inputTextStyle}
                                        id="name"
                                        type="text"/>
                                </div>
                            </td>
                            <td style={cellStyle}>
                                <div style={{cellDivStyle}}>
                                    <input autoComplete="off"
                                           onChange = {(event) => this.updateTransaction(i, event)}
                                           style={inputTextStyle}
                                           id="description"
                                           name="comment"
                                           type="text"
                                           value={this.state.verification.transactions[i].comment}/>
                                </div>
                            </td>
                            <td style={cellStyle}>
                                <div style={{cellDivStyle}}>
                                    <input autocomplete="ihdsgfkjhgiuhfjheuir"
                                           value={this.presentationFormat(this.state.verification.transactions[i].displayDebet)}
                                           onChange={(e) => this.updateSummarys(i, "D", e)}
                                           style={inputNumberStyle}
                                           id="debet"
                                           type='text'
                                           />
                                </div>
                            </td>
                            <td style={cellStyle}>
                                <div style={{cellDivStyle}}>
                                    <input autocomplete="off"
                                           value={this.presentationFormat(this.state.verification.transactions[i].displayCredit)}
                                           onChange={(e) => this.updateSummarys(i, "C", e)}
                                           style={inputNumberStyle}
                                           id="credit"
                                           type='text'
                                           />
                                </div>
                            </td>
                            <td style={cellStyle}>
                                <div style={{cellDivStyle}}>
                                    <input autocomplete="ihdsgfkjhgiuhfjheuir" style={inputNumberStyle}  id="balance" type="text"/>
                                </div>
                            </td>
                            <td><button>-</button></td>
                            <td><button></button></td>
                        </tr>
                    )
                    })}

                    <tr>
                    <td><tr></tr></td><td></td><td></td>
                    <td style={cellStyle}>
                        <div style={{cellDivStyle}}>
                            <input readonly
                                   autocomplete="ihdsgfkjhgiuhfjheuir"
                                   value={this.state.summary.debetSum}
                                   style={inputNumberStyle}
                                   id="debet"
                                   type="text"/>
                        </div>
                    </td>
                    <td style={cellStyle}>
                        <div style={{cellDivStyle}}>
                            <input readonly
                                   autocomplete="ihdsgfkjhgiuhfjheuir"
                                   value={this.state.summary.creditSum}
                                   style={inputNumberStyle}
                                   id="credit"
                                   type="text"/>
                        </div>
                    </td>
                    <td style={cellStyle}>
                        <div style={{cellDivStyle}}>
                            <input readOnly
                                   autocomplete="ihdsgfkjhgiuhfjheuir"
                                   value={this.state.summary.balance}
                                   style={inputNumberStyle}
                                   id="balance"
                                   type="text"/>
                        </div>
                    </td>
                    </tr>
                    <tr>
                        <td><button onClick={(e) => this.onSave(e)}>SPARA</button></td>
                        <td><button onClick={this.props.onAccountingClose}>AVBRYT</button></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    updateVerification(event) {

        let verification = this.state.verification;
        verification[event.target.name] = event.target.value ;

        this.setState({verification: verification})
    }

    updateTransaction(i, event) {

        let transactionRow = this.state.verification.transactions[i];
        transactionRow[event.target.name] = event.target.value ;

        let verification = this.state.verification;
        verification.transactions[i] = transactionRow;

        this.setState({verification: verification})
    }

    onSave() {
        const targetUrl = Utils.createUrl('/brfa/api/accounting/verification');

        const requestOptions = {
            method: 'POST',
            headers: Utils.getAuthorizationHeaders().headers,
            // body: JSON.stringify({verification: this.state.verification})
            body: JSON.stringify(this.state.verification)
        }

        fetch(targetUrl, requestOptions)
            .then(response => {
                return response.json();
            }).then(data => {
            if (data) {
                this.setState({transactions: data, isLoading: 0});
            }
        });
    }

    drop(e) {
        alert(e);
    }

    addRow(e) {

        let verification = this.state.verification;
        let transactionRow = {
            id: null,
            row: verification.transactions.length,
            account: null,
            name: null,
            comment: null,
            debet: '',
            credit: '',
            lookupPending: false,
            suggestions: null
        }

        verification.transactions.push(transactionRow);
        this.setState({verification: verification});
    }

    onUpdate = (i, account) =>  {
        let acc = this.state.account;
        acc[i] = account;

        let verification = this.state.verification;
        verification.transactions[i].account = account.account;
        verification.transactions[i].name = account.name;
        verification.transactions[i].suggestions = null;
        verification.transactions[i].lookupPending = false;

        this.props.onAccountingClose();
        this.setState({verification: verification});
    }

    accountSearch(row, id, event) {

        let verification = this.state.verification;

        let searchString = event.target.value;

        if (verification.transactions[row].lookupPending == false) {
            verification.transactions[row].account = searchString.substr(searchString.length - 1);
            verification.transactions[row].name = '';
            verification.transactions[row].lookupPending = true;
            this.setState({verification: verification})
            return;
        }

        verification.transactions[row].account = searchString;

        this.state.account[row] = {};
        if (searchString.length >= 3) {
            const targetUrl = Utils.createUrl('/brfa/api/lookup/account/' + searchString);
            fetch(targetUrl, Utils.getAuthorizationHeaders())
                .then(response => {
                    return response.json();
                }).then(data => {
                if (data) {
                    verification.transactions[row].suggestions = data;
                    this.setState({verification: verification})
                    return;
                }
            });
        }

        this.setState({verification: verification})
    }

    updateSummarys(row, kind, event) {

        var input = event.target.value.replace(',','.')

        var value = parseFloat(input);

        console.log(input + " " + event.target.value + " => " + value);

        let verification = this.state.verification;

        let calc = {};
        let display = {};

        if (kind == 'D') {
            display.debet = event.target.value;
            calc.debet = value;
            calc.credit = 0
            calc.amount = calc.debet;
            display.credit = '';
        } else {
            display.credit = event.target.value.substr(0,1) == '-' ? event.target.value : '-' + event.target.value;
            calc.credit = -Math.abs(value);
            calc.debet = 0;
            calc.amount = calc.credit;
            display.debet = '';
        }

        console.log("VALUE: " + JSON.stringify(value));
        console.log(JSON.stringify(calc));

        verification.transactions[row].debet = calc.debet;
        verification.transactions[row].credit = calc.credit;
        verification.transactions[row].amount = calc.amount;
        verification.transactions[row].displayDebet = display.debet;
        verification.transactions[row].displayCredit = display.credit;

        this.recalc();
        this.setState({verification: this.state.verification})
    }

    recalc() {

        let verification = this.state.verification;

        let debetSum = 0.0;
        let creditSum = 0.0;

        for (let i=0;i<verification.transactions.length;i++) {
            debetSum += parseFloat(verification.transactions[i].debet == '' ? 0 : verification.transactions[i].debet);
            creditSum += parseFloat(verification.transactions[i].credit == '' ? 0 : verification.transactions[i].credit);
        }

        let summary = {debetSum: this.fixDecimals(debetSum), creditSum: this.fixDecimals(creditSum), balance: this.fixDecimals(debetSum + creditSum)};
        this.setState({summary: summary})
    }

    fixDecimals(value) {
        return value == null ? 0 : value.toFixed(2).replace('.',',');
    }

    presentationFormat(value) {
        console.log("PRES: " + value)
        return value === null ? 0 : value;
    }
}

export default Accounting;