import React from "react";

class LabeledButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {fields: props.fields, isLoading: false};
    }

    componentDidMount() {
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        return (
            <div style={{paddingLeft: '5px', fontSize: '16px', float: 'left'}}>
                <div style={{padding: '5px', float: 'left'}}>&nbsp;</div><br/>
                <button onClick={() => this.props.onClick()} style={{marginTop: '5px', width: (this.props.width == null ? 100 : this.props.width), height: '100%', fontSize: '16px'}}>{this.props.label}</button>
            </div>
        )
    }
}

export default LabeledButton;