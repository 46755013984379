import React from 'react';
import '../App.css';
import * as Main from "../styles/Main";
import Utils from "../utils/Utils";
import MaintenanceStatistics from "../components/keyvalues/MaintenanceStatistics";
import {gridShadowBox, shadowBox} from "../styles/Main";

class KeyValuesView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isLoading: true}
    }

    componentDidMount() {
        const header = Utils.getAuthorizationHeaders();

        let targetUrl = Utils.createUrl('/brfa/api/accounting/keyFigures');
        Utils.fetch(targetUrl, header, (data) => {
            this.setState({keyFigures: data, isLoading: false});
        }, (error) => {
            if (error == 401) {
            }
        });
    }

    render() {
        if (this.state.isLoading) {
            return "";
        }

        let savings = this.state.keyFigures.budgetApartmentFees + this.state.keyFigures.budgetPremisesRents - this.state.keyFigures.budgetCost;

        return (
            <div style={{ height: 'fit-content', maxWidth: '100%', padding: '0px', margin: '6px 6px 6px 6px', display: 'grid', gridTemplateColumns: 'auto auto auto auto autowrap'}}>
                <div style={Main.headingStyle}>Nyckeltal {this.state.year}</div>
                <div style={gridShadowBox}>
                    <div style={{lineHeight: '1.5', maxWidth: '512px', fontSize: '10pt', paddingTop: '12px', paddingBottom: '12px', padding: '4pt'}}>
                        Ändringar i årsredovisningslagen för bostadsrättsföreningar:
                        Från och med 2023 införs krav på kassaflödesanalys i årsredovisningen för bostadsrättsföreningar.
                        Det införs krav på att förvaltningsberättelsen ska innehålla upplysningar om vissa nyckeltal i syfte att göra bostadsrättsföreningarnas årsredovisningar mer lättillgängliga för medlemmar och köpare, däribland nämns:
                        årsavgift per kvadratmeter upplåten med bostadsrätt,
                        skuldsättning per kvadratmeter,
                        sparande per kvadratmeter,
                        räntekänslighet, och
                        energikostnad per kvadratmeter.
                        Går bostadsrättsföreningen med förlust måste den lämna upplysning om vad förlusten innebär för föreningens möjlighet att finansiera framtida ekonomiska åtaganden.
                        <br/><br/>
                        Läs bostadsrättsföreningens årsredovisning. Det finns mycket värdefull information
                        om till exempel föreningens ekonomi och behov av framtida renoveringar.<br/><br/>
                        • Kontakta någon i styrelsen. De som sitter i styrelsen sitter på information som inte
                        framgår av årsredovisningen. Fråga om framtida renoveringsbehov, eventuella
                        juridiska tvister med leverantörer eller medlemmar och be om att få ta del av
                        föreningens underhållsplan där man kan läsa om det behöver göras några stora och
                        dyra renoveringar de närmaste åren.<br/><br/>
                        • Undersök lägenheten noga. Som bostadsrättshavare ansvarar du för ytskiktet i
                        lägenheten. Var därför noga med att besikta lägenheten.<br/><br/>
                        • Ta hänsyn till föreningens skuldsättning. De flesta bostadsrättsföreningar har lån.
                        Nyproducerade bostadsrättsföreningar har ofta lån på 10 000 till 15 000 kronor per
                        kvm boyta (lån i relation till den samlade boytan för samtliga lägenheter). När du lägger
                        bud på en lägenhet bör du inkludera lägenhetens andel av föreningens lån. Begär
                        säljaren 40 000 kronor per kvm och föreningen har 12 000 kronor i lån per kvm är det
                        egentliga priset 52 000 kronor per kvm.<br/><br/>
                        • Beräkna räntekänsligheten. Med hög skuldsättning följer en hög räntekänslighet. Är
                        dessutom skuldsättningen hög i bostadsrättsföreningen ökar räntekänsligheten
                        ytterligare. Beräkna hur mycket månadsavgiften och hela boendekostnaden påverkas
                        vid en procentenhet högre bolåneränta.<br/><br/>

                        Ta hänsyn till föreningens sparande. En bostadsrättsförening behöver spara pengar för<br/>
                        framtida renoveringar. En bostadsrättsförening bör ha ett sparande som uppgår till<br/>
                        minst <span style={{background: '#76ff64'}}>250 till 350 kronor per kvm boyta</span>. Sparandet kan ske i form av till exempel<br/>
                        amorteringar på föreningens lån och redovisat överskott (årets vinst).<br/>
                    </div>
                </div>
                <div style={gridShadowBox}>
                    <table>
                        <thead>
                            <tr>
                                <td></td>
                                <td>Totalt</td>
                                <td>per m<sup>2</sup></td>
                                <td>Rekommenderat</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><td>Total bostadsyta</td><td>{this.state.keyFigures.apartmentFloorSpace.toFixed()}m<sup>2</sup></td></tr>
                            <tr><td>Medlemsavgifter</td><td>{Utils.numberAsCurrency(this.state.keyFigures.apartmentFees)}</td></tr>
                            <tr><td>Hyresintäkter</td><td>{Utils.numberAsCurrency(this.state.keyFigures.budgetPremisesRents)}</td></tr>
                            <tr><td>Kostnader</td><td>{Utils.numberAsCurrency(this.state.keyFigures.budgetCost)}</td></tr>
                            <tr><td>Årsavgifter</td><td>{Utils.numberAsCurrency(this.state.keyFigures.apartmentFees)}</td><td>{Utils.numberAsCurrency(this.state.keyFigures.annualFeePerSquareMeter)} / m<sup>2</sup></td></tr>
                            <tr><td>Sparande</td><td>{Utils.numberAsCurrency(savings)}</td><td>{Utils.numberAsCurrency(this.state.keyFigures.annualSavingsPerSquareMeter)} / m<sup>2</sup></td><td>250-350 / m<sup>2</sup></td></tr>
                            <tr><td>Skuldsättning</td><td>{Utils.numberAsCurrency(this.state.keyFigures.budgetDebt)}</td><td>{Utils.numberAsCurrency(this.state.keyFigures.debtPerSquareMeter)} / m<sup>2</sup></td><td> &lt; 5000 / m<sup>2</sup></td></tr>
                            <tr><td>Energikostnad</td><td>{Utils.numberAsCurrency(this.state.keyFigures.energyCost)}</td><td>{Utils.numberAsCurrency(this.state.keyFigures.energyCostPerSquareMeter)} / m<sup>2</sup></td></tr>
                            <tr><td>Räntekänslighet</td><td></td><td>{this.state.keyFigures.interestRateSensitivity.toFixed(2)}</td><td> &lt; 5</td><td>För varje % räntan går upp måste avgifterna höjas med denna faktor</td></tr>
                        </tbody>
                    </table>
                </div>
                    <MaintenanceStatistics/>
                </div>
        );
    }
}

export default KeyValuesView;
