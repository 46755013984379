import React from "react";
import Utils from "../../utils/Utils";
import {ArcElement, CategoryScale, Chart, LinearScale, LineElement, PointElement} from 'chart.js'
import * as Main from "../../styles/Main";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

Chart.register(ArcElement);
Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(PointElement);
Chart.register(LineElement);


class YearlyComparationChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isLoading: true, income: {}}
    }

    componentDidMount() {
        const header = Utils.getAuthorizationHeaders();
        let targetUrl = Utils.createUrl('/brfa/api/accounting/multiYearView/2021,2022,2023, 2024/' + this.props.accountClass + "?lowerLimit=5000");
        Utils.fetch(targetUrl, header, (data) => {
            this.setState({data: data, isLoading: false});
        }, (error) => {
            if (error == 401) {
            }
        });
    }

    render() {
        if (this.state.isLoading) {
            return (<div>Loading...</div>)
        }
        let all = [];
        let categories = [];

        let accounts = [];
        let series = [];

        for (let i = 0;i < this.state.data.accountSummaries.length;i++) {
            let fullAccountName = this.state.data.accountSummaries[i].account + ' ' + this.state.data.accountSummaries[i].accountName;
            let accountSummaries = this.state.data.accountSummaries[i].accountSummary;
            let data = [];
            let accountSummary = [];
            // accountSummary.push(this.state.data.totals)
            for (let j = 0; j <accountSummaries.length;j++) {
               accountSummary.push(accountSummaries[j].totalAmount);



/*
                for (let k=0;k<summaries.length;k++) {
                    accountSummary.push({totalAmount: summaries.accountSummary[k].totalAmount})
                }
                data.push({name: summaries.accountName})
  */

                // data.push({accountName: })
            }
            series.push({name: fullAccountName, data: accountSummary})
        }
/*
        series = [
            {
                name: 'Alpha',
                data: [
                    ['alpha', 1],
                    ['bravo', 2],
                    ['charlie', 3],
                    ['charlie', 3],
                    ['charlie', 3],
                ]
            },
            {
                name: 'Bravo',
                data: [
                    ['alpha', 4],
                    ['bravo', 7],
                    ['charlie', 10],
                    ['charlie', null],
                    ['charlie', 3],
                ]
            },
            {
                name: 'Charlie',
                data: [
                    ['alpha', 8],
                    ['bravo', 5],
                    ['charlie', 6],
                ]
            },
            {
                name: 'Delta',
                data: [
                    ['alpha', 8],
                    ['bravo', 5],
                    ['charlie', 6],
                ]
            },
            {
                name: 'Echo',
                data: [
                    ['alpha', 8],
                    ['bravo', 5],
                    ['charlie', 6],
                ]
            }
        ];
*/
        let options = {
            title: {
                text: this.props.title
            },
            credits: { enabled: false },
            // type: 'spline',
            chart: {
                type: 'bar',
            },
            xAxis: {
                categories: this.state.data.years,
                accessibility: {
                    description: 'Months of the year'
                },
            },
            yAxis: {
                title: {
                    text: 'SEK'
                },

                labels: {
                    format: '{value: point.y:,.0f}'
                },
            },
            zooming: {
                mouseWheel: true
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return this.series.name.substring(5)
                        }
                    },
                }
            },
            series: series
        }

        let total;

        return (
            <div style={{minWidth: '100%', height: '100%', maxWidth: '800px', width: '45%'}}>


                <div style={{margin: '6px 6px 6px 6px', boxShadow: '0px 1px 12px rgba(0.5, 0.5, 0.5, 0.5)'}}>
                    <HighchartsReact
                        // containerProps={{ style: { height: 'calc(100vh - 170px)' } }}
                        highcharts={Highcharts}
                        options={options}>
                    </HighchartsReact>
                </div>
            </div>
        )
    }
}

export default YearlyComparationChart;
