import React from "react";
import Utils from "../../../utils/Utils";
import TaskDialog from "./TaskDialog";

class Lane extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true, selectedItem: {}, items: []}
        console.log(this.props.onDragStart);
        console.log(this.props.onDragOver);
        console.log(this.props.onDrop);
    }

    componentDidMount() {
        this.setState({isLoading: false, edit: false, items: this.props.items})
    }

    componentDidUpdate(prevProps) {
        if (this.state.items != this.props.items) {
            this.setState({items: this.props.items})
        }
    }

    render() {

        let dialog = [];

        const userInfo = Utils.getUserInfo();

        if (this.state.isLoading) {
            return (<div>Loading...</div>);
        }

            let total = 0;
            if (this.state.items != null) {
                for (let i=0;i<this.state.items.length;i++) {
                    total += this.state.items[i].total;
                }
            }

            if (this.state.items == null) {
                return (                <div onDragEnter={(e) => this.props.onDragEnter(e)}
                                             onDragLeave={(e) => this.props.onDragLeave(e)}
                                             onDragOver={(e) => this.props.onDragOver(e)}
                                             onDrop={(e) => this.props.onDrop(e)}
                                             dropZone = {this.props.dropZone}
                                             style={{
                                                 // textAlign: ' center',
                                                 margin: '2px',
                                                 padding: '10px',
                                                 width: 'calc(100vw - 48px)',
                                                 minHeight: '100%',
                                                 overflow: 'hidden',
                                                 color: '#000000',
                                                 background: '#ffffff',
                                                 fontSize: '16pt',
                                                 boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.5)'
                                             }}>                    <b>{this.props.title}</b><br/>{Utils.numberAsCurrency(total)}
                </div>)
            }

        let headingStyle = {
            padding: '10px',
            height: '24pt',
            fontWeight: 'bold',
            fontSize: '14pt',
            color: '#000000'};


        return (
                <div onDragEnter={(e) => this.props.onDragEnter(e)}
                     onDragLeave={(e) => this.props.onDragLeave(e)}
                     onDragOver={(e) => this.props.onDragOver(e)}
                    onDrop={(e) => this.props.onDrop(e)}
                    dropZone = {this.props.dropZone}
                    style={{
                    // textAlign: ' center',
                    margin: '4px',
                    padding: '12px',
                    width: '92%',
                    height: '100%',
                    overflow: 'none',
                    color: '#000000',
                    background: '#ffffff',
                    fontSize: '16pt',
                    boxShadow: '0px 4px 12px rgba(0.5, 0.5, 0.5, 0.5)'
                }}>
                    <div style={headingStyle}><b>{this.props.title}</b><br/><span style={{fontSize: '12pt'}}>{Utils.numberAsCurrency(total)}</span></div>

                    {this.state.items.map((item, index) => {
                        let edgeLeftColor = '6px solid #55FF55'
                        if (item.taskItem == null) {
                            edgeLeftColor = '4px solid #0000FF'
                        } else {
                            console.log(Date.now() + " " + Date.parse(item.taskItem.due));
                            if (Date.parse(item.taskItem.due) >= Date.now()) {
                                console.log("X")
                                if  (Date.parse(item.taskItem.due) - 14 * 1000 * 3600 * 24 >= (Date.now())) {
                                    edgeLeftColor = '4px solid #55FF55' // Green
                                } else {
                                    edgeLeftColor = '4px solid #ffff00' // Yellow
                                    }
                            } else {
                                edgeLeftColor = '4px solid #FF5555' // Red
                            }
                        }

                        let qrCode = "";
                        if (item.taskItem != null && item.id == this.props.onGetSelectedItem().id) {
                            if (this.state.edit == true) {
                                dialog.push(<div><TaskDialog
                                    onCancel={() => this.onCancel()}
                                    onSave={(e, a, b) => this.onSave(e, a, b)}
                                    onChange={(e) => this.onChange(e)}
                                    task={this.state.selectedItem}
                                    index={index}/>
                                </div>);
                            }
                        }

                        let taskId = item.taskItem == null ? null : item.taskItem.id;

                        return (
                            <div onClick={(e) => this.onItemSelect(e)}
                                onDragEnter={(e) => this.props.onDragEnter(e)}
                                onDragLeave={(e) => this.props.onDragLeave(e)}
                                onDragStart={(e) => this.props.onDragStart(e)}
                                draggable
                                name='task'
                                data={JSON.stringify(item)}
                                id = {item.id}
                                taskId = {taskId}
                                index = {index}
                                dragzone = {this.props.dropZone}
                                dropzone = {this.props.dropZone}
                                style={{
                                marginTop: '20px',
                                padding: '5px',
                                overflow: 'auto',
                                fontSize: '10pt',
                                color: '#000000',
                                background: '#fcffdb',
                                boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.9)',
                                borderLeft: edgeLeftColor
                            }}>
                                <div><b>{item.taskItem == null ? '' : item.taskItem.id}</b></div>
                                <div><b>{item.year}</b></div>
                                <div>{item.position}</div>
                                <div style={{fontWeight: 'bold', fontSize: '12pt'}}>{item.taskItem != null ? item.taskItem.heading : item.description}</div>
                                <div>Antal: {item.quantity} {item.unit}</div>
                                <div>Styckpris: {Utils.numberAsCurrency(item.itemPrice == null ? 0 : item.itemPrice)}</div>
                                <div>Totalpris: {Utils.numberAsCurrency(item.total == null ? 0 : item.total)}</div>
                                <div style={{color: 'darkgreen' }}>Konto: {item.taskItem == null ? 0 : item.taskItem.account} {item.taskItem == null ? '' : item.taskItem.accountDescription == null ? "" : item.taskItem.accountDescription}</div>

                                {item.taskItem != null &&
                                    <>
                                    <div>{item.taskItem.description}</div>
                                    </>
                                }

                                {item.taskItem != null && item.taskItem.person != null &&
                                <div style={{fontSize: '10pt'}}>
                                    <br/>
                                    <b>Ansvarig</b>
                                    <div>{item.taskItem.person.firstName} {item.taskItem.person.lastName}</div>
                                    <div>{item.taskItem.person.email}</div>
                                    <br/>
                                </div>
                                }
                                {item.taskItem != null && item.taskItem.due != null &&
                                <div style={{fontSize: '10pt'}}>
                                    <b>Planerat:</b><br/>
                                    Startar: {item.taskItem.startDate}<br/>
                                    Slutfört: {item.taskItem.due}
                                </div>
                                }
                                <div style={{margin: '0px', padding: '20px'}}>
                                    <div>{qrCode}</div>
                                </div>
                            </div>
                        )
                    })}
                    {dialog}
                </div>
            )

    }

    onItemSelect(e) {
        console.log("Clicked");
        e.preventDefault();
        if (this.state.edit === true) {
            this.setState({edit: false});
            return;
        }

        if (e.detail == 2) {
            this.setState({edit: true})
            return;
        }

        let target = e.target;
        let data;
        while(target.getAttribute('name') != 'task') {
            target = target.parentElement;
            data = JSON.parse(target.getAttribute('data'));
        }

        this.setState({selectedItem: data, edit: false})
        this.props.onClick(e);
    }

    onCancel() {
        this.setState({edit: false});
    }


    onSave(task, index) {
        const targetUrl = Utils.createUrl('/brfa/api/maintenance/task/update');
        const headers = Utils.getAuthorizationHeaders();

        this.state.isLoading = true;

        const requestParams = {
            task: task,
        };

        const requestOptions = {
            method: 'POST',
            headers: Utils.getAuthorizationHeaders().headers,
            body: JSON.stringify(task)
        }

        fetch(targetUrl, requestOptions)
            .then(response => {
                return response.json();
            }).then(data => {

                let selectedItem = this.state.items[index];
                selectedItem.taskItem = data;

                this.setState({selectedItem: selectedItem, edit: false, isLoading: false});
        }).finally(data => {
            this.setState({edit: false, isLoading: false});
        });
    }
}

export default Lane;