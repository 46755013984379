import React from "react";
import Utils from "../utils/Utils";

class QRLookupView extends React.Component {
    constructor(props) {
        super(props);

        const queryParams = new URLSearchParams(window.location.search);
        let uuid = this.props.match.params.uuid != null ? this.props.match.params.uuid : null;

        this.state = {uuid: uuid, isLoading: true};
    }

    componentDidMount() {
        const targetUrl = Utils.createUrl('/brfa/api/admin/lookup/token/' + this.state.uuid);
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({info: data, isLoading: false});
        });
    }

    render() {
        return ("HEJ")
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        let apartmentId = Utils.getUserInfo().apartmentId;

        return (
            <div>
                <input type="file" accept="image/*" capture="camera" />
                <table>
                    <tr><td>{this.state.info.apartmentId}</td></tr>
                    <tr><td>{this.state.info.building}</td></tr>
                    <tr><td>{this.state.info.floor} tr</td></tr>
                    <tr><td>{this.state.info.person1}</td></tr>
                    <tr><td>{this.state.info.person2}</td></tr>
                </table>
            </div>
        )
    }
}

export default QRLookupView;