import React from "react";
import LoginView from "../views/LoginView";
import TopMenu from "./Menu/TopMenu";

class TopBar extends React.Component {
    constructor(props) {
        super(props);

        let userInfo = null;

        this.state = { loginInfo: this.props.loginInfo, isLoading: false }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userInfo !== this.props.userInfo) {
            if (this.props.userInfo == null) {
                return true;
            }

            this.setState({userInfo: this.props.userInfo})
        }
    }

    render() {

        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        if (window.innerWidth < 500 || window.innerHeight < 500) {
            return this.props.children;
        }

        let userInfo = null;
        let userInfoJson = localStorage.getItem("loginInfo");


        if (userInfoJson == null || userInfoJson == 'undefined') {
            return (<>
                <div style={{width: '100%', height: '50px', background: '#222222'}}>
                </div>
                <LoginView onLogin={this.props.onLogin}/>
            </>)
        } else {
            userInfo = JSON.parse(userInfoJson).user;
        }

/*
        menus.push(<MenuItem label='Översikt' link='/dashboard'></MenuItem>);
        menus.push(<MenuItem label='Nyckeltal' link='/keyvalues'></MenuItem>);
        menus.push(<MenuItem label='Självbetjäning' link='/selfservice'></MenuItem>);
        menus.push(<MenuItem label='Kontakter' link='/contacts'></MenuItem>);
        menus.push(<MenuItem label='Meddelanden' link='/messages'></MenuItem>);
        menus.push(<MenuItem label='Underhåll' link='/tasks'></MenuItem>);
        if (this.userGroupId >= 500) { menus.push(<MenuItem label='Ekonomi' link='/accounts'></MenuItem>) }
        if (this.userGroupId >= 500) { menus.push(<MenuItem label='Grafik' link='/charts'></MenuItem>); }
        menus.push(<MenuItem label='Avgifter' link='/fee'></MenuItem>);
        menus.push(<MenuItem label='Timeline' link='/timeline'></MenuItem>);
        if (this.userGroupId >= 1000) { menus.push(<MenuItem label='Kalender' link='/calendar'></MenuItem>) }
        if (this.userGroupId >= 1000) { menus.push(<MenuItem label='Budget' link='/budget'></MenuItem>) }
        if (this.userGroupId >= 1000) { menus.push(<MenuItem label='Filer' link='/files'></MenuItem>) }
        if (this.userGroupId >= 1000) { menus.push(<MenuItem label='Admin' link='/admin'></MenuItem>) }
        if (this.userGroupId >= 1000) { menus.push(<MenuItem label='Panter' link='/mortgage'></MenuItem>) }
*/

        let overviewMenu = [
            {label: 'Översikt', icon: 'icons8-room-24.png', link: '/dashboard'}];

        let contactsMenu = [
            {label: 'Kontakter', icon: '8666755_users_group_icon.png', link: '/contacts'}];

        let messagesMenu = [
            {label: 'Meddelanden', icon: 'icons8-envelope-24.png', link: '/messages'}];

        let maintenanceMenu = [
            {label: 'Underhållsplan', icon: 'icons8-support-24.png', link: '/tasks'},
            {label: 'Timeline', icon: 'icons8-calendar-24.png', link: '/timeline'}];

        let economyMenu = [
            {label: 'Översikt', icon: 'icons8-cashbook-24.png', link: '/accounts'},
            {label: 'Avgifter', icon: 'icons8-cashbook-24.png', link: '/fee'},
            {label: 'Grafik', icon: 'icons8-decline-24.png', link: '/charts'},
            {label: 'Flerårsöversikt', icon: 'icons8-key-24.png', link: '/multiyears'},
            {label: 'Nyckeltal', icon: 'icons8-key-24.png', link: '/keyvalues'}];

        let mortgageMenu = [
            {label: 'Panter', icon: 'icons8-bonds-24.png', link: '/mortgage'}];

        let adminMenu = [
            {label: 'Mötesbokning', icon: 'icons8-services-24.png', link: '/booking'},
            {label: 'Avier', icon: 'icons8-bill-24.png', link: '/admin'},
            {label: 'Filhantering', icon: 'icons8-folder-24.png', link: '/files'},
            {label: 'Dokument', icon: 'icons8-file-24.png', link: '/admin'},
            {label: 'Inställningar', icon: 'icons8-services-24.png', link: '/files'}];

        let testMenu = [
            {label: 'Move / resize', link: '/test'}];

        let menus = [
            {label: 'Översikt', menu: overviewMenu},
            {label: 'Kontakter', menu: contactsMenu},
            {label: 'Meddelanden', menu: messagesMenu},
            {label: 'Ekonomi', menu: economyMenu},
            {label: 'Underhåll', menu: maintenanceMenu},
            {label: 'Panter', menu: mortgageMenu},
            {label: 'Admin', menu: adminMenu},
            {label: 'Test', menu: testMenu}];

        if (userInfo == null) {
            return <>{this.props.children}</>
        }

        return (
            <>
            <div class="noprint" style={{zIndex: '100', width: '100%', height: '70px', background: '#35363a', position: 'fixed'}}>
                <div style={{gridTemplateColumns: 'auto auto auto', display: 'grid'}}>
                    <div style={{padding: '6px 6px 0px 6px', color: '#FFFFFF'}}>
                        <div>Inloggad som: { userInfo != null ? userInfo.firstName : '' } { userInfo != null ? userInfo.lastName : ''}</div>
                        <div style={{fontSize: '10pt'}}>{userInfo != null ? userInfo.userGroupName : ''}</div>
                    </div>
                    <div style = {{background: ''}}></div>
                    <div style={{padding: '6px 6px 0px 6px', color: '#FFFFFF', width: 'calc(100% - 12pt)', display: 'grid', textAlign: 'right'}}>
                        <div>{userInfo == null ? '' : userInfo.organization.name}</div>
                        <div style={{fontSize: '10pt'}}>{userInfo == null ? '' : userInfo.organization.orgNumber}</div>
                    </div>
                    <div style={{color: '#FFFFFF', fontSize: '18pt', textAlign: 'center'}}>{this.props.heading}</div>
                </div>
                <TopMenu menus={menus}/>
            </div>
            {this.props.children}
            </>
            )
        }
}

export default TopBar;