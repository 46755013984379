import React from 'react';
import {setDefaultBreakpoints} from 'react-socks';
import '../../App.css';
import Utils from "../../utils/Utils";
import {useHistory} from "react-router-dom";


class AccountTable extends React.Component {
    constructor(props) {
        super(props);

        console.log(JSON.stringify(props.years))

        this.state = {data: {}, isLoading: false, selectedAccount: {}, selectedCol : 0};

        setDefaultBreakpoints([
            { xs: 0 },
            { s: 376 },
            { m: 426 },
            { l: 769 },
            { xl: 1025 }
        ]);
    }

    render() {
        if (this.state.isLoading) {
            return "";
        }
        return (
            <div style={{float: "left", width: 'calc(100% - 6px)', cursor: 'pointer', boxShadow: '0px 1px 12px rgba(0.5, 0.5, 0.5, 0.5)'}}>
                <div>
                    <table class="dotted standardFont" style={{width: "100%"}}>
                    <thead>
                    <tr style={{background: "#FFDDAA"}}>
                        <td>Konto</td>
                        <td>Benämning</td>
                        {this.props.years.map((row, i) => {
                            return (<td style={{minWidth:'96px', textAlign:'right'}}>{row}</td>)
                        })}
                    </tr>
                    </thead>
                        <tbody>
                        {this.props.balances.map((balance, col) => {
                            let fontWeight = 'normal';
                            if (balance.account == 0) {
                                fontWeight = 'bold';
                            }

                            return (
                            <tr>
                            <td>{balance.account == 0 ? '' : balance.account}</td>
                            <td style={{fontWeight: fontWeight, width: '300px'}}>{balance.name}</td>
                                {balance.yearlyBalances.map((yearlyBalance, col) => {
                                    let border = '';
                                    if (this.state.selectedAccount != null && balance != null && this.state.selectedAccount == balance.account && this.state.selectedCol == col) {
                                        border = "1px solid #FF0000";
                                    }
                                return (
                                    <td onClick={(e) => this.onClick(balance, col)}
                                        key = {balance.account + col}
                                        style={{border: border, fontWeight: fontWeight, textAlign: 'right', color: (parseFloat(yearlyBalance)<0) ? 'red' : 'black'}}>
                                        {yearlyBalance == null ? '' : Utils.numberAsCurrency(parseFloat(yearlyBalance))}
                                    </td>
                                )
                            })}
                        </tr>)
                        })}

                    </tbody>
                    </table>
                    </div>
{/*                </Breakpoint>*/}
            </div>
        );
    }

    onClick(balance, col) {
        if (this.state.selectedAccount == balance.account && this.state.selectedCol == col) {
            this.props.onClick(null, null);
            this.setState({selectedAccount: null, selectedCol: null})
            return;
        }
        this.props.onClick(balance, col);
        this.setState({selectedAccount: balance.account, selectedCol: col})
    }
}

export default AccountTable;
