import React from "react";
import {gridShadowBox, panelStyle, shadowBox, subHeadingStyle} from "../../styles/Main";
import Utils from "../../utils/Utils";

class Payments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {apartmentId: props.apartmentId, fee: {}, isLoading: true};
    }

    componentDidMount() {
        const targetUrl = Utils.createUrl('/brfa/api/fee/2021/' + this.state.apartmentId);
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
                this.setState({fee: data, isLoading: false});
        });
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        let transOut = [];
        let headers = [];
        let trans = this.state.fee.payments;

        headers.push(
                <tr key={0}>
                    <th>Betalt</th>
                    <th>Belopp</th>
                    <th>Text</th>
                    <th>Referens</th>
                </tr>
        )

        for (let i=0;i<trans.length;i++) {
            transOut.push(
                <tr key={trans[i].id}>
                    <td>{trans[i].date}</td>
                    <td>{trans[i].amount}</td>
                    <td>{trans[i].comment}</td>
                    <td>{trans[i].reference}</td>
                </tr>)
        }

        let feeWithCalculations = this.state.fee.feeWithCalculation;

        console.log(JSON.stringify(feeWithCalculations));

        return (
            <>
            <div style={gridShadowBox}>
                <div style={subHeadingStyle}>Avgiftsberäkning</div>
                <div style={{gridColumn: 'span 2', gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))', fontSize: '12pt', lineHeight: '1.5'}}>
                Beräkningsfaktorerna höjer eller sänker ditt ytbaserade andelstal<br/>
                Avgiften beräknas med det viktade andelstalet som bas.<br/>
                Värme och varmvatten är utbrutet eftersom några vindsvåningar<br/>
                inte är inkopplade på värmesystemet, de betalar däremot varmvatten.<br/>
                Kostnaden för varmvattet baseras på fjärrvärmekostnaden under sommarmånaderna (jul-aug)<br/>
                då värmesystemet normalt sett är avstängt.<br/><br/>
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap', fontSize: '12pt', lineHeight: '1.5'}}>
                <div style={{minWidth: '40%'}}>
                    <b>Viktningsfaktorer:</b><br/><br/>
                    <table style={panelStyle}>
                        <tbody>
                        <tr><td><b>Hus</b></td></tr>
                        <tr><td>Gårdshus:</td><td>1.00</td></tr>
                            <tr><td>Gathus:</td><td>1.03</td><td></td></tr>
                            <tr><td><b>Våning</b></td></tr>
                            <tr><td>Nedre botten:</td><td> 1.00</td></tr>
                            <tr><td>Våning 1:</td><td> 1.03</td></tr>
                            <tr><td>våning 2-3:</td><td> 1.06</td></tr>
                            <tr><td>>=våning 4:</td><td> 1.03</td></tr>
                            <tr><td><b>Antal rum</b></td></tr>
                            <tr><td>1 rum:</td><td> 1.06</td></tr>
                            <tr><td>2 rum:</td><td> 1.03</td></tr>
                            <tr><td>>=3 rum:</td><td> 1.00</td></tr>
                        </tbody>
                    </table><br/>
                </div>
                <div style={{width: '40%'}}>
                <b>Beräkningsgrund för er lägenhet:</b><br/><br/>
                <table style={panelStyle}>
                    <tbody>
                        <tr><td>Yta:</td><td>{feeWithCalculations.floorspace} m2</td></tr>
                        <tr><td>Ytbaserat andelstal:</td><td>{feeWithCalculations.spaceBasedPercentageRate}</td></tr>
                        <tr><td>Byggnadsfaktor:</td><td>{feeWithCalculations.buildingFactor}</td></tr>
                        <tr><td>Våningsfaktor:</td><td>{feeWithCalculations.levelFactor}</td></tr>
                        <tr><td>Viktat andelstal:</td><td>{feeWithCalculations.adjustedPercentageRate}</td></tr>
                        <tr><td>Rumsfaktor:</td><td>{feeWithCalculations.roomFactor}</td></tr>
                        <tr><td>Grundavgift:</td><td>{Utils.numberAsCurrency(feeWithCalculations.baseamount)} / år</td></tr>
                        <tr><td>Värme:</td><td>{Utils.numberAsCurrency(feeWithCalculations.heating)} / år</td></tr>
                        <tr><td>Varmvatten:</td><td>{Utils.numberAsCurrency(feeWithCalculations.hotwater)} / år</td></tr>
                        <tr><td>Bredband:</td><td>{Utils.numberAsCurrency(feeWithCalculations.broadband)} / år</td></tr>
                        <tr><td>Årsavgift:</td><td>{Utils.numberAsCurrency(feeWithCalculations.yearlyFee)}</td></tr>
                        <tr><td><b>Per månad:</b></td><td><b>{Utils.numberAsCurrency(feeWithCalculations.monthlyFee)}</b></td></tr>
                    </tbody>
                </table>
                </div>
            </div>
            </div>
            <div style={gridShadowBox}>
                <div style={subHeadingStyle}>Inbetalningar</div>
                <div style={{fontSize: '12pt', lineHeight: '1.5'}}>
                Senaste avgiftsförändring: {this.state.fee.lastChange}<br/>
                Årsavgift: {Utils.numberAsCurrency(this.state.fee.yearly)}<br/>
                Månadsavgift: {Utils.numberAsCurrency(this.state.fee.monthly)}<br/><br/>
                </div>
                <table style={panelStyle}>
                    <thead>
                        {headers}
                    </thead>
                    <tbody>
                        {transOut}
                    </tbody>
                </table>
            </div>
            </>
        )
    }
}

export default Payments;