import React from "react";
import Utils from "../../utils/Utils";
import {Chart, ArcElement, CategoryScale, LinearScale, PointElement, LineElement} from 'chart.js'
import * as Main from "../../styles/Main";
import PieChart from "./PieChart";

Chart.register(ArcElement);
Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(PointElement);
Chart.register(LineElement);


class AccountChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isLoading: true, year: this.props.year, income: {}}
    }

    componentDidMount() {
        this.updateValues(this.state.year);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.year != this.props.year) {
            this.updateValues(this.props.year);
            this.setState({year: this.props.year})
        }
    }

    updateValues(year) {
        const header = Utils.getAuthorizationHeaders();
        let targetUrl = Utils.createUrl('/brfa/api/accounting/balance/instant/'+ year +'/' + this.props.accountClass);
        Utils.fetch(targetUrl, header, (data) => {
            this.setState({data: data, isLoading: false});
        }, (error) => {
            if (error == 401) {
            }
        });
    }

    render() {
        if (this.state.isLoading) {
            return (<div>Loading...</div>)
        }
        let values = [];
        let total = this.state.data.balances[this.state.data.balances.length-1].yearlyBalances[0];
        for (let i=0;i<this.state.data.balances.length;i++) {
            if (this.state.data.balances[i].account != 0 && this.state.data.balances[i].yearlyBalances[0] > 5) {
                let value = [];
                value.push(this.state.data.balances[i].name + '<br/>' + Utils.numberAsCurrency(this.state.data.balances[i].yearlyBalances[0]) + ' (' + (this.state.data.balances[i].yearlyBalances[0] / total * 100).toFixed(2) + '%)');
                value.push(this.state.data.balances[i].yearlyBalances[0]);
                values.push(value);
            }
        }

        return (
            <div style={{minWidth: '500px', minHeight: '30vh', maxWidth: '800px', width: '45%'}}>
                <div style={Main.headingStyle}>{this.state.data.label}</div>
                <div style={{margin: '6px 6px 6px 6px', boxShadow: '0px 1px 12px rgba(0.5, 0.5, 0.5, 0.5)'}}>
                    <PieChart title={'Totalt: ' + Utils.numberAsCurrency(total)} values = {values}/>
                </div>
            </div>
        )
    }
}

export default AccountChart;
