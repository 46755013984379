import React from 'react';
import Suggestions from "../Suggestions";
import Utils from "../../utils/Utils";

class TransactionRows extends React.Component {
    constructor(props) {
        super(props);

        this.headerFontSize = '12px';
        this.inputStyle = {
            position: 'relative',
            width: '100%',
            height: '32px',
            margin: '0px',
            padding: '0px',
            border: 'none'
        }

        let models = [];
        let model = { account: '', amount: '', comment: '', name: '', ordinal: 0, debet: 0, credit: 0 }
        models.push(model);

        let transactions = props.transactions == null ? models : props.transactions;
        this.initialize(transactions);
    }

    initialize(transactions) {
        for (let i=0;i<transactions.length;i++) {
            let row = transactions[i];
            if (row.amount > 0) {
                row.debet = parseFloat(row.amount);
                row.credit = 0;
            } else {
                row.credit = parseFloat(-row.amount);
                row.debet = 0;
            }
        }

        this.suggestionsComponent = [];

        let sum = this.recalc(transactions);

        this.state = ({transactions: transactions, sum: sum})
        return sum;
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps.transactions !== this.props.transactions) {
            let sum = this.initialize(this.props.transactions)
            this.setState(({transactions: this.props.transactions, sum: sum}))
        }
    }

    render() {
        let transRows = [];
        let summary = [];

        for (let i=0;i<this.state.transactions.length;i++) {

            let debet = this.state.transactions[i].debet === 0 ? '' : this.state.transactions[i].debet;
            let credit = this.state.transactions[i].credit === 0 ? '' : this.state.transactions[i].credit;

            transRows.push(
                <tr className='seamless'>
                    <td><input
                        autocomplete="off"
                        id='account'
                        name='account'
                        onChange={(e) => this.onAccountChange(i, e)}
                        onBlur={() => this.props.onUpdate(this.state.transactions)}
                        style={this.inputStyle}
                        value={this.state.transactions[i].account}></input>
                        <Suggestions
                            ref={(el) => this.suggestionsComponent[i] = el}
                            row={i}
                            component="account"
                            onUpdate={(account) => this.updateAutocomplete(i, account)}
                            data={this.state.transactions[i].suggestions == null ? null : this.state.transactions[i].suggestions}/></td>
                    <td class="dotted"><input
                        autocomplete="off"
                        name = 'name'
                        style={this.inputStyle}
                        value={this.state.transactions[i].name}>
                    </input></td>
                    <td><input name = 'apartmentId' onChange={(e) => this.updateTransaction(i, e)} style={this.inputStyle} value={this.state.transactions[i].apartmentId}></input></td>
                    <td><input name = 'comment' onChange={(e) => this.updateTransaction(i, e)} style={this.inputStyle} value={this.state.transactions[i].comment}></input></td>
                    <td style={{width: '10%'}}><input name='debet' onBlur={() => this.props.onUpdate(this.state.transactions)} onChange={(e) => this.updateAmount(i, e)} style={this.inputStyle} value={debet}></input></td>
                    <td style={{width: '10%'}}><input name='credit' onBlur={() => this.props.onUpdate(this.state.transactions)} onChange={(e) => this.updateAmount(i, e)} style={this.inputStyle} value={credit}></input></td>
                    <td style={{width: '10%'}}><input readonly='true' style={this.inputStyle} value={this.state.transactions[i].account}></input></td>
                    <td><button>-</button></td>
                </tr>
            )
        }

        return (
            <div style={{background: 'ltgray', padding: '12px'}}>
                <button onClick={(e) => this.addRow(e)}>+</button>
                <table class='dotted' style={{width: '100%'}}>
                    <thead style={{background: 'lightgray', height: '24px', fontSize: '14px', paddingLeft: '5px'}}>
                        <tr>
                            <td style={{width: '10%'}}>Konto</td>
                            <td>Benämning</td>
                            <td>Lgh</td>
                            <td>Transaktionsinfo</td>
                            <td>Debet</td>
                            <td>Kredit</td>
                            <td>Saldo</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {transRows}
                        <tr class="dotted" style={{height: '32px', background: '#EEEEEE', fontSize: '16px'}}>
                            <td colSpan='4'></td>
                            <td>{this.state.sum.debet}</td>
                            <td>{this.state.sum.credit}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    addRow(e) {
        let rows = this.state.transactions;
        let ordinal = this.state.ordinal + 1;
        rows.push({ account: '', amount: '', comment: '', name: '', ordinal: ordinal, debet: 0, credit: 0 })
        this.setState({transactions: rows, ordinal: ordinal});
    }

    recalc(transactionRows) {
        let debet = 0;
        let credit = 0;

        let sum = {};

        for (let i=0;i<transactionRows.length;i++) {
            let row = transactionRows[i];
            debet += parseFloat(row.debet);
            credit += -row.credit;
            row.amount = row.debet != 0 ? row.debet : -row.credit;
        }

        sum.debet = debet;
        sum.credit = -credit;
        Math.round(sum);
        return sum;
    }

    updateAmount(i, event) {
        let transactionRow = this.state.transactions[i];
        if (event.target.name === 'credit') {
            transactionRow.credit = event.target.value;
            transactionRow.debet = 0;
        } else {
            transactionRow.debet = event.target.value;
            transactionRow.credit = 0;
        }

        console.log(JSON.stringify(transactionRow));

        let sum = this.recalc(this.state.transactions);

        this.setState({transactions: this.state.transactions, sum: sum})
    }

    updateAutocomplete(i, account) {
        let transactionRow = this.state.transactions[i];
        transactionRow.account = account.account;
        transactionRow.name = account.name;
        transactionRow.suggestions = null;
        this.setState({transactions: this.state.transactions})

    }

    updateTransaction(i, event) {
        let transactionRow = this.state.transactions[i];
        transactionRow[event.target.name] = event.target.value ;
        this.props.onUpdate(this.state.transactions)
        this.setState({transactions: this.state.transactions})
    }

    onAccountChange(row, event) {

            let transactions = this.state.transactions;

            let searchString = event.target.value;

            if (transactions[row].lookupPending == false) {
                transactions[row].account = searchString.substr(searchString.length - 1);
                transactions[row].name = '';
                transactions[row].lookupPending = true;
                return;
            }

            transactions[row].account = searchString;

            this.setState({ dummy: 0 })

            // this.state.account[row] = {};
            if (searchString.length >= 2) {
                const targetUrl = Utils.createUrl('/brfa/api/lookup/account/' + searchString);
                fetch(targetUrl, Utils.getAuthorizationHeaders())
                    .then(response => {
                        return response.json();
                    }).then(data => {
                    if (data) {
                        transactions[row].suggestions = data;
                        this.setState({dummy: 0})
                        return;
                    }
                });
            }

    }
}

export default TransactionRows;
