
import React from 'react';
import '../../App.css';
import Utils from "../../utils/Utils";
import CSVUpload from "./CSVUpload";
import TransactionForm from "../accounting/TransactionForm";
import DownloadUtil from "../../utils/DownloadUtil";
import DialogFrame from "../dialog/DialogFrame";
import Verification from "../accounting/Verification";

class TransactionTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {checkedVerifications: [], excelContent: null, data: {}, isLoading: true, transactions: [], verifiedIds: [], selectedVerification: null, selectedForAccounting: null, newTransactions: []};
    }

    componentDidUpdate(prevProps) {
        if (prevProps.transactions !== this.props.transactions && Array.isArray(this.props.transactions)) {
            console.log("PROPERTY: " + JSON.stringify(this.props))
            this.setState({transactions: this.props.transactions, isLoading: false})
            return true;
        }
    }

    render() {
        if (this.state.isLoading || this.state.transactions == null || this.state.transactions.length == 0) {
            return "";
        }

        if (this.state.excelContent != null) {
            DownloadUtil.getFile(this.state.excelContent);
            this.state.excelContent = null;
        }

        let displayTextStyle, displayNumberStyle, rowStyle, debetStyle, creditStyle, dateStyle;

        let thStyleLeft={fontWeight: 'bold', overflow: 'hidden', maxWidth: '10px', textOverflow: 'hidden'};
        // let thStyleLeft={fontWeight: 'bold', overflow: 'hidden' };
        let thStyleButton={width: '80px'};
        let thCheckbox = {width: '10px'};
        let thStyleRight={fontWeight: 'bold', textAlign: 'right'};

        let tableHeaders = [];
        tableHeaders.push()

        if(this.state.selectedForAccounting != null) {
            return(<TransactionForm transactions = {this.state.selectedForAccounting}/>);
        }

        let upload = [];
        if (Utils.getLoginInfo().user.userGroupId > 500) {
            upload.push(<div style={{padding: '5px', background: '#ffeeb9'}}><input value="Kundfordringar från föregående år" type="button" onClick={() => this.transformToReceivable('ACCOUNTS_RECIVABLE')}/></div>)
            upload.push(<div style={{padding: '5px', background: '#ffeeb9'}}><input value="Markerade som förbetalda" type="button" onClick={() => this.transformToReceivable('PREPAID')}/></div>)
            upload.push(<div style={{padding: '5px', background: '#ffeeb9'}}><input value="Exportera månadsvis" type="button" onClick={() => this.exportTransactions(1)}/></div>)
            upload.push(<div></div>)
            upload.push(<CSVUpload onUploadComplete={() => this.onUploadComplete()} account = {this.props.account} onComplete={(data) => this.onUploadedData(data)}>Stäm av konto</CSVUpload>)
        }

        let verificationDialog = [];
        if (this.state.selectedVerification != null) {
            let content = [];
            content.push(<Verification verificationId={this.state.selectedVerification} verificationNumber={this.state.selectedVerificationNumber}></Verification>)
            let title = 'Verifikation '+ this.state.selectedVerificationNumber;
            verificationDialog.push(<DialogFrame id={this.state.selectedVerification} title={title} content={content}/>);
        }


        return (
            <div id="transactionTable" style={{width: 'calc(100% - 6px)', padding: '3px'}}>
                    <div>
                        <div style={{padding: '10px', height: '24pt', fontWeight: 'bold', color: '#949494', float: 'left', width: '50%'}}>{this.props.account.account} {this.props.account.name} {this.props.year} </div>
                        <div style={{marginTop: '10px', display: 'grid', gridTemplateColumns: 'auto auto auto auto auto'}}>{upload}</div>
                        <table class="dotted standardFont" style={{width: "100%", fontFamily: 'Arial, Helvetica, sans-serif', border: 'none', boxShadow: '0px 1px 12px rgba(0.5, 0.5, 0.5, 0.5)'}}>
                            <thead>
                            <tr>
                                <td style={thCheckbox}><input type = 'checkbox'></input></td>
                                <td style={thStyleButton}>Ver</td>
                                <td style={thStyleLeft}>Datum</td>
                                <td style={thStyleLeft}>Lägenhet</td>
                                <td style={thStyleLeft}>Benämning</td>
                                <td style={thStyleLeft}>Referens</td>
                                <td style={thStyleLeft}>Namn</td>
                                <td style={thStyleLeft}>Avsändare / Mottagare</td>
                                <td style={thStyleLeft}>Detaljer</td>
                                <td style={thStyleRight}>Debet</td>
                                <td style={thStyleRight}>Kredit</td>
                                <td style={thStyleRight}>Saldo</td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.transactions.map((transaction, i) => {
                                let totalStyle = { fontWeight: 'bold', textAlign: 'right', minWidth: '80px' };
                                let verification = [];
                                let verificationButton = []
                                if (transaction.transactionType != 'TRANSACTION') {
                                    dateStyle = {minWidth: '64px'}
                                    displayTextStyle = {fontWeight: 'bold'}
                                    displayNumberStyle = {fontWeight: 'bold', textAlign: 'right'}
                                    debetStyle = {minWidth: '80px', fontWeight: 'bold', textAlign: 'right', color: '#007D00'};
                                    creditStyle = {minWidth: '80px', fontWeight: 'bold', textAlign: 'right', color: '#E60A02'};
                                    totalStyle.color = transaction.runningTotal > 0 ? '#007D00' : '#E60A02';

//                                    if (Date.parse(transaction.date) > Date.now()) {
                                        rowStyle = {
                                            height: '24px',
                                            background: '#dcdcdc',
                                            color: '#000000',
                                    }

                                    verificationButton.push(<td></td>);
                                    } else {
                                    displayTextStyle = {fontWeight: 'normal'}
                                    displayNumberStyle = {fontWeight: 'normal', textAlign: 'right'}
                                    debetStyle = {minWidth: '100px', fontWeight: 'normal', textAlign: 'right', color: '#007D00'};
                                    creditStyle = {minWidth: '100px', fontWeight: 'normal', textAlign: 'right', color: '#E60A02'};
                                    if (Date.parse(transaction.date) > Date.now()) {
                                        rowStyle = {background: '#ffdeab'}
                                    } else {
                                        rowStyle = {background: '#FFFFFF'}
                                    }

                                    let documents = [];
                                    if (transaction.documentCount > 0) {
                                        documents.push(<div style={{float: 'left'}}><img style = {{height: '15px'}} src='documents.png'></img></div>)
                                        } else {
                                        documents.push(<div></div>);
                                    }

                                    verificationButton.push(<td nowrap={true}><div style={{float: 'left', width: '70%'}}><button style={{height: '18px', width: '80%'}} onClick={() => this.onClickVerification(transaction.verificationId, transaction.verification)}>{transaction.verification}</button></div>{documents}</td>);
                                }

                                let debet = (transaction.debet == null || transaction.debet == 0) ? "" : Utils.numberAsCurrency(transaction.debet);
                                let credit = (transaction.credit == null || transaction.credit == 0) ? "" : Utils.numberAsCurrency(transaction.credit);
                                let verifiedIds = this.state.verifiedIds;
                                let checkmark = "";

                                let tableData = [];
                                let accounting = [];

                                    if (transaction.apartmentId == 0) {
                                        displayTextStyle.background = '#A2DEAE';
                                    }

                                    let autogiroIcon;
                                    if (transaction.autogiroType == 2) {
                                        displayTextStyle.color = '#0024DD';
                                    }

                                    let monthlySummary = [];
                                    monthlySummary.push(<td style={totalStyle} noWrap={true}>{Utils.numberAsCurrency(transaction.runningTotal)}</td>)

                                    let checked = this.state.checkedVerifications.indexOf(transaction.verificationId) > 0 ? 'checked' : ''
                                    tableData.push(
                                        <tr style={rowStyle} onClick={(e) => this.viewFile(transaction.verificationId)}>
                                            <td><input value={checked} type='checkbox' onClick={(event) => this.onRowSelect(event, transaction.verificationId)}></input></td>
                                            {verificationButton}
                                            <td style={dateStyle} noWrap={true}>{transaction.date}</td>
                                            <td style={displayTextStyle}
                                                noWrap={true}>{transaction.apartmentId == 0 ? '' : transaction.apartmentId}</td>
                                            <td style={displayTextStyle} noWrap={true}>{transaction.description}</td>
                                            <td style={displayTextStyle} noWrap={true}>{transaction.reference}</td>
                                            <td style={displayTextStyle} noWrap={true}>{transaction.transactionName}</td>
                                            <td style={displayTextStyle} noWrap={true}>{transaction.senderOrRecipient}</td>
                                            <td style={displayTextStyle} noWrap={true}>{transaction.details}</td>
                                            <td style={debetStyle} noWrap={true}>{debet}</td>
                                            <td style={creditStyle} noWrap={true}>{credit}</td>
                                            {monthlySummary}
                                        </tr>
                                    )

                                let row = [];
                                row.push(tableData);

                                return (
                                    <>
                                        {accounting}
                                        {row}
                                    </>

                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                {verificationDialog}
            </div>
        );
    }

    transformToReceivable(rebookingType) {
        let year = this.props.year;
        let account = this.props.account.account;

        this.state.isLoading = true;

        const requestOptions = {
            method: 'POST',
            headers: Utils.getAuthorizationHeaders().headers,
            body: JSON.stringify({
                verificationIDs: this.state.checkedVerifications,
                rebookingType: rebookingType})
        }

        let targetUrl = Utils.createUrl('/brfa/api/accounting/verification/transform');
        fetch(targetUrl, requestOptions)
            .then(data => {
                this.setState({ isLoading: false } );
            });
    }

    onRowSelect(event, id) {
        let checkedVerifications = this.state.checkedVerifications;

        if (event.target.checked) {
            checkedVerifications.push(id);
        } else {
            var index = checkedVerifications.indexOf(id);
            checkedVerifications.splice(index, 1);
        }
        this.setState({checkedVerifications: checkedVerifications})
    }

    viewFile() {

    }

    onClickVerification(verification, selectedVerificationNumber) {
        this.setState({selectedVerification: verification, selectedVerificationNumber: selectedVerificationNumber});
    }

    doAccount(event, transaction) {
        this.setState({selectedForAccounting: transaction});
    }

    onUploadedData(apiTrans) {
        let verifiedIds = [];
        for (let i = 0; i < apiTrans.length; i++) {
            let bankTrans = apiTrans[i].bankTransaction;
            verifiedIds = verifiedIds.concat(this.lookup(bankTrans));
        }
        if (this.state.newTransactions.length > 0) {
            let transactions = this.state.transactions;

            const resultList = this.state.transactions.concat(this.state.transactions);

            this.state.newTransactions.forEach((item) => {
                resultList.unshift(item);
            })
            this.setState({transactions: resultList, verifiedIds: verifiedIds, newTransactions: this.state.newTransactions});
        }
    }

    lookup(bankTrans) {
        let transactionDate = bankTrans.transactionDate;
        let amount = bankTrans.amount;

        let verifiedIds = [];
        let verified = false;
        for (let i=0;i<this.state.transactions.length;i++) {
            let trans = this.state.transactions[i];
            if (trans.date == transactionDate  && trans.amount == amount) {
                    verified = true;
                    verifiedIds.push(trans.transactionId);
            }
        }
        if (verified == false) {
            let state = this.state;
            let newTransactions = state.newTransactions;
            newTransactions = newTransactions.concat({
                transactionType: "TRANSACTION",
                transactionId: 0,
                verificationId: 0,
                verification: 'XX',
                date: bankTrans.transactionDate,
                amount: bankTrans.amount,
                debet: bankTrans.amount > 0 ? bankTrans.amount : '',
                credit: bankTrans.amount < 0 ? bankTrans.amount : '',
                description: bankTrans.description + ' ' +bankTrans.message
            });
            this.setState({newTransactions: newTransactions});
            return verifiedIds;
        }
        return verifiedIds;
    }

    onEditVerification(verification) {
        this.setState({selectedVerification: verification});
    }

    accountingClose() {
        this.setState({selectedVerification: null});
    }

    onUploadComplete() {
        this.props.onRefresh();
    }

    exportTransactions(type) {
        let year = this.props.year;
        let account = this.props.account.account;

        this.state.isLoading = true;
        document.body.classList.add('busy-cursor');
        let targetUrl = Utils.createUrl('/brfa/api/file/download/excel/transactions/' + type + "/" + year + '/'+ account);
        const headers = Utils.getAuthorizationHeaders();
        fetch(targetUrl, headers)
            .then(data => {
                this.setState({ excelContent: data, isLoading: false, document: true} );
            });

    }

}

export default TransactionTable;
