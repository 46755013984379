import React from "react";
import Utils from "../../utils/Utils";
import {panelStyle} from "../../styles/Main";
import axios from "axios";

class CSVUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false, transactions: null }
    }

    componentDidMount() {
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        // alert(JSON.stringify(this.state.transactions));

        let trans = [];
/*
        if (this.state.transactions != null) {
            let apitrans = this.state.transactions;
            for (let i =0;i<apitrans.length;i++) {
                let accounting = apitrans[i].accountingTransaction;
                let bank = apitrans[i].bankTransaction;

                let style = {};
                if (accounting != null && bank != null) {
                    if (accounting.transactionDate == bank.transactionDate) {
                        style={background: '#FFFFFF'};
                    } else {
                        style={background: '#f7fccc'};
                    }
                }

                trans.push(
                    <tr style = {style}>
                        <td>{accounting == null ? "" : accounting.transactionDate}</td>
                        <td>{accounting == null ? "" : accounting.amount}</td>
                        <td>{accounting == null ? "" : accounting.description}</td>
                        <td>{accounting == null ? "" : accounting.account}</td>
                        <td>{bank == null ? "" : bank.transactionDate}</td>
                        <td>{bank == null ? "" : bank.amount}</td>
                        <td>{bank == null ? "" : bank.description}</td>
                        <td>{bank == null ? "" : bank.sendingAccount}</td>
                        <td>{bank == null ? "" : bank.recipientAccount}</td>
                        <td>{bank == null ? "" : bank.recipientName}</td>
                        <td>{bank == null ? "" : bank.message}</td>
                        <td>{bank == null ? "" : bank.notes}</td>
                        <td>{bank == null ? "" : bank.balance}</td>
                    </tr>)
            }
        }
*/

            return (
                <div  style={{fontSize: '9pt', width: 'auto'}}>
                    <div style={{width: '100%'}}>
                        <input type="file" onChange={(e) => this.handleInputChange(e)} />
                        <button onClick={() => this.onUpload(this.props.account.account)}>Importera</button>
                    </div>
                    <div style={{float: 'left', width: '100%'}}>
                        <table style={{fontSize: '9pt'}}>
                            <tbody style={{fontSize: '9pt'}}>
                                {trans}
                            </tbody>
                        </table>
                    </div>
                </div>
        )
    }

    handleInputChange(event) {
        this.setState({
            selectedFile: event.target.files[0],
        })
    }

    onUpload(account) {
        let formData = new FormData();

        const fileMetadata = {
            fileType: 'Verification',
            identification: 0,
            uploadedBy: Utils.getUserInfo().id
        }

        const data = new FormData()
        data.append('file', this.state.selectedFile)
        data.append('fileType', 'Verification')
        data.append('identification', this.props.series+this.props.number)
        data.append('uploadedBy', Utils.getUserInfo().id)

        this.buildFormData(data, fileMetadata, fileMetadata)

        let currentYear = new Date();

        const targetUrl = Utils.createUrl('/brfa/api/file/upload/csv/reconcile/' + currentYear.getFullYear() + '/'+account);
        let headers = Utils.getAuthorizationHeaders('text/json');

        const requestOptions = {
            method: 'POST',
            headers: headers.headers,
        }

        axios.post(targetUrl, data, requestOptions, {
        }).then(data => { // then print response status
            this.props.onComplete(data.data);
            this.setState({ transactions: data.data});
            this.props.onUploadComplete();
        })
    }

    buildFormData(formData, data, parentKey) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;

            formData.append(parentKey, value);
        }
    }
}

export default CSVUpload;