import React from "react";
import * as Main from "../styles/Main";
import {gridShadowBoxFull, shadowBox, subHeadingStyle} from "../styles/Main";
import Utils from "../utils/Utils";
import LabeledDropDown from "../components/forms/LabeledDropDown";
import Thumbnail from "../components/filemanagement/Thumbnail";
import LabeledTextArea from "../components/forms/LabeledTextArea";
import LabeledInput from "../components/forms/LabeledInput";

const STATE_INITIAL = 0;
const STATE_GENERATING = 1;
const STATE_FETCHING_PAGES = 2;
const STATE_VIEW = 3;

class AdminView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {formTypes: [], thumbsSize: 200, overlay: false, isLoading: true, ocrNumber: null, parameters: {id: null, period: null, year: null}, selectedInvoiceType: null, docSelections: [], documents: [], state: STATE_INITIAL, imageUrls: [], input: {} }
    }

    componentDidMount() {
        let targetUrl = Utils.createUrl('/brfa/api/admin/invoice/type');
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({invoiceTypes: data.invoiceTypes, invoiceParameters: data.invoiceParameters, isLoading: false});
        });

        targetUrl='/brfa/api/admin/invoice/formType';
        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({formTypes: data, selectedForm: 'SingleForm'});
        });
    }

    getParameterValues(prefix) {
        let params = {};
        params = this.state.invoiceParameters;

        for (let i=0;i<params.length;i++) {
            if (params[i].forParameter == prefix) {
                let list = [...params[i].values];
                list.unshift("")
                return list;
            }
        }
    }

    onSelectionChange(e) {
        this.state.parameters[e.target.name] = e.target.value;
        // alert(e.target.value + " - " + e.target.name + " "+ JSON.stringify(this.state.parameters))

        const invoiceType = this.state.selectedInvoiceType;
        if ((invoiceType.parameters.includes("A") || invoiceType.parameters.includes("L") ) && this.state.parameters['id'] == null) {
            return;
        }
        if ((invoiceType.parameters.includes("M") || invoiceType.parameters.includes("Q") ) && this.state.parameters['period'] == null) {
            return;
        }
        if (invoiceType.parameters.includes("Y") && this.state.parameters['year'] == null) {
            return;
        }

        this.calculateOcr();
    }

    calculateOcr() {
        this.state.isLoading = true;
        const parameters = this.state.parameters;
        const targetUrl = Utils.createUrl('/brfa/api/admin/invoice/calculateOcr');
        const headers = Utils.getAuthorizationHeaders();
        const requestBody = {
            invoiceType: this.state.selectedInvoiceType.invoiceType,
            year: parameters.year,
            id: parameters.id,
            period: parameters.period
        };

        const requestOptions = {
            method: 'POST',
            headers: headers.headers,
            body: JSON.stringify(requestBody)
        }

        fetch(targetUrl, requestOptions)
            .then(response => {
                return response.json();
            }).then(data => {

            this.setState({ocrNumber: data, isLoading: false});
        });
    }

    onThumbClick() {
        const url = window.URL.createObjectURL(
            new Blob([this.state.pdfDocument]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `FileName.pdf`)
        this.setState({pdfLink: link})
    }
    render() {

        if (this.state.isLoading) {
            return "";
        }

        let invoiceTypes = [];
        for (let i=0;i<this.state.invoiceTypes.length;i++) {
            invoiceTypes.push(<option class="medium" value={i}>{this.state.invoiceTypes[i].label} ({this.state.invoiceTypes[i].objectType})</option>)
        }

        let info = [];
        if (this.state.state == STATE_GENERATING) {
            info.push(<div>Creating invoices, please hang on...</div>);
        }

        if (this.state.state == STATE_FETCHING_PAGES) {

        }

        let thumbnail = [];
        if (this.state.imageUrls) {
            this.state.imageUrls.map((imageUrl, index) => {
            thumbnail.push(
                <div  style = {{float: 'left', margin: '12pt', boxShadow: 'rgba(0, 0, 0, 0.9) 0px 1px 12px'}}>
                <img onClick={(event) => this.onThumbClick(event, this.state.src)}
                    src={this.state.imageUrls[index]}
                    height='auto'
                    style={{
                    width: this.state.thumbsSize + 'px',
                    margin: '0px',
                    padding: '0px'
                    }}/>
                </div>)
            })
        }

        let documentList = [];
        for (let i = 0;i<this.state.documents.length;i++) {
            let document = this.state.documents[i];
            documentList.push(<option value={document.id}>{document.description}</option>)
        }

        let fields = [];
        if (this.state.selectedInvoiceType != null) {
            if (this.state.selectedInvoiceType.parameters.includes("A")) {
                fields.push(<LabeledDropDown width='100%' onChange={(e) => this.onSelectionChange(e)} selection={this.state.parameters.id} list={this.getParameterValues('A')} name='id' label={"Lägenhetsnummer"}></LabeledDropDown>)
            }
            if (this.state.selectedInvoiceType.parameters.includes("L")) {
                fields.push(<LabeledDropDown onChange={(e) => this.onSelectionChange(e)} selection={this.state.parameters.id} list={this.getParameterValues('L')} name='id' label={"Lokalnummer"}></LabeledDropDown>)
            }
            if (this.state.selectedInvoiceType.parameters.includes("YY")) {
                fields.push(<LabeledDropDown onChange={(e) => this.onSelectionChange(e)} selection={this.state.parameters.year} list={this.getParameterValues('Y')} name='year' label={"År"}></LabeledDropDown>)
            }
            if (this.state.selectedInvoiceType.parameters.includes("MM")) {
                fields.push(<LabeledDropDown onChange={(e) => this.onSelectionChange(e)} selection={this.state.parameters.period} list={this.getParameterValues('M')} name='period' label={"Månad"}></LabeledDropDown>)
            }
            if (this.state.selectedInvoiceType.parameters.includes("Q")) {
                fields.push(<LabeledDropDown onChange={(e) => this.onSelectionChange(e)} selection={this.state.parameters.period} list={this.getParameterValues('Q')} name='period' label={"Kvartal"}></LabeledDropDown>)
            }

            if (this.state.selectedInvoiceType.parameters.includes("H")) {
                fields.push(<LabeledInput width='200px' onChange={(e) => this.onSelectionChange(e)}
                                          selection={this.state.parameters.heading} list={this.getParameterValues('H')}
                                          name='heading' label={"Rubrik"}></LabeledInput>)
            }

            if (this.state.selectedInvoiceType.parameters.includes("T")) {
                fields.push(<LabeledTextArea width='100%' rows='5' cols='80' onChange={(e) => this.onSelectionChange(e)} selection={this.state.parameters.text} list={this.getParameterValues('T')} name='text' label={"Text"}></LabeledTextArea>)
            }
            if (this.state.selectedInvoiceType.parameters.includes("$")) {
                fields.push(<LabeledInput width='200px' onChange={(e) => this.onSelectionChange(e)} selection={this.state.parameters.amount} list={this.getParameterValues('$')} name='amount' label={"Belopp"}></LabeledInput>)
            }
        }

        let formTypes = [];
        for (let i=0;i < this.state.formTypes.length;i++) {
            formTypes.push({id: this.state.formTypes[i].name, value: this.state.formTypes[i].label})
        }

        return (
            <>
                <div style={Main.headingStyle}>Administration</div>
                <div style={{ height: 'fit-content', width: 'calc(100% - 12px)', padding: '0px', margin: '6px 6px 6px 6px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    <div style={gridShadowBoxFull}>
                        <div style={subHeadingStyle}>Betalningsavier</div>
                        <select size = {this.state.invoiceTypes.length} onChange={(e) => this.onInvoiceTypeChange(e)}>
                            {invoiceTypes}
                        </select><br/>
                        <div style={{marginBottom: '12px', float: 'left', width: '100%', minHeight: '80px'}}>
                            {fields}
                        </div>
                        <div style = {{marginTop: '12px', marginBottom: '12px', width: '100%', minHeight: '120px', marginLeft: '8px', fontSize: '12pt'}}>{this.state.ocrNumber != null ? 'OCR: '+this.state.ocrNumber : ''}</div>
                        <div style = {{width: '100%', minHeight: '60px', marginLeft: '8px', fontSize: '12pt'}}>
                            <button disabled={this.state.ocrNumber == null} onClick={(e) => this.generateInvoices(e)}>Generara avi</button>
                            <input id='overlay' type='checkbox' onClick={(e) => this.toggleOverlay(e)}></input>Använd overlay på pdf<br/>
                            <LabeledDropDown width='100%' onChange={(e) => this.onFormTypeChange(e)} selection={this.state.parameters.id} list={formTypes} name='formTypes' label={"Formulärstyp"}></LabeledDropDown>
                        </div>

                        <div style={{float: 'left'}}>
                            {info}
                            <input style={{width: '400px'}} onChange={(e) => this.onZoom(e)} type="range" min="100" max="1024" className="slider" id="myRange value={this.state.thumbSize}"/>
                            <div style={{width: '100%'}}>
                                <a download={this.state.pdfFileName} href={this.state.pdfDocument}>{thumbnail}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    onZoom(e) {
        this.setState({thumbsSize: e.target.value});
    }

    onFormTypeChange(e) {
        this.setState({selectedForm: e.target.value});
    }

    toggleOverlay() {
        let state = this.state.overlay == false;
        this.setState({overlay: state})
    }
    onInvoiceTypeChange(e) {
        this.setState({parameters: {year: null, month: null, id: null}, ocrNumber: null, selectedInvoiceType: this.state.invoiceTypes[e.target.value]})
    }

    docSelect(event) {
        let target = event.target;
        for (let i=0;i<target.options.length;i++) {
            if (target.options[i].selected) {

            }
        }
    }

    apartmentUpdate(event) {
        let value = event.target.value;
        let name = event.target.name;
        let input = this.state.input;
        input[name] = value;
        console.log(name+" = "+value)
        this.setState({ input: input });
    }

    generateInvoices(e) {
        this.setState({ state: STATE_GENERATING });

        let today = new Date();

        if (this.state.parameters.year != null && this.state.parameters.period != null) {
            today = new Date(this.state.parameters.year, this.state.parameters.period - 1, 0);
        }

        let date = today.toLocaleDateString();
        let periods = [];
        if (this.state.parameters.period == null) {
            periods = null;
        } else {
            periods.push(this.state.parameters.period == null ? null : this.state.parameters.period)
        }

        let targetUrl = Utils.createUrl('/brfa/api/admin/generate/invoice');

        const headers = Utils.getAuthorizationHeaders();

        const requestBody = {
            invoiceType: this.state.selectedInvoiceType.invoiceType,
            id: this.state.parameters.id,
            date: date,
            year: this.state.parameters.year,
            periods: periods,
            heading: this.state.parameters.heading,
            text: this.state.parameters.text,
            amount: this.state.parameters.amount == null ? null : this.state.parameters.amount.replace(",", "."),
            overlay: this.state.overlay,
            // pdfFormType: 'SingleForm',
            pdfFormType: this.state.selectedForm,
            thumbnails: true
            };

        const requestOptions = {
            method: 'POST',
            headers: headers.headers,
            body: JSON.stringify(requestBody)
            };

        fetch(targetUrl, requestOptions)
            .then(response => {
                return response.json();
            }).then(data => {
            let imageUrls = this.state.imageUrls;
            let fileName = data.fileName;
            for (let i=0;i<data.imageData.jpgImages.length;i++) {
                let url = "data:image/jpeg;base64,"+data.imageData.jpgImages[i];
                imageUrls[i] = url;
            }
            let url = "data:image/pdf;base64,"+data.imageData.pdfDocument;

            this.setState({ imageUrls: imageUrls, pdfDocument: url, pdfFileName: fileName, state: STATE_VIEW })
            });
    }

    generateInvoiceWithMessage(e) {
        this.setState({ state: STATE_GENERATING });
        let targetUrl = Utils.createUrl('/brfa/api/admin/generate/invoice/message?overlay=true');

        const headers = Utils.getAuthorizationHeaders();
        // headers.responseType =  'blob';

        let message = {
            apartmentId: this.state.input.apartmentId,
            paymentInfoheading: "Hello form!",
            paymentInfo: ["This is an ipsum..."],
            heading: "Välkommen",
            message: this.state.input.message,
            amount: this.state.input.amount,
            date: this.state.input.date,
            expireDate: '2022-09-20' };

        const requestOptions = {
            method: 'POST',
            headers: headers.headers,
            body: JSON.stringify(message)
        }

        fetch(targetUrl, requestOptions)
            .then(response => {
                return response.json();
            }).then(data => {
                let imageUrls = this.state.imageUrls;
                let url = "data:image/jpeg;base64,"+data.imageData.jpgImages[0];
                imageUrls[0] = url;
                let pdf = "data:image/pdf;base64,"+data.imageData.pdfDocument;
                this.setState({ imageUrls: imageUrls, pdfDocument: pdf, state: STATE_VIEW })
            });
    }

    getAll() {
        for (let i=0;i<4;i++) {
            this.getPage(i)
        }
    }

    downloadPdf() {
        const url = window.URL.createObjectURL(
            new Blob([this.state.pdfDocument]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `FileName.pdf`)

    }

    getPage(page) {
        let targetUrl = Utils.createUrl('/brfa/api/admin/invoice/retrieve/'+this.state.apartmentId+'/' + + '/'+page+'?overlay=false');
        this.state.isLoading = true;
        const headers = Utils.getAuthorizationHeaders();
        // headers.responseType =  'blob';
        fetch(targetUrl, headers)
            .then(data => data.blob())
            .then((blob) => {
                var binaryData = [];
                binaryData.push(blob); //My blob
                let url = URL.createObjectURL(new Blob(binaryData, {type: "image/jpeg"}));
                let imageUrls = this.state.imageUrls;
                imageUrls[page] = url;
                this.setState({imageUrls: imageUrls, state: STATE_VIEW})
            });
    }

}

export default AdminView

