import React from "react";
import {panelStyle} from "../../styles/Main";

class DialogFrame extends React.Component {
    constructor(props) {
        super(props);

        this.state = {show: true, id: this.props.id};
    }

    inComponentMont
    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.setState({show: true})
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    handleResize(e) {
        alert("!");
        this.setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
        })
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        let display = this.state.show == true ? 'block' : 'none';

        const style =  {
            display: display,
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: '80%',
            minHight: '50%',
            width: 'auto',
            height: '300px',
            // border: '2px solid red',
            borderRadius: '5px',
            beckground: 'blue',
            // boxShadow: '0px 1px 12px rgba(0.5, 0.5, 0.5, 0.5)'
            //zIndex: '0'
            resize: 'both'
        }
        return (
            <div style={style}>
                <div style={{minHeight: '24px', posision: 'sticky', top: '0', left: '0px', borderRadius: '5px 5px 0px 0px', padding: '5px', height: '5%', width: 'auto', color: '#FFFFFF', background: '#00528d'}}><div style={{marginLeft: '4px', marginTop: '4px', float: 'left'}}>{this.props.title}</div><div style={{float: 'right', marginTop: '2px'}}><button  onClick={() => this.onCLose()} style={{align: 'right'}}>X</button></div></div>
                <div style={{top: '20', paddingBottom: '20px', overflowX: 'hidden', overflowY: 'auto', maxHeight: '800px', position: 'absolute', width: '100%', background: 'white', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'}}>{this.props.content}</div>
            </div>
        )
    }

    syncModel(event) {

        let person = this.state.person;
        person[event.target.name] = event.target.value ;
        this.setState({person: person})
    }

    onCLose() {
        this.setState({show: false})
    }
}

export default DialogFrame;