import React from "react";
import {Link} from "react-router-dom";
import Utils from "../../utils/Utils";
import {gridShadowBox} from "../../styles/Main";

class MaintenanceStatistics extends React.Component {
    constructor(props) {
        super(props);

        this.state = {isLoading: true};

        this.itemStyle = {
            cursor: 'pointer',
            fontSize: '11pt'
        }
    }

    componentDidMount() {
        const header = Utils.getAuthorizationHeaders();

        let targetUrl = Utils.createUrl('/brfa/api/maintenance/statistics');
        Utils.fetch(targetUrl, header, (data) => {
            this.setState({statistics: data, isLoading: false});
        }, (error) => {
            if (error == 401) {
            }
        });
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        let year = new Date().getFullYear();

        let rows = [];
        let style;
        for (let i=0;i<this.state.statistics.length;i++) {
            if (year == this.state.statistics[i].year) {
                style={background: '#76ff64'};
            } else {
                style = {background: '#FFFFFF'};
            }
            rows.push(
                <tr><td>{this.state.statistics[i].year}</td>
                    <td>{this.state.statistics[i].itemCount}</td>
                    <td>{Utils.numberAsCurrency(this.state.statistics[i].totalAmount)}</td>
                    <td><span style={style}>{Utils.numberAsCurrency(this.state.statistics[i].accumelatedAmount)}</span></td>
                </tr>)
        }

        return (
                <div style={gridShadowBox}>
                    <div style={{width: '48%'}}>Planerat ej utfört underhåll</div><br/>
                    <table>
                        <thead>
                        <tr>
                            <td>År</td>
                            <td>Antal</td>
                            <td>Totalkostnad</td>
                            <td>Accumulerat</td>
                        </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
        )
    }
}

export default MaintenanceStatistics;