import React from "react";
import {panelStyle} from "../styles/Main";

class Person extends React.Component {
    constructor(props) {
        super(props);

        this.state = {apartmentId: props.apartmentId, person: props.person, isLoading: false};
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        return (
            <div style={panelStyle}>
                <div>{this.state.person.firstName} {this.state.person.lastName}</div>
                <table styl={{width: '100%'}}>
                    <tr><td>Andel</td><td>{this.state.person.share}%</td></tr>
                    <tr><td>Namn</td><td>{this.state.person.firstName} {this.state.person.lastName}</td></tr>
                    <tr><td>Personnummer</td><td>{this.state.person.personalNumber}</td></tr>
                    <tr><td>Avtal</td><td>{this.state.person.fromDate}</td></tr>
                    <tr><td>Mobiltelefon</td><td>{this.state.person.mobilePhone}</td></tr>
                    <tr><td>Telefon hem</td><td>{this.state.person.homePhone}</td></tr>
                    <tr><td>Telefon arbete</td><td>{this.state.person.workPhone}</td></tr>
                </table>

            </div>
        )
    }
}

export default Person;