import React from "react";
import {timelineContainerStyle} from "../../../styles/Main";
import Utils from "../../../utils/Utils";
import ReactTooltip from "react-tooltip";

class Timeline extends React.Component {
    constructor(props) {
        super(props);

        this.state = {items: [], person: {}, isLoading: true};
        this.dragStart = 0;
        this.dragStartDate = null;
        this.dragStartItem = null;
        this.dragDate = null;
        this.ref = React.createRef();
    }

    componentDidMount() {
        const targetUrl = Utils.createUrl('/brfa/api/maintenance/task/list');
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {

                if (response.status !== 200) {
                    const status = response.status;
                    return Promise.reject(status)
                }

                return response.json();
            }).then(data => {
            this.setState({tasks: data, isLoading: false});
        }).catch(error => {
            switch (error) {

                case 401: alert("Du har blivit utloggad");
                break;

                case 403: alert("Du har engett ett felaktigt användarnamn eller lösenord, var god försök igen!");
                break;
            }

        });

        const element = this.ref.current
        if (element == null) {
            return;
        }
        element.addEventListener('resize', (event) => console.log(event.detail));
        function checkResize(mutations) {
            const el = mutations[0].target;
            const w = el.clientWidth;
            const h = el.clientHeight;

            const isChange = mutations
                .map((m) => `${m.oldValue}`)
                .some((prev) => prev.indexOf(`width: ${w}px`) === -1 || prev.indexOf(`height: ${h}px`) === -1);

            if (!isChange) { return; }
            const event = new CustomEvent('resize', { detail: { width: w, height: h } });
            el.dispatchEvent(event);
            alert("!");
        }
        const observer = new MutationObserver(checkResize);
        observer.observe(element, { attributes: true, attributeOldValue: true, attributeFilter: ['style'] });
    }

    getPos(date) {
        var activityStart = new Date(date);
        var start = new Date(activityStart.getFullYear(), 0, 2);
        var diff = activityStart - start;
        var oneDay = 1000 * 60 * 60 * 24;
        var day = diff / oneDay;
        var pos = 'calc(' + 100 / 365 * day  + '% + 5px)';
        console.log(new Date(date).toLocaleString() + start.toLocaleString() + " " + day + " " + pos)
        return pos;
    }

    getDateFromPos(startDate, pos) {
        var oneDay = 1000 * 60 * 60 * 24;

        var ratio = 1 + 100 / window.innerWidth;
        console.log(ratio);

        var date = new Date(startDate - (pos-5) * 100 / 365 * oneDay / ratio);
        var result = new Date(date);

        console.log(pos + ' ' + result.toISOString());
        return result;
    }

    getLength(startDate, endDate) {
        let days = (endDate - startDate) / 1000 / 60 / 60 / 24;
        var pos = 'calc(' + 100 / 365 * days + '% - 15px)';
        console.log(new Date(endDate).toLocaleString() + " " + days + " " + pos)
        return pos;
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

    let daySpan = '8.49% 7.67% 8.49% 8.22% 8.49% 8.22% 8.49% 8.49% 8.22% 8.49% 8.22% 8.49%';


        let days = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augsti', 'September', 'Oktober', 'November', 'December']
        let months = [];
        // let items = [];
        let tasks = [];

/*
        items[0] = {date: '2022-05-13', label: 'Alpha'};
        items[1] = {date: '2022-03-17', label: 'Bravo'};
        items[2] = {date: '2022-08-02', label: 'Charlie'};
        items[3] = {date: '2022-08-02', label: 'Delta'};
        items[4] = {date: '2022-08-02', label: 'Echo'};
        items[5] = {date: '2022-08-02', label: 'Foxtrot'};
*/
        for (let i=0;i<12;i++) {
            var now = new Date(2022, i,1);
            var start = new Date(now.getFullYear(), 0, 0);
            var diff = now - start;
            var oneDay = 1000 * 60 * 60 * 24;
            var day = Math.floor(diff / oneDay);
            var pos = Math.floor(70/365 * day) + '%';
            // var style = {margin: '0px', padding: '0px', width: '100%', background: '#ffffff', height: 'calc(100vh - 144px)'};

            let style;
            if (i<11) {
                style = { gridGap: '1px', boxSizing: 'content-box', minHeight: 'calc(100vh - 180px)', padding: '0px', width: '100%', textAlign: 'center', borderLeft: '1pt dotted gray' }
            } else {
                style = { gridGap: '1px', boxSizing: 'content-box', minHeight: 'calc(100vh - 180px)', padding: '0px', width: '100%', textAlign: 'center', borderLeft: '1pt dotted gray', borderRight: '1pt dotted gray' }
            }

            months.push(
                <div style={style}>
                        <div style={{
                            // margin: '0px',
                            // fontSize: '14pt',
                            // padding: '6px 0px 6px 0px',
                            // padding: '6px 0px 6px 0px',
                            // width: 'calc(100% - 10px)',
                            // background: '#DDEEFF',
                            // boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.5)'
                            }}>
                            {days[i]}
                        </div>
               </div>)
        }

        let items = this.state.tasks;
        for (let i=0;i<items.length;i++) {
            var pos = this.getPos(Date.parse(items[i].startDate));
            var width = this.getLength(Date.parse(items[i].startDate), Date.parse(items[i].due))

            let background;

            if (items[i].person == null) {
                background = '#DDDDDD';
            } else {

                switch(items[i].taskStage.id) {
                    case 1:
                        background = '#fbff9c';
                        break;
                    case 2:
                        background = '#AADDFF';
                        break;
                    case 3:
                        background = '#92fc8e';
                        break;
                }
            }


            var style = {
                boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                fontSize: '12px',
                overflow: 'hidden',
                marginLeft: pos,
                width: width,
                padding: '4px',
                marginTop: '4px',
                marginBottom: '4px',
                background: background,
                border: '0px dotted black',
                resize: 'horizontal'
            };

            var description = items[i].startDate + ' ' + items[i].due + ' ' + (items[i].person == null ? 'unassigned' : items[i].person.firstName + ' ' + items[i].person.lastName);

            tasks.push(<><div style={{
                    padding: '2px',
                    height: '100vh - 140px)',
                    width: 'calc(100% - 12px)'
            }}
                            onDragStart={(e) => this.onDragStart(e, items[i])}
                            onDragEnter={(e) => this.onDragEnter(e)}
                            onDragLeave={(e) => this.onDragLeave(e)}
                            onDragOver={(e) => this.onDragOver(e)}
                            onDragEnd={(e) => this.onDragEnd(e)}
                            onDrop={(e) => this.onDrop(e, this)}

            >
                <div ref={this.ref}
                     draggable
                    data-tip
                    data-for={'task' + i}
                    item={items[i]}
                    style={style}
                >{items[i].id + ' ' + items[i].heading}
                </div>
            </div>
            <ReactTooltip id={'task' + i} place="top" effect="solid">{<div>{description}</div>}</ReactTooltip>
            </>
            )
        }

        if (0==0) {
            return(
                <>
                <div style={{margin: '10px', display: 'grid', gridTemplateColumns: daySpan}}>
                    {months}
                </div>
                    <div style={{left: '10px', top: '182px', position: 'absolute', zindex: '100', width: '100%', }}>
                    <div>{tasks}</div>
                    </div>
                </>
            )
        }

        return (
            <div style={{padding: '4px' }}>
                <div style={timelineContainerStyle}>
                {months}
                </div>
                <div>{tasks}</div>
        </div>
        )
    }



    initDrag(event) {
        console.log('RESIZE');
    }

    onDragStart(event, item) {
        this.dragStart = event.clientX;
        this.dragStartItem = item;
        this.dragStartDate = Date.parse(item.startDate);
        console.log(this.dragStartDate);
    }

    onDragEnter() {

    }

    onDragEnd(event) {
        let date = this.getDateFromPos(this.dragStartDate, this.dragStart - event.clientX)
        console.log(this.dragStartDate + ' ' + event.clientX + ' ' + date.toISOString());
        this.dragStartDate = null;

        const targetUrl = Utils.createUrl('/brfa/api/maintenance/task/'+ this.dragStartItem.id + '/start/'+date.toISOString());
        const requestOptions = {
            method: 'PUT',
            headers: Utils.getAuthorizationHeaders().headers,
        }

        fetch(targetUrl, requestOptions)
            .then(response => {

                if (response.status !== 200) {
                    const status = response.status;
                    return Promise.reject(status)
                }

                return response.json();
            }).then(data => {
            this.setState({tasks: data, isLoading: false});
        }).catch(error => {
            switch (error) {

                case 401: alert("Du har blivit utloggad");
                    break;

                case 403: alert("Du har engett ett felaktigt användarnamn eller lösenord, var god försök igen!");
                    break;
            }
        });
    }

    onDragLeave(e) {
        e.preventDefault();
    }

    onDragOver(event) {
        event.preventDefault();
        let date = this.getDateFromPos(this.dragStartDate, this.dragStart - event.clientX)
        console.log(date);
        this.dragDate = date;
        // this.setState({dragDate: 'x'})
    }

    onDrop(event) {
        event.preventDefault();
    }
}

export default Timeline;