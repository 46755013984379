import React from "react";
import ImageViewer from "./ImageViewer";

class Files extends React.Component {
    constructor(props) {
        super(props);
        this.state={files: props.files, size: '400px'};
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps.files !== this.props.files) {
            this.setState({files: this.props.files});
        }
    }

    render() {
        let now = new Date();

        return <div>
            <div className="slidecontainer">
                <input onChange = {(e) => this.zoomChanged(e)} type="range" min="200" max="800" value={this.size} className="slider" id="myRange"></input>
            </div>
            <ImageViewer
                previewTopOffset='-100px'
                files={this.state.files}
                size={this.state.size}
                autoFit={true}
                objectId='0'
                objectKey='Unassigned'
                objectDate={now.toLocaleDateString()}>
            </ImageViewer>
        </div>
    }

    zoomChanged(e) {
        this.setState({size: e.target.value + 'px'})
    }
}

export default Files;
