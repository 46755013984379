import Utils from "../../utils/Utils";
import axios from "axios";
import React from "react";
import ImageViewer from "../ImageViewer";

class Uploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {selectedFile: '', uploadedFiles: [], images: {}, imageUrls: [], pdfDocument: null};
    }

    componentDidMount() {
    }

    render() {

        let files = this.state.uploadedFiles;

        files.map((item) =>
            <dt>{item}</dt>
        )

        let images = [];
        if (this.state.imageUrls != null) {
            images.push(<ImageViewer pdfDocument = {this.state.pdfDocument} imageUrls = {this.state.imageUrls}/>)
        }

        return (
            <div>
                <h3>
                    Verifikationer / dokument
                </h3>
                <div>
                    <ul>
                        {files}
                    </ul>
                </div>
                <div>
                    <input type="file" onChange={(e) => this.handleInputChange(e)} />
                    <button onClick={() => this.onUpload()}>
                        Ladda upp
                    </button>
                </div>
                <div style={{}}>{images}</div>
            </div>
        )
    }

    handleInputChange(event) {
        this.setState({
            selectedFile: event.target.files[0],
        })
    }

    onUpload() {
        let formData = new FormData();

        const fileMetadata = {
            // fileType: 'Verification',
            identification: this.props.series+this.props.number,
            uploadedBy: Utils.getUserInfo().id
        }

        const data = new FormData()
        data.append('fileType', 'Verification')
        data.append('identification', this.props.series+this.props.number)
        data.append('uploadedBy', Utils.getUserInfo().id)
        data.append('file', this.state.selectedFile)
        console.warn(this.state.selectedFile);

        this.buildFormData(data, fileMetadata, fileMetadata)

        const targetUrl = Utils.createUrl('/brfa/api/file/upload');
        let headers = Utils.getAuthorizationHeaders('text/json');

        const requestOptions = {
            method: 'POST',
            headers: headers.headers,
        }

        axios.post(targetUrl, data, requestOptions, {
        }).then(data => { // then print response status
            let imageUrls = [];
            let imageData = data.data.imageData;
            for (let i=0;i<imageData.jpgImages.length;i++) {
                let url = "data:image/jpeg;base64,"+imageData.jpgImages[i];
                imageUrls[i] = url;
            }
            let pdfUrl = "data:image/pdf;base64,"+imageData.pdfDocument;

            let uploadedFiles = this.state.uploadedFiles;
                uploadedFiles.push("Alpha");
                this.setState({uploadedFiles: uploadedFiles, imageUrls: imageUrls, pdfDocument: pdfUrl });
            })
    }

    buildFormData(formData, data, parentKey) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;

            formData.append(parentKey, value);
        }
    }

}

export default Uploader;