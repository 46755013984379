import React from "react";
import Utils from "../../utils/Utils";

import ApartmentFeeDetails from "./ApartmentFeeDetails";

const STATE_INITIAL = 0;
const STATE_GENERATING = 1;
const STATE_FETCHING_PAGES = 2;
const STATE_VIEW = 3;

class ApartmentFee extends React.Component {
    constructor(props) {
        super(props);

        let state = props.imageUrls == null ? STATE_INITIAL : STATE_VIEW;

        const now = new Date();
        const currentYear = now.getFullYear();

        this.years = [];
        for (let i=currentYear;i>currentYear-5;i--) {
            this.years.push(<option>{i}</option>);
        }

        this.pdfYears = [];
        this.pdfYears.push(<option>{currentYear + 1}</option>)
        this.pdfYears.push(<option>{currentYear}</option>)

        const loginInfo = localStorage.getItem("loginInfo");

        this.state = {pdfYear: currentYear+1, currentYear: currentYear, state: state, imageUrls: [], pdfFileName: this.props.pdfFileName, pdfDocument: this.props.pdfDocument, isLoading: true};
    }

    componentDidMount() {
        this.fetchYear(this.state.currentYear);
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        let tableHeaderStyle = {
            background: '#EEEEEE',
            // width: '40px',
        // height: '24px',
            textAlign: 'center'};

        let center = {textAlign: 'center'};

        let headingStyle = {
            padding: '10px',
            height: '24pt',
            fontWeight: 'bold',
            fontSize: '18pt',
            color: '#949494'};

        if (this.state.state === STATE_VIEW) {
/*
            return (<ImageViewer
                imageUrls = {this.state.imageUrls}
                pdfFileName = {this.state.pdfFileName}
                pdfDocument = {this.state.pdfDocument}
                >
                </ImageViewer>)
*/
            let images = [];
            for (let i=0;i<this.state.imageUrls.length;i++) {
                images.push(<img src={this.state.imageUrls[i]} width='22%' style={{margin: '20px', boxShadow:  'rgba(0, 0, 0, 0.9) 0px 1px 12px', padding: '5px'}}/>);
            }

            let style = {float: 'left', width: '100%', justifyContent: 'center', cursor: this.state.cursor};

            return (
                <div style={style}>
                    {images}<br/>
                    <button style = {{margin: '12px'}}><a download={this.state.pdfFileName} href={this.state.pdfDocument}>Ladda ner PDF</a></button>
                </div>
            )


        } else if (this.state.state == STATE_GENERATING) {
        }

        return (
            <div style={{margin: '6px 6px 6px 6px', }}>
                <div style={headingStyle}>Avgifter</div>
                <div style={{margin: '12px'}}>
                    <select onChange = { (e)  => this.yearSelected(e) }>
                        {this.years}
                    </select>
                </div>
                <div style = {{boxShadow: '0px 1px 12px rgba(0.5, 0.5, 0.5, 0.5)', margin: '12px'}}>
                <table style={{width: '100%', cursor: 'zoom-in'}}>
                    <thead>
                        <tr style = {{}}>
                            <td style={tableHeaderStyle}>Lgh</td>
                            <td style={tableHeaderStyle}>Ägare</td>
                            <td style={tableHeaderStyle}>Avgift</td>
                            <td style={tableHeaderStyle}>Jan</td>
                            <td style={tableHeaderStyle}>Feb</td>
                            <td style={tableHeaderStyle}>Mar</td>
                            <td style={tableHeaderStyle}>Apri</td>
                            <td style={tableHeaderStyle}>Maj</td>
                            <td style={tableHeaderStyle}>Jun</td>
                            <td style={tableHeaderStyle}>Jul</td>
                            <td style={tableHeaderStyle}>Aug</td>
                            <td style={tableHeaderStyle}>Sep</td>
                            <td style={tableHeaderStyle}>Okt</td>
                            <td style={tableHeaderStyle}>Nov</td>
                            <td style={tableHeaderStyle}>Dec</td>
                            <td style={tableHeaderStyle}>Totalt</td>
                        </tr>
                    </thead>
                        <tbody>
                        {this.state.apartment.map((apartment, index) => {
                            let style = {};
                            if (index % 2 == 0) {
                                style.background = '#c4e3fd';
                            } else {
                                style.background = '#FFFFFF';
                            }

                            return(
                                <tr style = {style}>
                                    <td style={{width: '2%', height: '24px', background: style.background}}>{apartment.apartmentId}</td>
                                    <td style={{width: '15%', height: '24px', background: style.background}}>{this.formatHabitants(apartment)}</td>
                                    <td style={{width: '20px', height: '24px', background: style.background}}>{Utils.numberAsCurrency(apartment.currentFee)}</td>
                                    {apartment.monthlyPayments.map((payment, index) => {
                                        let date = new Date(this.state.currentYear, index, 0, 23, 59, 59);
                                        let cellStyle={textAlign: 'right', width: '20px', height: '12px', color: '#007a00'};
                                        if (payment != null && payment.paymentDetailsItems != null && payment.paymentDetailsItems[0] != null) {
                                            let payDate = new Date(payment.paymentDetailsItems[0].date)
                                            if(payDate.getTime() > date.getTime()) {
                                                cellStyle={textAlign: 'right', width: '20px', height: '12px', color: '#ff0000'};
                                            }
                                            for (let i=0;i<payment.paymentDetailsItems.length;i++) {
                                                if (payment.paymentDetailsItems[i].counterAccount == 2972) {
                                                    cellStyle={textAlign: 'right', width: '20px', height: '12px', color: '#a200ff'};
                                                }
                                            }
                                        }
                                        return(
                                            <td onMouseEnter={(e) => this.onHover(e, payment) } style={cellStyle}>{Utils.numberAsCurrency(payment == null || payment.sum == 0 ? '' : payment.sum)}</td>
                                        )
                                    })}
                                <td style={{textAlign: 'right', width: '30px', height: '12px', background: '#ececec'}}>{Utils.numberAsCurrency(apartment.sum)}</td>
                                </tr>)
                        })}
                        </tbody>
                </table>
                </div>
                <ApartmentFeeDetails posX={this.state.pageX} posY={this.state.pageY} paymentDetails={this.state.currentPayment}/>
                <button onClick = { () => this.createReminders() } style={{margin: '12px'}}>Generera påminnelse för obetalda</button>
                <button onClick = { () => this.createInvoicesForYear() } style={{margin: '12px'}}>Generera pdf för hela året</button>
                    <select onChange = { (e)  => this.onPdfYearChange(e) }>
                        {this.pdfYears}
                    </select>
            </div>
        )
    }

    onPdfYearChange(e) {
        this.setState({pdfYear: e.target.value})
    }

    formatHabitants(apartment) {
        const h1 = apartment.owners[0].firstName + ' ' + apartment.owners[0].lastName;
        const h2 = apartment.owners[1] == null ? '' : ' & ' + apartment.owners[1].firstName + ' ' + apartment.owners[1].lastName;
        return h1 + h2;
    }

    yearSelected(e) {
        this.setState({currentYear: e.target.value})
        this.fetchYear(e.target.value)
    }

    fetchYear(year) {
        const targetUrl = Utils.createUrl('/brfa/api/fee/payments/' + year);
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({apartment: data, isLoading: false});
        });
    }

    createReminders() {
        let targetUrl = Utils.createUrl('/brfa/api/admin/generate/invoice/reminders');

        this.setState({ state: STATE_GENERATING});

        const headers = Utils.getAuthorizationHeaders();
        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            let imageUrls = this.state.imageUrls;
            let fileName = data.fileName;
            for (let i=0;i<data.imageData.jpgImages.length;i++) {
                let url = "data:image/jpeg;base64,"+data.imageData.jpgImages[i];
                imageUrls[i] = url;
            }
            let url = "data:image/pdf;base64,"+data.imageData.pdfDocument;

            this.setState({ imageUrls: imageUrls, pdfDocument: url, pdfFileName: fileName, state: STATE_VIEW })
            });
        }

    createInvoicesForYear() {
        let targetUrl = Utils.createUrl('/brfa/api/admin/generate/invoices/' + this.state.pdfYear);

        const headers = Utils.getAuthorizationHeaders();

        const { timeout = 120000 } = headers;

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            let imageUrls = this.state.imageUrls;
            let fileName = data.fileName;

            for (let i=0;i<data.imageData.jpgImages.length;i++) {
                let url = "data:image/jpeg;base64,"+data.imageData.jpgImages[i];
                imageUrls[i] = url;
            }

            let url = "data:image/pdf;base64,"+data.imageData.pdfDocument;

            this.setState({ imageUrls: imageUrls, pdfDocument: url, pdfFileName: fileName, state: STATE_VIEW })
        });
    }


onHover(event, payment) {
        this.setState({currentPayment: payment, pageX: event.pageX, pageY: event.pageY})
    }
}

export default ApartmentFee;