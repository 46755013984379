import React from "react";
import Utils from "../../../utils/Utils";
import Information from "./Information";

class BrokerImage extends React.Component {
    constructor(props) {
        super(props);

        const queryParams = new URLSearchParams(window.location.search);
        let apartmentId = this.props.match.params.apartmentId != null ? this.props.match.params.apartmentId : Utils.getUserInfo().apartmentId;

        this.state = {apartmentId: apartmentId, isLoading: false};

        this.itemStyle = {
            cursor: 'pointer',
            fontSize: '11pt'
        }
    }

    componentDidMount() {
    }

    render() {
        if (this.state.isLoading == null) {
            return <div>Loading...</div>;
        }

        return (
            <div class="printable" style={{width: '100%'}}>
                <Information apartmentId = {this.state.apartmentId}/>
            </div>
        )
    }
}

export default BrokerImage;