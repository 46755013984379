import React from "react";

class LabeledTextArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {fields: props.fields, isLoading: false};
    }

    componentDidMount() {
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        return (
            <div style={{width: 'calc(100% - 15px)', paddingLeft: '5px', fontSize: '16px', float: 'left'}}>
                <div style={{padding: '5px', float: 'left'}}>{this.props.label}</div><br/>
                <textarea onChange={(e) => this.props.onChange(e)}
                          rows={this.props.rows}
                          cols={this.props.cols}
                          name={this.props.name}
                          style={{fontSize: '16px', height: '100%', width: this.props.width}}
                          value={this.props.value}/>

            </div>
        )
    }
}

export default LabeledTextArea;