import React from "react";
import Calendar from "../components/calendar/Calendar";
import TopBar from "../components/TopBar";
import * as Main from "../styles/Main";
import SurvayForm from "../components/forms/SurvayForm";
import ImageViewer from "../components/ImageViewer";

class SelfServiceView extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {

        return (
            <>
                <div style={Main.headingStyle}>Självbetjäning</div>
                <div style={{margin: '6px 6px 6px 6px'}}>
                    <ImageViewer/>
                </div>
            </>
        )
    }
}

export default SelfServiceView;