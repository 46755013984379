import React from "react";
import Utils from "../utils/Utils";
import * as Main from "../styles/Main";
import SMSBatch from "../components/messaging/SMSBatch";
import SMSBatchRecipients from "../components/messaging/SMSBatchRecipients";
import {subHeadingStyle} from "../styles/Main";

class MessageView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {qr: {}, details: {}, recipients: [], accountInfo: null, isLoading: true};
    }

    componentDidMount() {
        let targetUrl = Utils.createUrl('/brfa/api/service/sms/message');
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({messages: data, isLoading:  false});
        });

        targetUrl = Utils.createUrl('/brfa/api/service/sms/accountinfo');

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({accountInfo: data});
        });
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        let messages = [];
        let details = [];
        let recipients = [];


        for (let i=0;i<this.state.messages.length;i++) {
            messages.push(
                <tr onClick={(e) => this.getDetails(e, this.state.messages[i].batchId)}>
                    <td>{this.state.messages[i].created}</td>
                    <td>{this.state.messages[i].sender}</td>
                    <td>{this.state.messages[i].batchId}</td>
                    <td>{this.state.messages[i].message.substring(0,50)}...</td>
                </tr>)
        }

        let accountInfo = [];
        if (this.state.accountInfo != null) {
            accountInfo.push(<div style={{margin :'12px'}}>
                <div style={subHeadingStyle}>IP1 Konto</div>
                <table>
                    <tbody>
                    <tr><td>Konto:</td><td>{this.state.accountInfo.account}</td></tr>
                    <tr><td>Organisation:</td><td>{this.state.accountInfo.organization}</td></tr>
                    <tr><td>Typ:</td><td>{this.state.accountInfo.type}</td></tr>
                    <tr><td>Saldo:</td><td>{this.state.accountInfo.balance} {this.state.accountInfo.currency}</td></tr>
                    </tbody>
                </table>
            </div>)
        }

        return (
            <>
                <div style={Main.headingStyle}>Meddelanden</div>
                <div style={{float: "left", width: 'calc(100% - 6px)', cursor: 'pointer', boxShadow: '0px 1px 12px rgba(0.5, 0.5, 0.5, 0.5)'}}>
                    {accountInfo}
                <table style={{height: '200px', overflow: 'scroll', background: '#ffffff', width: '100%'}}>
                    <thead>
                        <tr>
                            <th width='150px'><td>Skickat</td></th>
                            <th width='150px'><td>Avsändare</td></th>
                            <th width='150px'><td>Batch Id</td></th>
                            <th><td>Meddelande</td></th>
                        </tr>
                    </thead>
                    <tbody style={{display: 'block', height: '500px', width: '800px', overflowY: 'auto'}}>
                        {messages}
                    </tbody>
                </table>
                    <SMSBatch batchId = {this.state.batchId}/>
                    <SMSBatchRecipients batchId = {this.state.batchId}/>
                </div>
            </>)
    }

    getDetails(e, batchId) {
        this.setState({batchId: batchId});
    }
}

export default MessageView;