import React from "react";
import Utils from "../utils/Utils";

class PersonList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {x: 0, y: 0, isLoading: true, checkedPersons: [], message: null};
    }

    componentDidMount() {
        var targetUrl = Utils.createUrl('/brfa/api/person/all/CURRENT_RESIDENTIAL');
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({persons: data, showQr: -1, isLoading: false});
        });

        window.addEventListener('mousemove', this.handleMouseMove);
    }

    handleMouseMove = (event) => {
        this.state.x = event.clientX;
        this.state.y = event.clientY;
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        let headingStyle = {
            padding: '10px',
            height: '24pt',
            fontWeight: 'bold',
            fontSize: '18pt',
            color: '#949494'};

        return (
            <div style={{padding: '5px'}}>
                <div style={headingStyle}>Kontakter</div>
                <div style={{float: "left", width: 'calc(100% - 6px)', cursor: 'pointer', boxShadow: '0px 1px 12px rgba(0.5, 0.5, 0.5, 0.5)'}}>
                    <table style={{background: '#ffffff', width: '100%'}}>
                        <tr>
                            <th style={{textAlign: 'center'}}><input type='checkbox' onClick={(e) => this.selectAll(e)}/></th>
                            <th>Lägenhet</th>
                            <th>Inflyttad</th>
                            <th>Namn</th>
                            <th>Företag</th>
                            <th>Personnummer</th>
                            <th>Hemtelefon</th>
                            <th>Arbete</th>
                            <th>Mobil</th>
                            <th>E-Post</th>
                            <th>Styrelseroll</th>
                            <th></th>
                            <th>ePost</th>
                            <th>GDPR</th>
                        </tr>

                        {this.state.persons.map((person, index) => {
                            var image = 'data:image/png;base64,' + person.qrCodeImageData;
                            let qrCode = "";
                            if (person.id == this.state.showQr) {
                                let style = {background: '#ccdeff', boxShadow: '0px 1px 12px rgba(0.5, 0.5, 0.5, 0.5)', padding: '12px', position: 'absolute', zorder: '1', left: this.state.x};

                                qrCode = (<div style={style}>
                                    <div style={{padding: '10px', fontSize: '16px', background: '#ccdeff'}}>{person.firstName + ' ' + person.lastName}</div>
                                    <img style={{float: 'left', width: 200, height: 200, background: '#FFFFFF'}} src={image}/>
                                </div>);
                            }

                            let style = { background: 'white'}
                            if (person.role != null) {
                                style = { background: '#ccdeff'}
                            }

                            return (
                            <tr  style={style} class="rowsel" onMouseOver={(e) => this.onHover(person.id) } >
                                <td><input type='checkbox' checked = {this.state.checkedPersons.indexOf(person.id) != -1} onChange={(e) => this.onPersonCheckChanged(e, person.id)}/><span style={{color: 'green'}}>{this.getSmsResult(person.mobilePhone) == true ? '\u2713' : ''}</span></td>
                                <td>{person.idApartment}</td>
                                <td>{person.fromDate}</td>
                                <td onClick = {() => this.onClick(person.id)}>{person.firstName} {person.lastName}</td>
                                <td>{person.companyName}</td>
                                <td>{person.personalNumber}</td>
                                <td>{person.homePhone}</td>
                                <td>{person.workPhone}</td>
                                <td>{person.mobilePhone}</td>
                                <td>{person.email}</td>
                                <td>{person.role}</td>
                                <td>{qrCode}</td>
                                <td>{person.acceptEmailInfo == true ? "*" : ""}</td>
                                <td>{person.gdprApproved == true ? "*" : ""}</td>
                            </tr>
                            )
                        })}
                    </table>
                    <div style={{padding: '12px'}}>
                        <input style = {{margin: '10px'}} type='button' value='Välj alla i styrelsen' onClick={() => this.checkBoard()}/>
                        <input style = {{margin: '10px'}} type='button' value='Välj alla medmobilnummer' onClick={() => this.selectAll()}/><br/>
                        <input style = {{margin: '10px'}} type='button' value='Kopiera mailadresser' onClick={() => this.copySelectedEmails()}/><br/>
                        <textarea rows="10" cols="50" onChange={(e) => this.messageChanged(e)}>{this.state.message}</textarea><br/>
                        <textarea rows="10" cols="50" value={this.state.clipboard}></textarea><br/>
                        <input style = {{margin: '10px'}} type='button' value='Skicka SMS till valda' onClick={() => this.sendSms()}/>
                        <input style = {{margin: '10px'}} type='button' value='Uppdatera resultat' onClick={() => this.getSmsDeliveryReport()}/><br/>
                        <div><input type='text' onChange={(e) => this.updateBatch(e)} value={this.state.smsBatchId}/></div>
                    </div>
                </div>
            </div>
        );
    }

    copySelectedEmails() {
        let persons = this.state.persons;
        let result = [];
        for (let i=0;i<persons.length;i++) {
            if (this.state.checkedPersons.indexOf(persons[i].id) != -1 && this.state.persons[i].email != null) {
                result.push(persons[i].email);
            }
        }
        this.setState({clipboard: result})
    }

    updateBatch(e) {
        let value = e.target.value;
        this.setState({smsBatchId: value});
    }
    getSmsResult(recipient) {
        let reports = this.state.smsDeliveryReport;
        if (reports == null || recipient === null) {
            return false;
        }
        for (let i=reports.length-1;i>=0;i--) {
            if (reports[i].recipient === '46' + recipient.substring(1)) {
                return reports[i].code === 201 ? true : false;
            }
        }
    }

    checkBoard(e) {
        const persons = this.state.persons;

            let check = this.state.checkedPersons.length === 0;

            for (let i = 0; i < persons.length; i++) {
                if (persons[i].role !== null && !persons[i].role.includes("revisor") && !persons[i].role.includes("Valberedning")) {
                   if (check) {
                       this.state.checkedPersons.push(persons[i].id)
                   } else {
                       this.state.checkedPersons.splice(this.state.checkedPersons.indexOf(persons[i].id, 1));
                   }
                }
            }
            this.setState({checkedPersons: this.state.checkedPersons})
    }

    selectAll(e) {
        let check = this.state.checkedPersons.length === 0;
        const persons = this.state.persons;
        if ((e == null || e.target.checked) && check == true) {
            this.state.checkedPersons = [];
            for (let i = 0; i < persons.length; i++) {
                if (persons[i].mobilePhone != null) {
                    this.state.checkedPersons.push(persons[i].id)
                }
            }
            this.setState({checkedPersons: this.state.checkedPersons})
        } else {
            this.setState({checkedPersons: []})
        }
    }

    messageChanged(e) {
        this.setState({message: e.target.value})
    }

    sendSms() {
        var targetUrl = Utils.createUrl('/brfa/api/person/sendSMS');

        let payload = {
            personIds: this.state.checkedPersons,
            message: this.state.message
        }

        const requestOptions = {
            method: 'POST',
            headers: Utils.getAuthorizationHeaders().headers,
            body: JSON.stringify(payload)
        }

        fetch(targetUrl, requestOptions)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({smsBatchId: data.batchId});
        });
    }

    getSmsDeliveryReport() {
        let targetUrl = Utils.createUrl('/brfa/api/service/sms/delivery/' + this.state.smsBatchId);
        let headers = Utils.getAuthorizationHeaders().headers;
        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({smsDeliveryReport: data});
        });
    }

    onPersonCheckChanged(event, person) {
        if (event.target.checked == 1) {
            this.state.checkedPersons.push(person)
        } else {
            const index = this.state.checkedPersons.indexOf(person);
            const x = this.state.checkedPersons.splice(index, 1);
        }
        this.setState({checkedPersons: this.state.checkedPersons})
    }

    onClick(id) {
        if (id == this.state.showQr) {
            this.setState({showQr: null});
        } else {
            this.setState({showQr: id});
        }
    }

    onHover(id) {

    }
}

export default PersonList;