
class DownloadUtil {
    static getFile(fileContent) {
        let response = fileContent.clone();
        let fileName = response.headers.get('content-disposition');
        // Trim the filename
        fileName = fileName.replace('attachment; filename=', '');

        response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
        });

        document.body.classList.remove('busy-cursor');
    }
}
export default DownloadUtil;

