import React from "react";
import {gridShadowBox, gridShadowBoxFull, panelStyle, shadowBox, subHeadingStyle} from "../styles/Main";
import Utils from "../utils/Utils";
import Person from "./Person";
import EditPerson from "./EditPerson";

class PersonGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {apartmentId: props.apartmentId, persons: {}, isLoading: true};
    }

    componentDidMount() {
        const targetUrl = Utils.createUrl('/brfa/api/person/apartment/' + this.state.apartmentId + '/CURRENT_OWNER');
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({persons: data, isLoading: false});
        });
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        let persons = [];

        for (let i=0;i<this.state.persons.length;i++) {
            persons.push(<Person person={this.state.persons[i]}/>)
        }

        return (
            <div style={gridShadowBox}>
                <div style={subHeadingStyle}>Ägare</div>
                <div style={{display: 'flex', fontSize: '12pt', lineHeight: '1.5'}}>
                {persons}
                </div>
            </div>
        )
    }
}

export default PersonGroup;