import React from "react";
import Mortgage from "../components/apartment/brokerimage/Mortgage";
import ApartmentData from "../components/apartment/ApartmentData";
import Utils from "../utils/Utils";

class ApartmentView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {person: {}, isLoading: false};
    }

    componentDidMount() {
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        let apartmentId = Utils.getUserInfo().apartmentId;

        return (
            <div>
                <ApartmentData apartmentId={apartmentId}/>
                <Mortgage apartmentId={apartmentId}/>
            </div>
        )
    }
}

export default ApartmentView;