import React from "react";
import {panelStyle} from "../styles/Main";

class Suggestions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {isLoading: false, isHovering: []};
    }

    render() {

        if (this.props.data === null) {
            return "";
        }


        if (this.props.data.length == 1) {
            this.props.onUpdate(this.props.data[0]);
        }

        const popupStyle = {
            border: '1px solid #C7C7C7',
            visibility: 'block',
            width: '300px',
            textAlign: 'left',
            padding: '0px 0',
            // background: '#fcffdb',
            position: 'absolute',
            zIndex: '500',
            boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.5)',
        }

        console.log("Rendering suggestions");

        return (
            <div autoFocus style={popupStyle}>
                <table style={{width: '100%'}}>
                    {this.props.data.map((account, i) => {
                        return (<tr style={{background: this.state.isHovering[i] == true ? '#DDDDFF' : '#fcffdb'}}
                            onKeyDown={ this.handleKeyDown }
                            onClick={( e) => this.onClick(account)}
                            onMouseOver={(e) => this.onMouseEnter(i, e)}
                            onMouseOut={(e) => this.onMouseLeave(i, e)}>
                            <td>{account.account}</td><td>{account.name}</td></tr>)
                    })}
                </table>
            </div>
        )
    }

    handleKeyDown(e) {
        const { cursor, result } = this.state
        // arrow up/down button should select next/previous list element
        if (e.keyCode === 38 && cursor > 0) {
            let cursor = this.state.cursor;
            cursor += 1;
            console.log("CURSOR: " + cursor);
            let isHovering = this.state.isHovering;
            isHovering[cursor] = false;
            this.setState( {isHovering: isHovering});
        } else if (e.keyCode === 40 && cursor < result.length - 1) {
            let cursor = this.state.cursor;
            cursor -= 1;
            console.log("CURSOR: " + cursor);
            let isHovering = this.state.isHovering;
            isHovering[cursor] = false;
            this.setState( {isHovering: isHovering});
        }
    }

    onMouseEnter(i, e) {
        let isHovering = this.state.isHovering;
        isHovering[i] = true;
        this.setState( {isHovering: isHovering});
    };

    onMouseLeave(i, e) {
        let isHovering = this.state.isHovering;
        isHovering[i] = false;
        this.setState( {isHovering: isHovering});
    };

    onClick(account) {
        this.props.onUpdate(account);
    }
}

export default Suggestions;