import React from "react";
import PersonList from "../components/PersonList";
import TopBar from "../components/TopBar";

class PersonView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {person: {}, isLoading: false};
    }

    componentDidMount() {
        }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        return (
            <div>
                <PersonList/>
            </div>
        )
    }
}

export default PersonView;