import React from "react";
import Utils from "../../utils/Utils";

import {changeColor, gridShadowBox, gridShadowBoxFull, panelStyle, shadowBox, subHeadingStyle} from '../../styles/Main';
import TopBar from "../TopBar";

class ApartmentData extends React.Component {
    constructor(props) {
        super(props);

        this.state = {apartmentId: props.apartmentId, isLoading: true};
    }

    componentDidMount() {
        const targetUrl = Utils.createUrl('/brfa/api/apartment/' + this.state.apartmentId);
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({apartment: data, isLoading: false});
        });
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        return (
            <div style={gridShadowBox}>
                <div style={subHeadingStyle}>Lägenhetsdata för lägenhet {this.state.apartment.idapartment}</div>
                <div>
                <table style={panelStyle}>
                    <tr><td>Lägenhet</td><td>{this.state.apartment.idapartment}</td></tr>
                    <tr><td>Nationellt lägenhetsnummer</td><td>{this.state.apartment.nlr}</td></tr>
                    <tr><td>Byggnad</td><td>{this.state.apartment.building}</td></tr>
                    <tr><td>Adress</td><td>{this.state.apartment.street} {this.state.apartment.streetNumber}</td></tr>
                    <tr><td>Våning</td><td>{this.state.apartment.floor}</td></tr>
                    <tr><td>Rum</td><td>{this.state.apartment.rooms}</td></tr>
                    <tr><td>Yta</td><td>{this.state.apartment.floorspace} m<sup>2</sup></td></tr>
                    <tr><td>Insats</td><td>{this.state.apartment.rentalEffort} Kr</td></tr>
                    <tr><td>Andelstal</td><td>{this.state.apartment.percentageRate}</td></tr>
                    <tr><td>Avgift</td><td>{Utils.numberAsCurrency(this.state.apartment.fee)}</td></tr>
                    <tr><td>Senaste avgiftsändring</td><td>{this.state.apartment.feeDate}</td></tr>
                </table>
                </div>
            </div>
        )
    }
}

export default ApartmentData;