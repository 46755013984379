import React from "react";
import Lane from "../components/calendar/Maintenance/Lane";
import Utils from "../utils/Utils";
import {headingStyle} from "../styles/Main";
import * as Main from "../styles/Main";

var util = require('util')

class TaskBoardView extends React.Component {
    constructor(props) {
        super(props);

        var items = ['Brandskyddskontroll av rökkanaler/eldstäder', 'Byte äldre avstängningsventiler', 'Byte trädetaljer gård'];

        this.state = {currentItem: {}, items: [], isLoading: true};

        this.dragHeight = 0;
        this.currentSelection = null;
        this.currentDropIndex = null;
    }

    componentDidMount() {
        const targetUrl = Utils.createUrl('/brfa/api/maintenance/task');
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {

            this.setState({items: data, isLoading: false});
        });
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        return (
            <div style={{margin: '6px', height: 'calc(100vh - 80px - 54px)'}}>
            <div style={Main.headingStyle}>Underhåll</div>
                <div style={{margin: 'px', paddingTop: '6px', height: '100%', overflow: 'auto', minWidth: '100%', display: 'grid', gridTemplateColumns: '25% 25% 25% 25%', gridAutoFlow: 'column'}}>
                    <Lane
                        dropZone='0'
                        onClick={(e) => this.onTaskClick(e)}
                        onDragStart={(e) => this.onDragStart(e)}
                        onDragEnter={(e) => this.onDragEnter(e)}
                        onDragLeave={(e) => this.onDragLeave(e)}
                        onDragOver={(e) => this.onDragOver(e)}
                        onDrop={(e) => this.onDrop(e)}
                        onGetSelectedItem = {() => this.onGetSelectedItem()}
                        items={this.state.items[0]}
                        title='Backlog (underhållsplan)'/>
                    <Lane
                        dropZone='1'
                        onClick={(e) => this.onTaskClick(e)}
                        onDragStart={(e) => this.onDragStart(e)}
                        onDragEnter={(e) => this.onDragEnter(e)}
                        onDragLeave={(e) => this.onDragLeave(e)}
                        onDragOver={(e) => this.onDragOver(e)}
                        onDrop={(e) => this.onDrop(e)}
                        onGetSelectedItem = {() => this.onGetSelectedItem()}
                        items={this.state.items[1]}
                        title='Planerat'/>
                    <Lane
                        dropZone='2'
                        onClick={(e) => this.onTaskClick(e)}
                        onDragStart={(e) => this.onDragStart(e)}
                        onDragEnter={(e) => this.onDragEnter(e)}
                        onDragLeave={(e) => this.onDragLeave(e)}
                        onDragOver={(e) => this.onDragOver(e)}
                        onDrop={(e) => this.onDrop(e)}
                        onGetSelectedItem = {() => this.onGetSelectedItem()}
                        items={this.state.items[2]}
                        title='Pågår'/>
                    <Lane
                        dropZone='3'
                        onClick={(e) => this.onTaskClick(e)}
                        onDragStart={(e) => this.onDragStart(e)}
                        onDragEnter={(e) => this.onDragEnter(e)}
                        onDragLeave={(e) => this.onDragLeave(e)}
                        onDragOver={(e) => this.onDragOver(e)}
                        onDrop={(e) => this.onDrop(e)}
                        onGetSelectedItem = {() => this.onGetSelectedItem()}
                        items={this.state.items[3]}
                        title='Klart'/>
                </div>
            </div>
        )
    }

    deselectItem() {
        if (this.currentSelection != null) {
            this.currentSelection.style.background = '#fcffdb';
        }
    }

    onGetSelectedItem() {
        return this.state.currentItem;
    }

    onTaskClick(e) {
        this.deselectItem();

        let target = e.target;
        while(target.getAttribute('name') != 'task') {
            console.log(target)
            target = target.parentElement;
        }

        target.style.background = '#92fc8e';
        this.currentSelection = target;

        let item = JSON.parse(target.getAttribute('data'));

        this.setState({currentItem: item});
    }

    onDragStart(ev) {
        console.log("onDrag");
        let id = ev.target.getAttribute("id");
        let dragZone = ev.target.getAttribute("dragZone");

        this.dragHeight = ev.target.clientHeight;

        let data = {
            dragZone: dragZone,
            id: id,
            taskId: ev.target.getAttribute("taskId")
        }

        console.log("OnDragStart: " + data);

        ev.dataTransfer.setData("text", JSON.stringify(data));
    }

    onDragOver(e) {
        e.preventDefault();
    }

    onDragLeave(e) {
        e.preventDefault();
    }

    onDragEnter(e) {
        let target = e.target;
        let name = target.getAttribute('name');
        if (name == 'task') {
            let id = target.getAttribute('id');
            let index = target.getAttribute('index');
            console.log('YES!' + id + " " + index);
            this.currentDropIndex = index;
        }
    }


    // Search tree until dropZone found
    onDrop(ev) {

        this.deselectItem();

        this.setState({isLoading: true});
        ev.preventDefault();
        let target = ev.target;

        let data = JSON.parse(ev.dataTransfer.getData("text"));

        let dragZone = data.dragZone;

       let dropZone = target.getAttribute('dropZone');

       let targetId = null;
       let targetTaskId;

       while (dropZone == null) {
            target = target.parentElement;
            dropZone = target.getAttribute('dropZone');
            if (dropZone != null) {
                targetId = target.getAttribute('id');
                targetTaskId = target.getAttribute('taskid');
            }
            console.log("Tried parent")
        }

        console.log("TargetId: " + targetId);

        console.log("DragZone: " + dragZone);
        console.log("DropZone: " + dropZone);

        let sourceList = this.state.items[dragZone];
        let targetList = this.state.items[dropZone];

        console.log(sourceList);
        console.log(targetList);

        var id = data.id;
        var taskId = data.taskId;
        console.log("Got: " + id);
        console.log("Source: " + sourceList.length);

        this.updateTask(id, taskId, targetId, targetTaskId, dragZone, dropZone);
        this.setState({isLoading: false});
    }

    updateTask(maintenancePlanId, taskId, targetId, targetTaskId, dragZone, dropZone) {
        const targetUrl = Utils.createUrl('/brfa/api/maintenance/task/state/update');
        const headers = Utils.getAuthorizationHeaders();

        this.state.isLoading = true;

        const requestParams = {
            sourceId: maintenancePlanId,
            sourceTaskId: taskId,
            targetId: targetId,
            targetTaskId: targetTaskId,
            sourceList: dragZone,
            targetList: dropZone
            };

        const requestOptions = {
            method: 'POST',
            headers: Utils.getAuthorizationHeaders().headers,
            body: JSON.stringify(requestParams)
        }

        fetch(targetUrl, requestOptions)
            .then(response => {
                return response.json();
            }).then(data => {

            this.setState({items: data, isLoading: false});
        });
    }
}

export default TaskBoardView;