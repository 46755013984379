import React from "react";
import Mortgage from "../components/apartment/brokerimage/Mortgage";
import ApartmentData from "../components/apartment/ApartmentData";
import Payments from "../components/economy/Payments";
import Calendar from "../components/calendar/Calendar";
import PersonGroup from "../components/PersonGroup";
import Utils from "../utils/Utils";
import News from "../components/News";
import * as Main from "../styles/Main";
import ImageViewer from "../components/ImageViewer";
import {Link, NavLink, withRouter} from "react-router-dom";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        const queryParams = new URLSearchParams(window.location.search);

        let apartmentId = this.props.match.params.apartmentId != null ? this.props.match.params.apartmentId : Utils.getUserInfo().apartmentId;

        this.state = {apartmentId: apartmentId, person: {}, isLoading: false};
    }

    componentDidMount() {
    }


    render() {

        let test = [];
        test.push(
            <>
            <News apartmentId={this.state.apartmentId}/>
            <Calendar size = '40' apartmentId={this.state.apartmentId}/>
            </>
    )

        if (this.state.isLoading) {
            return <div>Loading...</div>
        }

        return (
            <>
            <div style={Main.headingStyle}>Översikt för lägenhet {this.state.apartmentId}</div><div style={{marginLeft: '12px'}}>
                <Link to={`/brokerimage/${this.state.apartmentId}`}><button>Mäklarbild</button></Link>
            </div>
            <div style={{ height: 'fit-content', width: 'calc(100% - 12px)', padding: '0px', margin: '6px 6px 6px 6px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                <ApartmentData apartmentId={this.state.apartmentId}/>
                <PersonGroup apartmentId={this.state.apartmentId}/>
                <Payments apartmentId={this.state.apartmentId}/>
                <Mortgage apartmentId={this.state.apartmentId}/>
            </div>
            </>
        )
    }

    brokerImage() {
        this.router.push('/brokerimage')
    }
}

export default withRouter(Dashboard);