import React from "react";

class LabeledInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {fields: props.fields, isLoading: false};
    }

    componentDidMount() {
        // alert(this.props.fontSize)
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        return (
            <div style={{width: 'calc(100% - 15px)', paddingLeft: '5px', fontSize: this.props.fontSize == null ? '12pt' : this.props.fontSize, float: 'left'}}>
                <div style={{height: '100%', width: '100%', padding: '1px', float: 'left'}}>{this.props.label}</div><br/>
                    <input type="textbox" onChange={(e) => this.props.onChange(e)}
                           name={this.props.name}
                           style={{fontSize: '13px', height: '24px', width: this.props.width == null ? '100%' : this.props.width}}
                           type='text'
                           value={this.props.value}/>
            </div>
        )
    }
}

export default LabeledInput;