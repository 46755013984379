import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts';
import highcharts3d from 'highcharts/highcharts-3d';

highcharts3d(Highcharts);

class PieChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isLoading: true, data: {}}
    }

    render() {
        const options = {
            is3D: true,
            chart: {
                type: 'pie',

                options3d: {
                    enabled: true,
                    alpha: 45,
                    beta: 0
                }
            },
            title: {
                text: this.props.title
            },

            series: [
                {
                    data: this.props.values
                }
            ],
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    depth: 35,
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}'
                    }
                }
            },
            credits: false
        };

        return (
            <div>
                <HighchartsReact id='piechart_3d' highcharts={Highcharts} options={options} />
            </div>
        )
    }
}

export default PieChart;
