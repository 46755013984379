import {CollapsibleComponent, CollapsibleContent, CollapsibleHead} from "react-collapsible-component";
import React from "react";
import {BreakpointProvider} from 'react-socks';
import Utils from "../utils/Utils";
import TopBar from "../components/TopBar";
import ErrorBoundary from "../components/ErrorBoundary";
import {panelStyle, widePanelStyle} from "../styles/Main";

class VerificationView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {verifications: [], isLoading: true, error: false};
    }

    componentDidMount() {
        const targetUrl = Utils.createUrl('/brfa/api/accounting/verifications/' + "2021");
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                if (response.status == 200) {
                    return response.json();
                } else {
                    this.setState({error: true});
                    throw Error("Error!");
                }
            }).then(data => {
            this.setState({verifications: data, isLoading: false});
        })
    }

    render() {
        if (this.state.isLoading) {
            return <div>{this.state.isLoading}</div>;
        }

        if (this.state.error) {
            return <div>{this.state.isLoading}</div>;
        }

        let verElements = []

        for (let i=0;i<this.state.verifications.length;i++) {

            let verification = this.state.verifications[i];

            let transactions = verification.transactions;

            let transElements = [];
            if (transactions != null) {
                let totalDebet = 0;
                let totalCredit = 0;
                for (let j=0;j<transactions.length;j++) {
                    let transaction = verification.transactions[j];

                    let debet = null;
                    let credit = null;

                    if (transaction.amount < 0) {
                        credit = transaction.amount;
                    } else {
                        debet = transaction.amount;
                    }

                    transElements.push(
                        <tr>
                            <td>{transaction.row}</td>
                            <td>{transaction.account}</td>
                            <td>{transaction.name}</td>
                            <td>{transaction.description}</td>
                            <td style={{color: '#00AA00'}}>{debet}</td>
                            <td style={{color: '#AA0000'}}>{credit}</td>
                            <td>{transaction.reference}</td>
                        </tr>
                    )
                }
            }

            let amount = verification.transactions == null ? "" : verification.transactions[0].amount;

            verElements.push(
                <CollapsibleComponent>
                    <CollapsibleHead className="additionalClassForHead">
                        {verification.transactionDate}    {verification.series+verification.number} {verification.description}
                    </CollapsibleHead>
                    <CollapsibleContent className="additionalClassForContent" isExpanded>
                        <table>
                            <tr>
                                <td>Verifikation {verification.series+verification.number}</td>
                                <td>Datum {verification.transactionDate}</td>
                                <td>{verification.reference}</td>
                            </tr>
                        </table>
                        <table>
                        {transElements}
                        </table>
                    </CollapsibleContent>
                </CollapsibleComponent>
            )
        }

        return (
            <ErrorBoundary>
            <BreakpointProvider>
            <TopBar/>
            <div style={widePanelStyle}>
                <div className="App">
                    {verElements}
                </div>
            </div>
            </BreakpointProvider>
            </ErrorBoundary>
        )
    }
}

export default VerificationView;
