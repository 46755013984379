import React from "react";
import Utils from "../../utils/Utils";

class ApartmentFeeDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {isLoading: false};
    }

    componentDidMount() {

    }

    render() {
        if (this.props.paymentDetails == null || this.props.paymentDetails.paymentDetailsItems == null) {
            return <div></div>;
        }

        let style = {padding: '10px', zIndex: '10', position: 'absolute', left: '0', top: '0', background: '#FFFFDD', borderRadius: '10px', boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.5)'};
        style['left'] = this.props.posX + 10;
        style['top'] = this.props.posY + 10;

        let sum = 0;

        return (
            <div style={style}>
                <table style = {{ border: 'none'}}>
                    {this.props.paymentDetails.paymentDetailsItems.map((details, index) => {
                        sum += details.amount;
                        return(
                        <>
                            <tr><td>Datum</td><td>{details.date}</td></tr>
                            <tr><td>Belopp</td><td>{Utils.numberAsCurrency(details.amount)}</td></tr>
                            <tr><td>Text</td><td>{details.text}</td></tr>
                            <tr><td>Verifikations ID</td><td>{details.verificationId}</td></tr>
                            <tr><td>Betalningsreferens</td><td>{details.reference}</td></tr>
                            <tr>&nbsp;</tr>
                        </>
                    )
                })}
                <tr><td><b>Totalt:</b></td><td><b>{Utils.numberAsCurrency(sum)}</b></td></tr>
                </table>
            </div>
        )
    }
}

export default ApartmentFeeDetails;