import React from "react";
import Utils from "../../utils/Utils";
import {Chart, ArcElement, CategoryScale, LinearScale, PointElement, LineElement} from 'chart.js'
import { Doughnut, Pie, Line } from 'react-chartjs-2';
import * as Main from "../../styles/Main";
import Highcharts from 'highcharts'
import HighchartsReact from "highcharts-react-official";

Chart.register(ArcElement);
Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(PointElement);
Chart.register(LineElement);


class CashFlow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isLoading: true, year: this.props.year, data: {}}

        let lastLoans = 0;
        let lastFunds = 0;

    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        this.updateValues(this.props.year);
    }

    updateValues(year) {
        let targetUrl = Utils.createUrl('/brfa/api/accounting/cashflow/' + year + '/BANK_FUNDS, LOANS');
        const headers = Utils.getAuthorizationHeaders();

        let result = {};

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({year: year, data: data.values, isLoading: false})
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    componentDidUpdate(prevProps) {
        if (this.state.year != this.props.year) {
            this.updateValues(this.props.year);
        }
    }

    updateDimensions(event) {
    }


    render() {
        if (this.state.isLoading) {
            return '<div>Loading...</div>'
        }

        // let prev = 0;
        const assets = [];
        const bankFunds = [];
        const loans = [];
        const operationCosts = [];
        const sum = [];
        let labels = []

        let last = 0;
        for (let i=0;i<this.state.data.BANK_FUNDS.length;i++) {
            bankFunds.push(this.state.data.BANK_FUNDS[i].amount);
            last = this.state.data.BANK_FUNDS[i];
            labels.push(this.state.data.BANK_FUNDS[i].label);
        }

        for (let i=0;i<this.state.data.LOANS.length;i++) {
            loans.push(this.state.data.LOANS[i].amount);
            last = this.state.data.LOANS[i];
            labels.push(this.state.data.LOANS[i].label);
        }

/*
        for (let i=0;i<this.state.data.OPERATING_COSTS.length;i++) {
            operationCosts.push(this.state.data.OPERATING_COSTS[i].amount);
            last = this.state.data.OPERATING_COSTS[i];
        }
*/
        let baselineSum = [];
        let baselineOwn = [];

        for (let i=0;i<this.state.data.BANK_FUNDS.length;i++) {
            this.lastLoans = 0;
            this.lastFunds = 0;
            this.lastFunds = this.state.data.BANK_FUNDS[i].amount != null ? this.state.data.BANK_FUNDS[i].amount : this.llastFunds.amount;
            this.lastLoans = this.state.data.LOANS[i].amount != null ? this.state.data.LOANS[i].amount : this.llastLoans.amount;
            sum.push(Math.round((this.lastFunds - this.lastLoans + Number.EPSILON) * 100) / 100);
            baselineSum.push(this.state.data.BANK_FUNDS[0].amount)
            baselineOwn.push(this.state.data.BANK_FUNDS[0].amount - this.state.data.LOANS[0].amount)

        }

        const options = {
                title: {
                    text: 'Eget kapital och skulder'
                },
                credits: { enabled: false },
            xAxis: {
                categories: labels,
                accessibility: {
                    description: 'Months of the year'
                },
            },
            yAxis: {
                title: {
                    text: 'SEK'
                },

                labels: {
                    format: '{value: point.y:,.0f}'
                },
            },
            thousandsSep: ',',
            series: [
                {
                    color: "#00528d",
                    type: 'spline',
                    name: 'Disponibelt',
                    data: bankFunds,
                },
                {
                    color: "#ff1717",
                    type: 'spline',
                    name: 'Skulder',
                    data: loans,
                },
                {
                    color: "#118800",
                    type: 'spline',
                    name: 'Fritt eget kapital',
                    data: sum,
                },
                {
                    color: "#5eb0fd",
                    type: 'spline',
                    name: 'Ingående disp',
                    data: baselineSum,
                    marker: {
                        enabled: false
                    },
                    dashStyle: 'dash'
                },
                {
                    color: "#76ff64",
                    type: 'spline',
                    name: 'Ingående eget',
                    data: baselineOwn,
                    marker: {
                        enabled: false
                    },
                    dashStyle: 'dash'
                },
            ],
        }

            return (
                <div style={{minWidth: '500px', minHeight: '30vh', maxWidth: '800px', width: '45%'}}>
                    <div style={Main.headingStyle}>Kassaflöde</div>
                    <div style={{minWidth: '500px', height: '400px', margin: '6px 6px 6px 6px', boxShadow: '0px 1px 12px rgba(0.5, 0.5, 0.5, 0.5)'}}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                    </div>
                </div>
            )
    }

    onClick() {
        alert("CLICK!")
    }
}

export default CashFlow;
