import React from "react";

class LabeledChackbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {fields: props.fields, isLoading: false};
    }

    componentDidMount() {
        // alert(this.props.fontSize)
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }
        return (
            <div style={{width: 'calc(100% - 15px)', paddingLeft: '5px', fontSize: this.props.fontSize == null ? '13px' : this.props.fontSize}}>

                <div>
                    <div style={{float: 'left', width: '10%'}}>
                        <input id="test"
                           type="checkbox"
                           name={this.props.name}
                           style={{marginTop:'16px',  width: '16px', height: '16px'}}
                           checked={this.props.checked}/>
                    </div>
                    <div style={{float: 'left', width: '80%', marginLeft: '10px', marginTop: '15px'}}>{this.props.label}</div>
                </div>
            </div>
        )
    }
}

export default LabeledChackbox;