import React from "react";
import Utils from "../../utils/Utils";

class SMSBatch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {batchId: props.batchId, isLoading: true};

        this.itemStyle = {
            cursor: 'pointer',
            fontSize: '11pt'
        }
    }

    componentDidMount() {
        if (this.state.bacthId == null) {return}
        this.getBatchDetails(this.state.bacthId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.batchId !== this.props.batchId) {
            this.getBatchDetails(this.props.batchId);
        }
    }

    getBatchDetails(batchId) {
        this.setState({isLoading: true})

        let targetUrl = Utils.createUrl('/brfa/api/service/sms/message/' + batchId);
        let headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            this.setState({details: data, isLoading:  false});
        });
    }

    render() {
        if (this.state.isLoading) {
            return <div></div>;
        }

        return (
            <div style={this.itemStyle}>
                <div style={{margin: '12px', fontFamily: 'Arial', fontSize: '14px', maxWidth: '50%'}}>
                    <table>
                        <tr><td>BacthId:</td><td>{this.state.details.batchId}</td></tr>
                        <tr><td>Skickat:</td><td>{this.state.details.created}</td></tr>
                        <tr><td>Avsändare:</td><td>{this.state.details.sender}</td></tr>
                        <tr><td>Mottagare:</td><td>{this.state.details.recipientCount}</td></tr>
                        <tr><td>Status:</td><td>{this.state.details.status}</td></tr>
                    </table>
                    <div style = {{margin: '12px', padding: '12pt', background: '#b3d7f8'}}>{this.state.details.message}</div>
                </div>
            </div>
        )
    }
}

export default SMSBatch;