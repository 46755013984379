import Accounting from "../Accounting";
import React from "react";
import {calendarDayStyle, dialogStyle, panelStyle} from "../../styles/Main";

class TaskDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {person: {}, isLoading: false};
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        return (
            <div ref={(node ) => this.setWrapperRef(node)} style={dialogStyle}>{this.props.task.tasks[0].comment}</div>
        )
    }

    // onClick={(e) => this.onClick(e, this.state.calendarData[i-1])}

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.dismiss();
        }
    }
}

export default TaskDetails;

