import React from "react";
import Utils from "../../utils/Utils";
import {Link} from "react-router-dom";
import PopupMenu from "./PopupMenu";

class TopMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {isLoading: false, windowWidth: 0, windowHeight: 0, menus: this.props.menus, showPopup: false};

        this.userGroupId = Utils.getLoginInfo().user.userGroupId;

        this.itemStyle = {
            cursor: 'pointer',
            fontSize: '11pt'
        }
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
    handleResize = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight, menuItem: null });
    }

    render () {

        let currentMenu = [];

        return (<div style={{margin: '10px', fontSize: '10pt', width: '100%', height: 'auto', cursor: 'pointer'}}>
            {this.state.menus.map((menu, index) => {
                let selectedMenu = [];
                if (index == this.state.selected) {
                    console.log(this.state.showPopup);
                    selectedMenu.push(<PopupMenu onClick={(event) => this.onDeselectMenu(event)} menus={this.state.menus[index].menu}></PopupMenu>)
                }

                let background = this.state.selected == index ? '#888888' : '#35363a';
                return (<div
                    onFocusOut = {(event) => this.onDeselectMenu(event)}
                    onClick={(event) => this.onSelectMenu(event, index)}
                    onMouseOver={(e) => this.onMouseOverMenuItem(e, index)}
                    style={{background: background, color: '#FFFFFF', float: 'left', padding: '0px 15px 0px 15px'}}>{menu.label}
                    {selectedMenu}
                </div>);
            })}
        </div>);
    }

    onMouseOverMenuItem(event, index) {
        this.setState({selected : index});
    }

    onDeselectMenu(event) {
        event.stopPropagation();
        this.setState({showPopup: false, selected: null});
    }

    onSelectMenu(event, index) {
        this.setState({showPopup: true});
    }
}

export default TopMenu;