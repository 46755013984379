import React from "react";
import Utils from "../utils/Utils";

class DevelopmentView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {qr: {}, isLoading: true};
    }

    componentDidMount() {
        const targetUrl = Utils.createUrl('/brfa/api/messages/qr/test');
        const headers = Utils.getAuthorizationHeaders();

        fetch(targetUrl, headers)
            .then(response => {
                return response.json();
            }).then(data => {
            var image = 'data:image/png;base64,' + data.imageData;

            this.setState({qr: image, isLoading: false});
        });
    }

    render() {
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        return (
                <div>
                    <img style={{float: 'left', width: 100, height: 100, background: '#FFFFFF'}} src={this.state.qr}/>
                </div>
        )
    }
}

export default DevelopmentView;